.alert
  position fixed
  top 0
  left 0
  box-shadow 0.1rem 0.1rem 1rem black
  padding 3rem
  z-index 9999999 !important
  border-radius 3px
  visibility hidden
  transition 0.5s all
  &__text
    text-align center
    font-size 2rem
  &.popup-wrapper-limit .popup-cotent
    padding 40px
 
