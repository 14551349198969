.materials
  width: 100%;
  min-height: 591px !important
  overflow: hidden
  background: rgb(245,122,91);
  background: linear-gradient(270deg, rgba(245,122,91,1) 50%, rgba(162,204,216,1) 50%);
  position: relative
  border-bottom: 9px solid white
  @media (max-width: 768px)
    padding: 0
    background: transparent
  .materials-elements
    content-container();
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center
    @media (max-width: 768px)
      flex-flow: column
    .left-part
      regular()
      width: 50%
      height: 100%
      display: flex
      align-items: flex-end
      justify-content: center
      padding: 162px 0 182px 0
      margin: 0
      background-color: flience-blue
      position: relative
      @media (max-width: 768px)
        width: 100%
        padding: 90px 3%
      @media (max-width: 375px)
        padding: 90px 36px
      .clouds
        width: 263px
        height: 145px
        position: absolute
        bottom: -8px;
        left: -86px;
        z-index: 10
        background-position: center
        background-size: contain
        background-repeat: no-repeat
        background-image: url('../../dist/img/home/cloud.png')
        @media (max-width: 768px)
          bottom: -57px
      .clouds-display-mobile
        @media(max-width: 768px)
          display: none
      .materials-text
        width: 54%
        @media (max-width: 768px)
          width: 100%
          margin: 0 3%
        @media (max-width: 550px)
          font-weight 100
        p
          color: flience-white
          @media (max-width: 768px)
            width: 100%
          strong
            color: flience-white
            bold()
            @media (max-width: 550px)
              font-weight 500

    .right-part
      regular()
      background-color: flience-orange
      background-position: center
      background-size: cover
      height: 100%
      width: 50%
      padding: 0
      position: relative
      @media (max-width: 768px)
        width: 100%
        padding: 50px 27px
      @media (max-width: 375px)
        padding: 100px 36px 100px 36px
      .flashcards
        height: 100%
        position: absolute
        left: -60px
        top: -10px
        display: flex
        flex-flow: row
        justify-content: flex-start
        @media (max-width: 768px)
          justify-content: center
          position: static
          height: 100%
          padding: 80px 0 100px 0
        .flashcards-math
          &:hover
            z-index 10000 !important
            & a
              transform scale(1.1) !important
          transform: translate(0, -50%)
          width: 296px
          height: 300px
          background-color: transparent
          @media (max-width: 768px)
            padding: 180px 0
          @media (max-width: 665px)
            padding: 80px 0 0 0
          @media (max-width: 375px)
            width: 200px
            height: 209px
            margin: 0 150px 0 0
          a
            position relative
            display: block
            cursor: pointer
            z-index: 0
            background-color: transparent
            .flashcards-image1
              width: 100%
              transform: translateY(0px);
              animation: float 6s ease-in-out infinite
              background-color: transparent
              @media (max-width: 450px)
                position absolute
                left 20px
          .description-with-arrow-math
            regular()
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            position: absolute
            bottom: -149px;
            left: 146px;
            width: 170px;
            @media (max-width: 768px)
              left: 86px;
              width: 50%
              position: static
              width: 100%
              padding: 50px 0 0 0
              background-color: flience-orange
            @media(max-width: 450px) and (min-width: 376px)
              top: 300px
              position: absolute
              left: 1px
            @media (max-width: 375px)
              bottom: -176px;
              left: 49px;
              position: absolute
              width: 125%
              z-index: 0
              padding: 0
            .text1
              width: 100%
              text-align: center
              background-color: flience-orange
              strong
                bold()
              .arrow-math
                width: 48px
                height: 33px
                margin: 0 0 20px 0
                background-color: flience-orange
              p
                background-color: flience-orange
        .flashcards-lang
          &:hover
            z-index 10000 !important
            & a
              transform scale(1.1) !important
          position: absolute
          top: -6px
          left: 120px
          transform: translate(0, -50%)
          width: 296px
          height: 300px
          z-index: 6
          background-color: transparent
          @media (max-width: 768px)
            position: static
            padding: 180px 0
          @media (max-width: 665px)
            padding: 80px 0 0 0
          @media (max-width: 375px)
            position: absolute
            width: 191px
            height: 209px
            top: 120px
            left: 148px
          a
            position relative
            display: block
            cursor: pointer
            z-index: 0
            background-color: transparent
            .flashcards-image2
              width: 100%
              animation: float2 6s ease-in-out infinite
              background-color: transparent
              @media (max-width: 450px)
                position absolute
                left -20px
          .description-with-arrow-lang
            regular()
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            background-color: flience-orange
            position: absolute
            bottom: -149px;
            left: 9px;
            width: 200px
            z-index: -1
            @media (max-width: 768px)
              position: static
              padding: 50px 0 0  0
              width: 100%
            @media(max-width: 450px) and (min-width: 376px)
              top: 300px
              position: absolute
            @media (max-width: 375px)
              background-color: flience-orange
              position: absolute
              padding: 25px 0 0  0
              width: 100%
              bottom: -237px;
              left: -62px;
            .text2
              width: 100%
              text-align: center
              background-color: flience-orange
              strong
                bold()
              .arrow-lang
                width: 48px
                height: 33px
                margin: 0 0 14px 0
                background-color: flience-orange
              p
                background-color: flience-orange
