.popup-wrapper,
.popup-wrapper-limit
  width 100%
  height 100vh
  background-color rgba(60, 60, 59, 0.9)
  position absolute
  top 0
  left 0
  z-index 99999
  display flex
  justify-content center
  align-items center
  position fixed
  .popup-cotent
    background-color flience-light-grey
    width 302px
    height 288px
    display flex
    justify-content center
    flex-flow column
    align-items center
    position relative
    border-radius 20px
    padding 60px
    font-family 'Lato', sans-serif !important
    padding 50px
    .button-width185
      width 103px
      cursor pointer
      margin 40px 0 0 0
      p
        padding 8px 0 0 0
        bold()
        font-size 16px
    .main-header
      width 100%
      font-family 'Lato', sans-serif
      text-align center
      .notice-header
        line-height 2
        font-family 'Lato', sans-serif !important
        h2
          width 100%
          color flience-dark-grey
          font-family 'Lato', sans-serif !important
          black()
          font-size 20px !important
          margin 0 0 15px 0
        .notice
          color pleo-dark-green
          font-family 'Lato', sans-serif !important
          font-size 16px !important
          regular()
.error
  display flex
  flex-flow column
  padding 0
  color flience-orange
