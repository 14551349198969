@keyframes float
	0%
		transform translatey(0px)
	50%
		transform translatey(-20px)
	100%
		transform translatey(0px)

@keyframes float2
	0%
		transform translatey(0px)
	50%
		transform translatey(20px)
	100%
		transform translatey(0px)

@keyframes lds-ripple
	0%
		top 28px
		left 28px
		width 0
		height 0
		opacity 1
	100%
		top -1px
		left -1px
		width 58px
		height 58px
		opacity 0
