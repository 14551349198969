.footer
  height 145px
  width 100%
  background flience-dark-grey
  position relative
  display flex
  align-items center
  @media (max-width: 628px)
    height 437px
  .footer-container
    content-container()
    height 145px
    display flex
    flex-flow row
    align-items center
    justify-content space-between
    width 100%
    @media (max-width: 1440px)
      margin 0 3%
    @media (max-width: 628px)
      justify-content center
      align-items flex-start
      flex-flow column
      margin 0 36px
    .right-part
      display flex
      flex-flow row
      justify-content flex-end
      align-items center
      width 50%
      @media (max-width: 628px)
        margin 47px 0 0 0
        justify-content space-between
      .email
        bold()
        margin 0 40px 0 0
        color flience-white
      .social-media
        display flex
        flex-flow row
        a
          margin 0 0 0 15px
          text-decoration none
          cursor pointer
    .left-part
      display flex
      flex-flow row
      justify-content center
      align-items center
      @media (max-width: 628px)
        flex-flow column
      .polityka-prywatnosci, .warunki-korzystania
        width 100%
        margin 0 15px
        @media (max-width: 628px)
          margin 0 0 26px 0
        a
          bold()
          margin 0
          color flience-white
          text-decoration none
      .contact
        display flex
        justify-content center
        width 100%
        margin 0 15px
        @media (max-width: 628px)
          margin 0 0 26px 0
        a
          bold()
          margin 0
          color flience-white
          text-decoration none
  .obrazek
    width 311px
    height 233px
    position absolute
    top -38%
    left 50%
    transform translate(-50%, -50%)
    background-position center
    background-size contain
    background-repeat no-repeat
    @media (max-width: 628px)
      top 0px
      width 240px
      height 179px
