section.banner-image
    height: 600px
    width: 100%
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    @media (max-width: 1024px)
        height: 375px
    @media (max-width: 648px)
        height: 315px
    @media (max-width: 480px)
        height: 235px