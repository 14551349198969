* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
}
.visibleMenu {
  display: flex !important;
  opacity: 1 !important;
  max-height: unset;
  z-index: 99999999 !important;
}
.visibleFilterList {
  display: flex !important;
  opacity: 1 !important;
  height: auto !important;
  z-index: 99999999 !important;
}
.visibleSelect {
  opacity: 1 !important;
  max-height: 100px !important;
  z-index: -99999999 !important;
}
.menuOverflow {
  overflow: hidden !important;
}
.visibleSelectBorderRadius {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.checkElement {
  z-index: 10 !important;
  transform: scale(1.1, 1.1) !important;
}
.dot {
  height: 26px;
  width: 26px;
  background-color: #a2ccd8;
  border-radius: 50%;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-content: center;
  font-weight: 700;
}
.rotate {
  transform: rotate(90deg) !important;
}
.zIndex {
  z-index: 5 !important;
}
.sticky {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000 !important;
  background-color: #a3ced6;
  overflow: hidden;
  height: auto;
}
@media (max-width: 960px) {
  .sticky {
    display: none;
  }
}
.sticked {
  max-height: 134px !important;
  min-height: 134px !important;
}
@media (max-width: 387px) {
  .sticked {
    max-height: auto !important;
    min-height: auto !important;
  }
}
.select-options {
  padding: 0 20px 0 33px;
  font-weight: 700;
}
.disabled {
  pointer-events: none;
  cursor: default;
  color: #f00;
}
.displayNone {
  display: none !important;
}
.displayFlex {
  display: flex !important;
}
.displayBlock {
  display: block !important;
}
.menuBackgroundClor {
  background-color: #3c3c3b !important;
}
.cardsStyles {
  padding: 17px 20px 0 33px;
  font-weight: 700;
}
.visAndOpTrue {
  visibility: visible !important;
  opacity: 1 !important;
}
.visAndOpFalse {
  visibility: hidden;
  opacity: 0;
}
.logochange {
  display: block !important;
  z-index: 60;
}
@media (max-width: 375px) {
  .logochange {
    width: 83px;
    height: 26px;
  }
}
html {
  font-family: 'Lato', sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
  background-color: #a3ced6;
}
.body {
  background-color: #a3ced6;
}
.error p {
  color: #f57a5b;
}
.off-opacity {
  opacity: 1;
}
.on-opacity {
  opacity: 0.3;
}
.more-button {
  cursor: pointer !important;
}
.button-width185 {
  width: 185px;
  height: 41px;
  background-color: #f57a5b;
  border-radius: 20px;
  color: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  text-align: center;
}
.button-width185.smaller {
  width: 100px;
}
.button-width185 a {
  font-weight: 700;
  display: block;
  text-decoration: none;
  color: #fff;
}
.button-width185:focus {
  outline: none;
  cursor: pointer;
}
.button-width226 {
  width: 226px;
  height: 41px;
  margin-right: 25px;
  background-color: #f57a5b;
  border-radius: 20px;
  color: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}
.button-width226 a {
  font-weight: 700;
  display: block;
  text-decoration: none;
  color: #fff;
}
.button-width226:focus {
  outline: none;
  cursor: pointer;
}
.button-width226ham {
  width: 226px;
  height: 41px;
  margin-right: 14px;
  background-color: #f57a5b;
  border-radius: 20px;
  color: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}
.button-width226ham a {
  font-weight: 700;
  display: block;
  text-decoration: none;
  color: #fff;
}
.button-width226ham:focus {
  outline: none;
  cursor: pointer;
}
.button-width153 {
  width: 153px;
  height: 41px;
  background-color: #f57a5b;
  border-radius: 20px;
  color: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}
.button-width153 a {
  font-weight: 700;
  display: block;
  text-decoration: none;
  color: #fff;
}
.button-width153:focus {
  outline: none;
  cursor: pointer;
}
.button-width170 {
  width: 153px;
  height: 41px;
  background-color: #f57a5b;
  border-radius: 20px;
  color: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}
.button-width170 a {
  font-weight: 700;
  display: block;
  text-decoration: none;
  color: #fff;
}
.button-width170:focus {
  outline: none;
  cursor: pointer;
}
.button-width107 {
  width: 107px;
  height: 41px;
  background-color: transparent;
  border-radius: 20px;
  color: #f57a5b;
  border: 2px solid #f57a5b;
  outline: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}
.button-width107 a {
  font-weight: 700;
  display: block;
  text-decoration: none;
  color: #f57a5b;
}
.button-width107:focus {
  outline: none;
  cursor: pointer;
}
.button-width198 {
  width: 198px;
  height: 41px;
  background-color: #fff;
  border-radius: 20px;
  color: #f57a5b;
  border: 2px solid #f57a5b;
  outline: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}
.button-width198 a {
  font-weight: 700;
  display: block;
  text-decoration: none;
  color: #f57a5b;
}
.button-width198:focus {
  outline: none;
  cursor: pointer;
}
.button-widthauto {
  height: 41px;
  padding: 10px;
  background-color: #f57a5b;
  border-radius: 20px;
  color: #fff;
  border: none;
  outline: none;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  font-weight: 700;
}
.button-widthauto a {
  font-weight: 700;
  display: block;
  text-decoration: none;
  color: #fff;
}
.button-widthauto:focus {
  outline: none;
  cursor: pointer;
}
button {
  transition: 0.5s transform;
}
button a {
  transition: 0.5s transform;
}
button:hover {
  transform: scale(1.05, 1.05);
}
button:hover a {
  transform: scale(0.95, 0.95);
}
button a:hover {
  color: #fff;
}
.active-flashcard {
  background-color: #f57a5b !important;
  color: #fff !important;
}
.active-flashcard .translate-out {
  display: flex !important;
  color: #f57a5b !important;
  width: 100%;
  white-space: nowrap;
}
.activeMathCircle {
  background-color: #f57a5b;
  color: #fff !important;
}
.activeCirceblenda {
  position: absolute !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(60,60,59,0.7) !important;
  padding: 71px 41px !important;
  flex-flow: column !important;
  justify-content: flex-start !important;
  display: flex !important;
}
.global-wrapper {
  overflow: hidden;
}
.h1-global {
  font-size: calc(46px + (68 - 46) * ((100vw - 360px) / (1440 - 360)));
!important
  font-family: 'Lato', sans-serif;
}
!important
.h3-global {
  font-size: 28px !important;
  line-height: 41px;
}
.h4-global {
  font-size: 25px !important;
}
.p1-global {
  font-size: 28px !important;
  line-height: 41px;
  font-family: 'Lato', sans-serif !important;
}
@media (max-width: 375px) {
  .p1-global {
    font-size: 22px !important;
    line-height: 32px;
  }
}
.p2-global {
  font-size: 25px !important;
  line-height: 37px;
  font-family: 'Lato', sans-serif !important;
}
.p3-global {
  font-size: 24px !important;
  line-height: 38px;
  font-family: 'Lato', sans-serif !important;
}
@media (max-width: 375px) {
  .p3-global {
    font-size: 20px !important;
    line-height: 29px;
  }
}
.p4-global {
  font-size: 20px !important;
  line-height: 26px;
  font-family: 'Lato', sans-serif !important;
}
@media (max-width: 375px) {
  .p4-global {
    font-size: 22px !important;
    line-height: 26px;
  }
}
.p5-global {
  font-size: 16px !important;
  line-height: 24px;
  font-family: 'Lato', sans-serif !important;
}
.p6-global {
  font-size: 14px !important;
}
@-moz-keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@-webkit-keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@-o-keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@-moz-keyframes float2 {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@-webkit-keyframes float2 {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@-o-keyframes float2 {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes float2 {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@-moz-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
@-o-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.menu {
  width: 100%;
  background-color: #f2f2f2;
  position: relative;
  z-index: 10px !important;
}
.menu .main-header {
  max-width: 1440px !important;
  margin: 0 !important;
  position: relative;
  z-index: -999999;
}
@media (max-width: 1440px) {
  .menu .main-header {
    padding: 0 60px 0 36px;
  }
}
@media (max-width: 812px) {
  .menu .main-header {
    min-height: 80px;
  }
}
@media (max-width: 375px) {
  .menu .main-header {
    align-items: center;
  }
}
.menu .header-container {
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  min-height: 117px;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}
@media (max-width: 1570px) {
  .menu .header-container {
    margin: 0 60px;
  }
}
@media (max-width: 812px) {
  .menu .header-container {
    min-height: 80px;
  }
}
@media (max-width: 375px) {
  .menu .header-container {
    align-items: center;
  }
}
.menu .header-container .header-logo {
  width: 107px;
  height: 34px;
}
@media (max-width: 375px) {
  .menu .header-container .header-logo {
    width: 83px;
    height: 26px;
  }
}
.menu .header-container .header-logo a {
  display: block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  display: block;
}
.menu .header-container .header-logo a .header-logo-img {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  fill: #3c3c3b;
}
.menu .header-container .header-logo a .header-logo-img,
.menu .header-container .header-logo a .header-logo-img * {
  fill: #3c3c3b;
}
.menu .header-container .header-logo .header-logo-img-white {
  margin: 0;
  padding: 0;
  width: 107px;
  height: 34px;
  position: absolute;
  top: 22px;
  left: 27px;
  display: none;
  z-index: 50;
}
@media (max-width: 375px) {
  .menu .header-container .header-logo .header-logo-img-white {
    width: 83px;
    height: 26px;
  }
}
.menu .header-container .header-logo .header-logo-img-white svg {
  fill: #fff;
}
@media (max-width: 375px) {
  .menu .header-container .header-logo .header-logo-img-white svg {
    width: 83px;
    height: 26px;
  }
}
.menu .header-container .header-logo .header-logo-img-white svg,
.menu .header-container .header-logo .header-logo-img-white svg * {
  fill: #fff !important;
}
.menu .header-container .header__select {
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 222px;
  height: 40px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #3c3c3b;
  text-transform: lowercase;
  cursor: pointer;
  background-color: #fff;
  position: relative;
  z-index: 10 !important;
}
@media (max-width: 812px) {
  .menu .header-container .header__select {
    display: none;
  }
}
.menu .header-container .header__select::after {
  content: '';
  position: absolute;
  right: 30px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #3c3c3b;
  color: #3c3c3b;
}
.menu .header-container .header__select p {
  font-weight: 700;
  padding: 17px 26px 0 30px;
  width: 100%;
  font-size: 16px;
}
.menu .header-container .header__select .select-list {
  background-color: #f6f6f6;
  list-style-type: none;
  position: absolute;
  z-index: 100000;
  top: 47px;
  width: 222px;
  opacity: 0;
  max-height: 0;
  transition: 0.2s ease-in-out;
  box-shadow: 0px 12px 0px 0px rgba(0,0,0,0.1);
  border-radius: 10px;
}
.menu .header-container .header__select .select-list li:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.menu .header-container .header__select .select-list li:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.menu .header-container .header__select .select-list .header__option {
  padding: 5px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.menu .header-container .header__select .select-list .header__option:hover {
  font-weight: 700;
  background-color: #fff;
}
.menu .header-container .header__select .select-list .header__option a {
  font-size: 16px;
  text-decoration: none;
  color: #3c3c3b;
}
.menu .header-container .header__select .select-list .header__option a .select-item {
  padding: 10px 33px 0 33px;
}
.menu .header-container .header__select .header__option {
  cursor: pointer;
}
.header__view {
  display: flex;
  flex-flow: row;
}
@media (max-width: 1005px) {
  .header__view {
    display: none;
  }
}
.header__view a {
  margin: 0 43px 0 0;
  display: flex;
  flex-flow: row;
  display: block;
  text-decoration: none;
  color: #3c3c3b;
  text-transform: lowercase;
  margin: 0 40px 0 0;
}
.header__view a:nth-child(2) {
  margin: 0;
}
.header__view .option-maths1,
.header__view .option-language1 {
  position: relative;
}
.logIn-select {
  position: relative;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 222px;
  height: 40px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #3c3c3b;
  text-transform: lowercase;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  z-index: 500000;
}
@media (max-width: 812px) {
  .logIn-select {
    display: none;
  }
}
.logIn-select li:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.logIn-select li:last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.logIn-select .header__option {
  padding: 5px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.logIn-select::after {
  content: '';
  margin: 0 37px 0 0;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #3c3c3b;
  color: #3c3c3b;
}
.logIn-select .sign-in-avatar {
  content: '';
  width: 32px;
  height: 32px;
  border-radius: 100px;
  color: #3c3c3b;
  position: absolute;
  right: 62px;
  top: 5px;
}
.logIn-select .sign-in-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.logIn-select p {
  font-weight: 900;
  padding: 17px 20px 0 33px;
  width: 100%;
  font-size: 14px;
}
.logIn-select .logIn-list {
  background-color: #fff;
  list-style-type: none;
  position: absolute;
  top: 55px;
  right: 34px;
  z-index: 100000;
  width: 121px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  opacity: 0;
  max-height: 0;
  transition: 0.2s ease-in-out;
  list-style-type: none;
  box-shadow: 0px 12px 0px 0px rgba(0,0,0,0.1);
  min-height: 105px;
  background-color: #f6f6f6;
}
.logIn-select .logIn-list li a {
  font-size: 14px;
  padding: 10px 13px 10px 33px;
  display: block;
  width: 100%;
  height: 100%;
  font-weight: 400;
  color: #3c3c3b;
  text-decoration: none;
  text-align: right;
}
.logIn-select .logIn-list li a:hover {
  font-weight: 700;
  background-color: #fff;
}
.logIn-select .logIn-list .header__option {
  padding: 5px 0;
}
.logIn-select .logIn-list .header__option a {
  text-decoration: none;
  color: #3c3c3b;
}
.header-sign-in {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 812px) {
  .header-sign-in {
    display: none;
  }
}
button {
  padding: 0;
}
button a {
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.sign-in {
  margin: 0 13px 0 0;
  cursor: pointer;
}
.language {
  right: -40px;
}
.language {
  position: absolute;
  top: 12px;
  right: 0px;
  list-style-type: none;
  z-index: 9999999;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.language:last-child {
  margin-top: 10px;
}
@media (max-width: 1441px) {
  .language {
    right: 0px;
  }
}
@media (max-width: 812px) {
  .language {
    top: 0px;
  }
}
.language li {
  display: none;
  order: 2;
  height: 20px;
  align-items: center;
  justify-content: center;
}
.language li.active {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f57a5b !important;
  transform: none;
  border-left: 4px solid #b2d6dd;
  margin: 0;
  order: 1;
}
.language li.active a {
  color: #f57a5b;
  padding-left: 5px;
  margin: 0;
}
.language a {
  text-decoration: none;
  font-weight: bold;
  color: #b2d6dd;
  line-height: 20px;
  padding-left: 8px;
  font-size: 14px;
  margin-top: 3px;
}
.language * {
  background-color: transparent !important;
}
.register-menu-box {
  display: flex;
  position: relative;
}
.register-menu-box .language {
  position: absolute;
  top: 12px;
  right: -30px;
  margin: 0;
}
@media (max-width: 812px) {
  .register-menu-box .language {
    position: fixed;
    top: 30px;
    right: 20px;
  }
}
@media (max-width: 812px) {
  .position {
    left: 40px;
  }
}
@media (max-width: 812px) {
  .space {
    padding-left: 20px !important;
  }
}
.newsletter {
  max-height: 591px;
  background-color: #f2f2f2;
  padding: 136px 0 275px 0;
  position: relative;
  overflow: hidden;
  border-bottom: 12px solid #fff;
}
@media (max-width: 768px) {
  .newsletter {
    max-height: 800px;
  }
}
@media (max-width: 375px) {
  .newsletter {
    padding: 136px 0 150px 0;
  }
}
.newsletter .newsletter-claud {
  align-items: center;
  width: 353px;
  height: 195px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  top: 31px;
  right: -95px;
  z-index: 0;
}
@media (max-width: 375px) {
  .newsletter .newsletter-claud {
    right: 195px;
  }
}
.newsletter .newsletter-claud2 {
  align-items: center;
  width: 353px;
  height: 195px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  bottom: -59px;
  right: -130px;
  z-index: 0;
}
@media (max-width: 375px) {
  .newsletter .newsletter-claud2 {
    display: none;
  }
}
.newsletter .newsletter-elements {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1440px) {
  .newsletter .newsletter-elements {
    margin: 0 3%;
  }
}
@media (max-width: 375px) {
  .newsletter .newsletter-elements {
    margin: 0 36px;
  }
}
.newsletter .newsletter-elements .newsletter-text {
  z-index: 1;
  text-align: center;
  padding: 0 0 75px 0;
}
.newsletter .newsletter-elements .newsletter-text p {
  font-weight: 500;
}
.newsletter .newsletter-elements .newsletter-text p strong {
  font-weight: 900;
}
.newsletter .newsletter-elements .newsletter-box {
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
}
@media (max-width: 768px) {
  .newsletter .newsletter-elements .newsletter-box {
    flex-flow: column;
  }
}
@media (max-width: 375px) {
  .newsletter .newsletter-elements .newsletter-box {
    width: 100%;
  }
}
.newsletter .newsletter-elements .newsletter-box input[type=text] {
  width: 518px;
  height: 54px;
  border-radius: 10px;
  background-color: #fff;
  margin: 0;
  border: 0;
  outline: 0;
  padding: 0 0 0 20px;
  position: relative;
  z-index: 1;
}
@media (max-width: 768px) {
  .newsletter .newsletter-elements .newsletter-box input[type=text] {
    margin: 0 0 50px 0;
  }
}
@media (max-width: 555px) {
  .newsletter .newsletter-elements .newsletter-box input[type=text] {
    width: 100% !important;
  }
}
.newsletter .newsletter-elements .newsletter-box input[type=text]::placeholder {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  color: #3c3c3b;
}
.newsletter .newsletter-elements .newsletter-box .email-input {
  padding: 10px 15px;
  background-color: #fff;
  outline: none;
  border: none;
  border-radius: 10px;
  width: 500px;
}
@media (max-width: 768px) {
  .newsletter .newsletter-elements .newsletter-box .email-input {
    width: 300px;
  }
}
@media (max-width: 350px) {
  .newsletter .newsletter-elements .newsletter-box .email-input {
    width: 100%;
  }
}
.newsletter .newsletter-elements .newsletter-box .button-box {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 353px;
  height: 195px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  right: -118px;
  bottom: -42px;
}
@media (max-width: 768px) {
  .newsletter .newsletter-elements .newsletter-box .button-box {
    right: -25px;
    bottom: -20px;
    z-index: -1;
  }
}
@media (max-width: 350px) {
  .newsletter .newsletter-elements .newsletter-box .button-box {
    width: 315px;
    height: 174px;
    bottom: -5px;
  }
}
.newsletter .newsletter-elements .newsletter-box .button-width153 {
  margin: 0 0 0 42px;
  z-index: 1;
  position: relative;
  left: -25px;
}
@media (max-width: 368px) {
  .newsletter .newsletter-elements .newsletter-box .button-width153 {
    margin: 0;
  }
}
.newsletter-popup {
  display: none;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 10000000;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
}
.newsletter-popup .chmurka {
  position: absolute;
  top: calc(50% + 113px);
  left: calc(50% - 557px);
  height: 174px;
  width: 317px;
  background-image: url("../../dist/img/globalne/chmukra.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.newsletter-popup .ptok {
  position: absolute;
  top: calc(50% + 164px);
  left: calc(50% - 467px);
  height: 174px;
  width: 317px;
  background-image: url("../../dist/img/globalne/kiwi-right.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.newsletter-popup .newsletter-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f6f6f6;
  padding: 50px;
  max-width: 800px;
  width: 100%;
  height: 500px;
  border-radius: 20px;
}
.newsletter-popup .newsletter-wrapper .newsletter-popup-text {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}
.newsletter-popup .newsletter-wrapper .email-input {
  padding: 10px 15px;
  background-color: #fff;
  outline: none;
  border: none;
  border-radius: 10px;
  margin: 5px 0 20px 0;
  width: 100%;
}
.newsletter-popup .newsletter-wrapper .email-text {
  font-size: 15px;
  margin-bottom: 0;
}
.newsletter-popup .newsletter-wrapper input[type=submit] {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.newsletter-popup .newsletter-wrapper .close-div {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newsletter-popup .newsletter-wrapper .close-div p.close-btn {
  width: fit-content;
  color: #f57a5b;
  cursor: pointer;
}
.footer {
  height: 145px;
  width: 100%;
  background: #3c3c3b;
  position: relative;
  display: flex;
  align-items: center;
}
@media (max-width: 628px) {
  .footer {
    height: 437px;
  }
}
.footer .footer-container {
  max-width: 1440px;
  margin: 0 auto;
  height: 145px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 1440px) {
  .footer .footer-container {
    margin: 0 3%;
  }
}
@media (max-width: 628px) {
  .footer .footer-container {
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
    margin: 0 36px;
  }
}
.footer .footer-container .right-part {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
@media (max-width: 628px) {
  .footer .footer-container .right-part {
    margin: 47px 0 0 0;
    justify-content: space-between;
  }
}
.footer .footer-container .right-part .email {
  font-weight: 700;
  margin: 0 40px 0 0;
  color: #fff;
}
.footer .footer-container .right-part .social-media {
  display: flex;
  flex-flow: row;
}
.footer .footer-container .right-part .social-media a {
  margin: 0 0 0 15px;
  text-decoration: none;
  cursor: pointer;
}
.footer .footer-container .left-part {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 628px) {
  .footer .footer-container .left-part {
    flex-flow: column;
  }
}
.footer .footer-container .left-part .polityka-prywatnosci,
.footer .footer-container .left-part .warunki-korzystania {
  width: 100%;
  margin: 0 15px;
}
@media (max-width: 628px) {
  .footer .footer-container .left-part .polityka-prywatnosci,
  .footer .footer-container .left-part .warunki-korzystania {
    margin: 0 0 26px 0;
  }
}
.footer .footer-container .left-part .polityka-prywatnosci a,
.footer .footer-container .left-part .warunki-korzystania a {
  font-weight: 700;
  margin: 0;
  color: #fff;
  text-decoration: none;
}
.footer .footer-container .left-part .contact {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 15px;
}
@media (max-width: 628px) {
  .footer .footer-container .left-part .contact {
    margin: 0 0 26px 0;
  }
}
.footer .footer-container .left-part .contact a {
  font-weight: 700;
  margin: 0;
  color: #fff;
  text-decoration: none;
}
.footer .obrazek {
  width: 311px;
  height: 233px;
  position: absolute;
  top: -38%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 628px) {
  .footer .obrazek {
    top: 0px;
    width: 240px;
    height: 179px;
  }
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  margin: 50px 0;
}
.lds-ripple.active div {
  display: block !important;
}
.lds-ripple div {
  height: 150px;
  display: none;
  position: absolute;
  border: 4px solid #f57a5b;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
.dow-loader-box {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255,255,255,0.6);
  z-index: 100000;
}
.dow-loader-box .lds-ripple {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
.banner-policy-wrapper {
  position: relative;
  z-index: 0;
}
.banner-policy-wrapper .policy-claud2 {
  background-image: url("../../dist/img/globalne/chmukra.png");
  background-position: center;
  background-size: cover;
  width: 346px;
  height: 191px;
  position: absolute;
  bottom: -1px;
  right: 129px;
  z-index: 0;
}
.banner-policy-wrapper .baner-content {
  width: 100%;
  height: 100vh;
}
.banner-policy-wrapper .baner-content .wrapper-for-pictograms {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 10px;
}
@media (max-width: 1200px) {
  .banner-policy-wrapper .baner-content .wrapper-for-pictograms {
    justify-content: flex-start;
    flex-flow: row wrap;
  }
}
.banner-download-wrapper,
.banner-policy-wrapper {
  background-color: #a3ced6;
  position: relative;
  padding: 90px 0 0 0;
  min-height: 375px;
  max-height: 375px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
}
.banner-download-wrapper .clouds1,
.banner-policy-wrapper .clouds1 {
  background-image: url("../../dist/img/globalne/chmukra.png");
  background-position: center;
  background-size: cover;
  width: 310px;
  height: 170px;
  position: absolute;
  bottom: -1px;
  right: 60px;
  z-index: 5;
  display: none;
}
@media (max-width: 960px) {
  .banner-download-wrapper .clouds1,
  .banner-policy-wrapper .clouds1 {
    display: flex;
  }
}
@media (max-width: 375px) {
  .banner-download-wrapper .clouds1,
  .banner-policy-wrapper .clouds1 {
    right: -90px;
  }
}
.banner-download-wrapper .download-cloud-blue1,
.banner-policy-wrapper .download-cloud-blue1 {
  width: 759px;
  height: 418px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  left: 112px;
  bottom: 0px;
  opacity: 0.1;
  z-index: 0;
}
@media (max-width: 375px) {
  .banner-download-wrapper .download-cloud-blue1,
  .banner-policy-wrapper .download-cloud-blue1 {
    left: -485px;
    bottom: -30px;
  }
}
.banner-download-wrapper .baner-content,
.banner-policy-wrapper .baner-content {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.banner-download-wrapper .baner-content .baner-download-header,
.banner-policy-wrapper .baner-content .baner-download-header {
  font-weight: 700;
  position: relative;
  z-index: 15;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.banner-download-wrapper .baner-content .baner-download-header .baner-download-description,
.banner-policy-wrapper .baner-content .baner-download-header .baner-download-description {
  font-weight: 100;
  text-align: center;
}
@media (max-width: 960px) {
  .banner-download-wrapper .baner-content .baner-download-header .baner-download-description,
  .banner-policy-wrapper .baner-content .baner-download-header .baner-download-description {
    display: none;
  }
}
.banner-download-wrapper .pictograms,
.banner-policy-wrapper .pictograms {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  justify-content: center;
  z-index: 5;
}
@media (max-width: 960px) {
  .banner-download-wrapper .pictograms,
  .banner-policy-wrapper .pictograms {
    display: none;
  }
}
.banner-download-wrapper .pictograms .pictograms-wrapper,
.banner-policy-wrapper .pictograms .pictograms-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100px;
}
@media (max-width: 1440px) {
  .banner-download-wrapper .pictograms .pictograms-wrapper,
  .banner-policy-wrapper .pictograms .pictograms-wrapper {
    padding: 0 27px;
  }
}
.banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms,
.banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media (max-width: 1200px) {
  .banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms,
  .banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms {
    justify-content: flex-start;
    flex-flow: row wrap;
  }
}
.banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description,
.banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description {
  margin: 0 73px 0 0;
  width: 30%;
}
.banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description p,
.banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description p {
  padding: 0;
  margin: 0;
}
.banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description p strong,
.banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description p strong {
  font-weight: 900;
}
.banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description-screenflies,
.banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description-screenflies {
  display: flex;
  flex-flow: column;
  width: 30%;
}
.banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description-screenflies p,
.banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description-screenflies p {
  padding: 0;
  margin: 0;
}
.banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description-screenflies p strong,
.banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram-description-screenflies p strong {
  font-weight: 900;
}
.banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .option2,
.banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .option2 {
  width: 50%;
}
.banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram,
.banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram {
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 20px 0px 0;
  display: flex;
  justify-content: flex-end;
}
.banner-download-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram img,
.banner-policy-wrapper .pictograms .pictograms-wrapper .wrapper-for-pictograms .pictogram img {
  width: auto;
  height: 35px;
}
.banner-download-wrapper .pictograms .button-wrapper,
.banner-policy-wrapper .pictograms .button-wrapper {
  position: relative;
  width: auto;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 49px 0 39px;
  position: relative;
  margin: 0;
}
.banner-download-wrapper .pictograms .button-wrapper .header__download-box-elements,
.banner-policy-wrapper .pictograms .button-wrapper .header__download-box-elements {
  display: flex;
  flex-flow: row;
  justify-content: center;
  padding: 0 10px 0 0;
}
.banner-download-wrapper .pictograms .button-wrapper .header__download-box-elements img,
.banner-policy-wrapper .pictograms .button-wrapper .header__download-box-elements img {
  widows: 27px;
  height: 34px;
}
.banner-download-wrapper .pictograms .button-wrapper .header__download-box-elements .header__download--count,
.banner-policy-wrapper .pictograms .button-wrapper .header__download-box-elements .header__download--count {
  background-color: transparent !important;
  font-weight: 700;
  font-size: 14px;
  position: relative;
  top: 3px;
  right: 0px;
}
.banner-download-wrapper .pictograms .button-wrapper .header__download-box-elements span,
.banner-policy-wrapper .pictograms .button-wrapper .header__download-box-elements span {
  position: relative;
  top: 9px;
  right: 10px;
  font-weight: 700;
  font-size: 14px;
  margin: 0 0 0 3px;
}
.banner-download-wrapper .pictograms .button-width170,
.banner-policy-wrapper .pictograms .button-width170 {
  margin: 0 0 0 20px;
}
.card-download {
  position: relative;
  z-index: 10;
  cursor: pointer;
}
.popup-wrapper,
.popup-wrapper-limit {
  width: 100%;
  height: 100vh;
  background-color: rgba(60,60,59,0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}
.popup-wrapper .popup-cotent,
.popup-wrapper-limit .popup-cotent {
  background-color: #f2f2f2;
  width: 302px;
  height: 288px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  position: relative;
  border-radius: 20px;
  padding: 60px;
  font-family: 'Lato', sans-serif !important;
  padding: 50px;
}
.popup-wrapper .popup-cotent .button-width185,
.popup-wrapper-limit .popup-cotent .button-width185 {
  width: 103px;
  cursor: pointer;
  margin: 40px 0 0 0;
}
.popup-wrapper .popup-cotent .button-width185 p,
.popup-wrapper-limit .popup-cotent .button-width185 p {
  padding: 8px 0 0 0;
  font-weight: 700;
  font-size: 16px;
}
.popup-wrapper .popup-cotent .main-header,
.popup-wrapper-limit .popup-cotent .main-header {
  width: 100%;
  font-family: 'Lato', sans-serif;
  text-align: center;
}
.popup-wrapper .popup-cotent .main-header .notice-header,
.popup-wrapper-limit .popup-cotent .main-header .notice-header {
  line-height: 2;
  font-family: 'Lato', sans-serif !important;
}
.popup-wrapper .popup-cotent .main-header .notice-header h2,
.popup-wrapper-limit .popup-cotent .main-header .notice-header h2 {
  width: 100%;
  color: #3c3c3b;
  font-family: 'Lato', sans-serif !important;
  font-weight: 900;
  font-size: 20px !important;
  margin: 0 0 15px 0;
}
.popup-wrapper .popup-cotent .main-header .notice-header .notice,
.popup-wrapper-limit .popup-cotent .main-header .notice-header .notice {
  color: pleo-dark-green;
  font-family: 'Lato', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400;
}
.error {
  display: flex;
  flex-flow: column;
  padding: 0;
  color: #f57a5b;
}
.cards-popup-wrapper {
  height: 100vh;
  background-color: rgba(60,60,59,0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
}
.cards-popup-wrapper .cards-popup-cotent {
  background-color: #f2f2f2;
  width: 302px;
  height: 288px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  position: relative;
  border-radius: 20px;
  font-family: 'Lato', sans-serif !important;
  padding: 50px;
}
.cards-popup-wrapper .cards-popup-cotent .button-width185 {
  width: 103px;
  cursor: pointer;
  margin: 20px 0 0 0;
}
.cards-popup-wrapper .cards-popup-cotent .button-width185 p {
  padding: 8px 0 0 0;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
}
.cards-popup-wrapper .cards-popup-cotent .main-header {
  width: 100%;
  font-family: 'Lato', sans-serif;
  text-align: center;
}
.cards-popup-wrapper .cards-popup-cotent .main-header .notice-header {
  line-height: 2;
  font-family: 'Lato', sans-serif !important;
}
.cards-popup-wrapper .cards-popup-cotent .main-header .notice-header h2 {
  width: 100%;
  color: #3c3c3b;
  font-family: 'Lato', sans-serif !important;
  font-weight: 900;
  font-size: 20px !important;
  margin: 0 0 15px 0;
}
.cards-popup-wrapper .cards-popup-cotent .main-header .notice-header .notice {
  color: pleo-dark-green;
  font-family: 'Lato', sans-serif !important;
  font-size: 16px !important;
  font-weight: 400;
}
.error {
  display: flex;
  flex-flow: column;
  padding: 0;
  color: #f57a5b;
}
.menu .hamburger-menu {
  display: none;
  position: relative;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 22px;
  margin-right: 40px;
  height: 20px;
  cursor: pointer;
  transition: 0.5s;
}
.menu .hamburger-menu .bar1 {
  width: 100%;
  height: 2px;
  background-color: #3c3c3b;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}
.menu .hamburger-menu .bar2 {
  width: 100%;
  height: 2px;
  background-color: #3c3c3b;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.menu .hamburger-menu .bar3 {
  width: 100%;
  height: 2px;
  background-color: #3c3c3b;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: 0.5s;
}
@media (max-width: 812px) {
  .menu .hamburger-menu {
    display: flex;
  }
}
@media (max-width: 812px) and (max-width: 812px) {
  .menu .hamburger-menu {
    display: flex;
  }
}
.menu .menu-elements {
  z-index: -5;
  width: 100vw;
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  opacity: 0;
  transition: 0.2s linear;
  background-color: #3c3c3b !important;
}
.menu .menu-elements > .menu-mb-list {
  text-align: center;
  background-color: #3c3c3b !important;
  width: 100vw;
}
.menu .menu-elements > .menu-mb-list .list-header {
  font-size: 16px;
  font-weight: 700;
  color: #f57a5b;
  width: 100%;
  margin: 50px 0 22px 0;
}
.menu .menu-elements > .menu-mb-list li {
  list-style-type: none;
  position: relative;
  cursor: pointer;
  padding: 0 0 27px 0;
}
.menu .menu-elements > .menu-mb-list li :hover {
  font-weight: 900;
}
.menu .menu-elements > .menu-mb-list li a {
  padding: 10px 0;
  display: block;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  display: inline-block;
  position: relative;
  font-weight: 400;
}
.menu .menu-elements > .menu-mb-list li a::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: #f57a5b;
  color: #a2ccd8;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  left: -15px;
  top: 20px;
}
.sub-dysciplin {
  display: flex;
  flex-flow: column;
}
.sub-dysciplin :hover {
  font-weight: 900;
}
.sub-dysciplin a {
  text-decoration: none;
  color: #fff;
  padding: 0 0 27px 0;
}
.button-line {
  padding-left: 50%;
  width: 300px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-flow: column;
  margin: 0px 0 0px 0;
}
.button-line .shop-url-ham {
  margin-right: 14px;
  padding: 9px;
  font: bold Lato sans-serif;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  box-sizing: border-box;
}
.button-line a {
  padding: 10px 0;
  display: block;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400;
}
.button-line a button {
  cursor: pointer;
}
@media (max-width: 812px) {
  .button-line a button {
    width: 226px;
  }
}
.social-line {
  display: flex;
  flex-flow: row;
  justify-content: center;
  margin: 0 0 50px 0;
}
.social-line .piterest {
  margin: 0 6px 0 0;
}
.active {
  transform: rotate(90deg);
}
.hamburger-menu.active .bar1 {
  top: calc(50% - 2px);
  transform: rotate(50deg);
  background-color: #f57a5b;
}
.hamburger-menu.active .bar3 {
  bottom: calc(50%);
  transform: rotate(-50deg);
  background-color: #f57a5b;
}
.hamburger-menu.active .bar2 {
  display: none;
}
.language-mb {
  list-style-type: none;
  display: flex;
  justify-content: center;
}
.language-mb li {
  height: 20px;
  margin: 20px 0;
  padding: 0;
  padding-left: 20px !important;
  padding-right: 20px !important;
  display: flex;
}
.language-mb li.active-mb a {
  color: #f57a5b !important;
}
.language-mb li a {
  padding: 0 !important;
  color: #a2ccd8 !important;
  cursor: pointer;
}
.language-mb li *::before {
  content: none !important;
}
.language-mb li:last-of-type {
  padding: 0;
  padding-left: 20px !important;
  border-left: 2px solid #a2ccd8;
}
.limitAlert {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0.1rem 0.1rem 1rem black;
  padding: 3rem;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s all;
  z-index: 10000;
}
.limitAlert-text {
  text-align: center;
  font-size: 2rem;
}
.limitAlert.popup-wrapper-limit .popup-cotent {
  padding: 40px;
}
.amountAlert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0.1rem 0.1rem 1rem black;
  padding: 3rem;
  border-radius: 3px;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s all;
  z-index: 10000;
}
.amountAlert.popup-wrapper-limit .popup-cotent {
  padding: 40px;
}
.amountAlert .amountAlert-counter {
  font-size: 42px;
  line-height: 42px;
  color: #a2ccd8;
  font-weight: bold;
}
.popup-close-x {
  position: relative;
}
.popup-close-x .button-close {
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 50%;
  top: -7px;
  right: -8px;
  border: none;
  box-shadow: 0px 3px 0px 0px #d1cdcd;
  cursor: pointer;
  z-index: 100;
}
.filter-header-select {
  margin: 0 0 105px 0;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
.filter-header-select ul li:hover {
  background-color: #fff;
}
@media (max-width: 900px) {
  .filter-header-select {
    flex-flow: column;
    padding: 0 36px;
  }
}
@media (max-width: 375px) {
  .filter-header-select {
    flex-flow: column;
    margin: 0 0 43px 0;
  }
}
.filter-header-select .filter1,
.filter-header-select .filter2 {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin: 23px 0;
  cursor: pointer;
}
@media (max-width: 504px) {
  .filter-header-select .filter1,
  .filter-header-select .filter2 {
    flex-flow: column;
    width: 100%;
  }
}
.filter-header-select .filter1 p,
.filter-header-select .filter2 p {
  font-size: 16px;
  height: 100%;
  padding: 0;
  margin: 0;
  font-weight: 700;
  margin: 0 10px 0 0;
  min-width: 130px;
}
@media (max-width: 375px) {
  .filter-header-select .filter1 p,
  .filter-header-select .filter2 p {
    width: 100%;
    margin: 0 0 11px 0;
  }
}
.filter-header-select .filter1 .filter-select-list-level,
.filter-header-select .filter2 .filter-select-list-level,
.filter-header-select .filter1 .filter-select-list-category,
.filter-header-select .filter2 .filter-select-list-category {
  width: 158px;
  background-color: #f6f6f6;
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  height: 40px;
  width: 158px;
  margin: 0 37px 0 0;
  position: relative;
  text-align: left;
}
@media (max-width: 900px) {
  .filter-header-select .filter1 .filter-select-list-level,
  .filter-header-select .filter2 .filter-select-list-level,
  .filter-header-select .filter1 .filter-select-list-category,
  .filter-header-select .filter2 .filter-select-list-category {
    width: 261px;
  }
}
@media (max-width: 504px) {
  .filter-header-select .filter1 .filter-select-list-level,
  .filter-header-select .filter2 .filter-select-list-level,
  .filter-header-select .filter1 .filter-select-list-category,
  .filter-header-select .filter2 .filter-select-list-category {
    width: 100%;
    margin: 0;
  }
}
.filter-header-select .filter1 .filter-select-list-level::after,
.filter-header-select .filter2 .filter-select-list-level::after,
.filter-header-select .filter1 .filter-select-list-category::after,
.filter-header-select .filter2 .filter-select-list-category::after {
  content: '';
  position: absolute;
  right: 30px;
  top: 16px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #3c3c3b;
  color: #3c3c3b;
}
.filter-header-select .filter1 .filter-select-list-level .level-choose,
.filter-header-select .filter2 .filter-select-list-level .level-choose,
.filter-header-select .filter1 .filter-select-list-category .level-choose,
.filter-header-select .filter2 .filter-select-list-category .level-choose,
.filter-header-select .filter1 .filter-select-list-level .category-choose,
.filter-header-select .filter2 .filter-select-list-level .category-choose,
.filter-header-select .filter1 .filter-select-list-category .category-choose,
.filter-header-select .filter2 .filter-select-list-category .category-choose {
  font-weight: 700;
  padding: 8px 26px 0 30px;
  width: 100%;
  font-size: 16px;
}
.filter-header-select .filter1 .filter-select-list-level .filter-list-category,
.filter-header-select .filter2 .filter-select-list-level .filter-list-category,
.filter-header-select .filter1 .filter-select-list-category .filter-list-category,
.filter-header-select .filter2 .filter-select-list-category .filter-list-category,
.filter-header-select .filter1 .filter-select-list-level .filter-list-level,
.filter-header-select .filter2 .filter-select-list-level .filter-list-level,
.filter-header-select .filter1 .filter-select-list-category .filter-list-level,
.filter-header-select .filter2 .filter-select-list-category .filter-list-level {
  list-style-type: none;
  background-color: #f6f6f6;
  position: absolute;
  top: 47px;
  width: 100%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  z-index: -1;
  display: none;
  flex-flow: column;
  opacity: 0;
  height: 0;
  transition: 0.2s ease-in-out;
  box-shadow: 0px 12px 0px 0px rgba(0,0,0,0.1);
}
.filter-header-select .filter1 .filter-select-list-level .filter-list-category :last-child,
.filter-header-select .filter2 .filter-select-list-level .filter-list-category :last-child,
.filter-header-select .filter1 .filter-select-list-category .filter-list-category :last-child,
.filter-header-select .filter2 .filter-select-list-category .filter-list-category :last-child,
.filter-header-select .filter1 .filter-select-list-level .filter-list-level :last-child,
.filter-header-select .filter2 .filter-select-list-level .filter-list-level :last-child,
.filter-header-select .filter1 .filter-select-list-category .filter-list-level :last-child,
.filter-header-select .filter2 .filter-select-list-category .filter-list-level :last-child {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.filter-header-select .filter1 .filter-select-list-level .filter-list-category :first-child,
.filter-header-select .filter2 .filter-select-list-level .filter-list-category :first-child,
.filter-header-select .filter1 .filter-select-list-category .filter-list-category :first-child,
.filter-header-select .filter2 .filter-select-list-category .filter-list-category :first-child,
.filter-header-select .filter1 .filter-select-list-level .filter-list-level :first-child,
.filter-header-select .filter2 .filter-select-list-level .filter-list-level :first-child,
.filter-header-select .filter1 .filter-select-list-category .filter-list-level :first-child,
.filter-header-select .filter2 .filter-select-list-category .filter-list-level :first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.filter-header-select .filter1 .filter-select-list-level .filter-list-category .filter-header-option-level,
.filter-header-select .filter2 .filter-select-list-level .filter-list-category .filter-header-option-level,
.filter-header-select .filter1 .filter-select-list-category .filter-list-category .filter-header-option-level,
.filter-header-select .filter2 .filter-select-list-category .filter-list-category .filter-header-option-level,
.filter-header-select .filter1 .filter-select-list-level .filter-list-level .filter-header-option-level,
.filter-header-select .filter2 .filter-select-list-level .filter-list-level .filter-header-option-level,
.filter-header-select .filter1 .filter-select-list-category .filter-list-level .filter-header-option-level,
.filter-header-select .filter2 .filter-select-list-category .filter-list-level .filter-header-option-level,
.filter-header-select .filter1 .filter-select-list-level .filter-list-category .filter-header-option-category,
.filter-header-select .filter2 .filter-select-list-level .filter-list-category .filter-header-option-category,
.filter-header-select .filter1 .filter-select-list-category .filter-list-category .filter-header-option-category,
.filter-header-select .filter2 .filter-select-list-category .filter-list-category .filter-header-option-category,
.filter-header-select .filter1 .filter-select-list-level .filter-list-level .filter-header-option-category,
.filter-header-select .filter2 .filter-select-list-level .filter-list-level .filter-header-option-category,
.filter-header-select .filter1 .filter-select-list-category .filter-list-level .filter-header-option-category,
.filter-header-select .filter2 .filter-select-list-category .filter-list-level .filter-header-option-category {
  list-style-type: none;
  padding: 10px 33px 10px 33px;
}
.filter-header-select .filter1 .filter-select-list-level .filter-list-category .filter-header-option-level .filter-select-item-level,
.filter-header-select .filter2 .filter-select-list-level .filter-list-category .filter-header-option-level .filter-select-item-level,
.filter-header-select .filter1 .filter-select-list-category .filter-list-category .filter-header-option-level .filter-select-item-level,
.filter-header-select .filter2 .filter-select-list-category .filter-list-category .filter-header-option-level .filter-select-item-level,
.filter-header-select .filter1 .filter-select-list-level .filter-list-level .filter-header-option-level .filter-select-item-level,
.filter-header-select .filter2 .filter-select-list-level .filter-list-level .filter-header-option-level .filter-select-item-level,
.filter-header-select .filter1 .filter-select-list-category .filter-list-level .filter-header-option-level .filter-select-item-level,
.filter-header-select .filter2 .filter-select-list-category .filter-list-level .filter-header-option-level .filter-select-item-level,
.filter-header-select .filter1 .filter-select-list-level .filter-list-category .filter-header-option-category .filter-select-item-level,
.filter-header-select .filter2 .filter-select-list-level .filter-list-category .filter-header-option-category .filter-select-item-level,
.filter-header-select .filter1 .filter-select-list-category .filter-list-category .filter-header-option-category .filter-select-item-level,
.filter-header-select .filter2 .filter-select-list-category .filter-list-category .filter-header-option-category .filter-select-item-level,
.filter-header-select .filter1 .filter-select-list-level .filter-list-level .filter-header-option-category .filter-select-item-level,
.filter-header-select .filter2 .filter-select-list-level .filter-list-level .filter-header-option-category .filter-select-item-level,
.filter-header-select .filter1 .filter-select-list-category .filter-list-level .filter-header-option-category .filter-select-item-level,
.filter-header-select .filter2 .filter-select-list-category .filter-list-level .filter-header-option-category .filter-select-item-level,
.filter-header-select .filter1 .filter-select-list-level .filter-list-category .filter-header-option-level .filter-select-item-category,
.filter-header-select .filter2 .filter-select-list-level .filter-list-category .filter-header-option-level .filter-select-item-category,
.filter-header-select .filter1 .filter-select-list-category .filter-list-category .filter-header-option-level .filter-select-item-category,
.filter-header-select .filter2 .filter-select-list-category .filter-list-category .filter-header-option-level .filter-select-item-category,
.filter-header-select .filter1 .filter-select-list-level .filter-list-level .filter-header-option-level .filter-select-item-category,
.filter-header-select .filter2 .filter-select-list-level .filter-list-level .filter-header-option-level .filter-select-item-category,
.filter-header-select .filter1 .filter-select-list-category .filter-list-level .filter-header-option-level .filter-select-item-category,
.filter-header-select .filter2 .filter-select-list-category .filter-list-level .filter-header-option-level .filter-select-item-category,
.filter-header-select .filter1 .filter-select-list-level .filter-list-category .filter-header-option-category .filter-select-item-category,
.filter-header-select .filter2 .filter-select-list-level .filter-list-category .filter-header-option-category .filter-select-item-category,
.filter-header-select .filter1 .filter-select-list-category .filter-list-category .filter-header-option-category .filter-select-item-category,
.filter-header-select .filter2 .filter-select-list-category .filter-list-category .filter-header-option-category .filter-select-item-category,
.filter-header-select .filter1 .filter-select-list-level .filter-list-level .filter-header-option-category .filter-select-item-category,
.filter-header-select .filter2 .filter-select-list-level .filter-list-level .filter-header-option-category .filter-select-item-category,
.filter-header-select .filter1 .filter-select-list-category .filter-list-level .filter-header-option-category .filter-select-item-category,
.filter-header-select .filter2 .filter-select-list-category .filter-list-level .filter-header-option-category .filter-select-item-category {
  color: #000;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 700;
}
.filter-header-select .filter1 .category,
.filter-header-select .filter2 .category {
  width: 261px;
}
@media (max-width: 504px) {
  .filter-header-select .filter1 .category,
  .filter-header-select .filter2 .category {
    width: 100%;
  }
}
.filter-header-select .reset-filter-button {
  cursor: pointer;
}
.popup__info-level {
  padding-bottom: 2px;
  margin-left: 4px;
}
.popup__info-icon-category {
  margin-left: 5px;
  padding: 1px 3px 3px 3px;
  width: 32px;
  height: 32px;
}
.menu-registery-wrapper {
  background-color: #a3ced6;
}
@media (max-width: 812px) {
  .menu-registery-wrapper {
    background-color: #f2f2f2;
  }
}
.menu-registery-wrapper .registery-menu-container .logo {
  width: 107px;
  height: 34px;
}
.menu-registery-wrapper .registery-menu-container .register-button {
  display: block;
}
.menu-registery-wrapper .registery-menu-container .register-button .button-width107 {
  margin: 0 3% 0 0;
  background-color: #fff;
}
@media (max-width: 812px) {
  .menu-registery-wrapper .registery-menu-container .register-button {
    display: none;
  }
}
.menu-login-wrapper {
  background-color: #a3ced6;
}
@media (max-width: 812px) {
  .menu-login-wrapper {
    background-color: #f2f2f2;
  }
}
.menu-login-wrapper .menu-container .logo {
  width: 107px;
  height: 34px;
}
.menu-login-wrapper .menu-container .login-button {
  display: block;
  cursor: pointer !important;
}
.menu-login-wrapper .menu-container .login-button .button-width198 {
  cursor: pointer !important;
  margin: 0 3% 0 0;
}
@media (max-width: 812px) {
  .menu-login-wrapper .menu-container .login-button {
    display: none;
  }
}
.menu-actions {
  position: relative;
}
.menu-actions .shop-url {
  margin-right: 4px;
  padding: 9px;
  font: bold Lato sans-serif;
  text-decoration: none;
  color: #f57a5b;
  font-size: 16px;
  box-sizing: border-box;
}
.menu-actions .shop-url:hover {
  color: #3c3c3b !important;
}
.limits-show {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -50px;
  width: 60px;
  height: 30px;
  background-color: #f57a5b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #fff;
}
@media (max-width: 812px) {
  .limits-show {
    left: -77px;
  }
}
.sign-in-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 30px;
}
.sign-in-text .account-type {
  font-size: 12px;
  color: #f57a5b;
  text-align: right;
  margin-top: -5px;
}
.sign-in-text p {
  padding: 0;
  margin: 0;
  line-height: normal;
}
.limits-link {
  font-size: 16px;
}
.limits-show.free {
  display: none;
}
.limits-show.premium {
  display: none;
}
.limits-show.premium span {
  font-size: 40px;
  font-weight: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sign-langs li.active {
  border-left: 2px solid #fff !important;
}
.sign-langs li.active a {
  color: black !important;
}
.sign-langs li a {
  color: #fff !important;
}
a {
  color: #f57a5b;
  font-weight: bold;
  text-decoration: underline;
}
a:hover {
  color: #f57a5b;
}
.cookies {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5px;
  width: calc(100vw - 25px);
  background-color: rgba(0,0,0,0.55);
  padding: 20px 40px;
  border-radius: 20px;
  z-index: 999999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: #fff;
}
.cookies-img {
  height: 100px;
  margin-right: 20px;
}
.cookies-accept {
  cursor: pointer;
}
.cookies .cookies-text {
  padding-right: 50px;
}
.cookies button {
  padding: 0px 25px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .cookies .cookies-text {
    padding-right: 0px;
  }
}
@media (max-width: 500px) {
  .cookies {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
  .cookies .cookies-img {
    display: none;
  }
  .cookies .cookies-text {
    height: 175px;
    overflow-y: scroll;
    font-size: 13px;
    margin-bottom: 15px;
  }
}
section.banner-image {
  height: 600px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 1024px) {
  section.banner-image {
    height: 375px;
  }
}
@media (max-width: 648px) {
  section.banner-image {
    height: 315px;
  }
}
@media (max-width: 480px) {
  section.banner-image {
    height: 235px;
  }
}
.banner {
  min-height: 695px;
  width: 100%;
  padding: 170px 0 0 0;
  background-color: #9fd6c1;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  border-bottom: 6px solid #fff;
}
@media (max-width: 600px) {
  .banner {
    min-height: 733px;
  }
}
.banner .banner-elements-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.banner .banner-elements-wrapper .baner-elements {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  display: grid;
  overflow: 0;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}
@media (max-width: 600px) {
  .banner .banner-elements-wrapper .baner-elements {
    overflow: hidden;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
}
.banner .banner-elements-wrapper .baner-elements .pantofelek-color {
  grid-area: pantofelek-color;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  position: absolute;
  top: 34px;
  left: -43px;
  max-width: 189px;
  max-height: 136px;
}
@media (max-width: 1024px) {
  .banner .banner-elements-wrapper .baner-elements .pantofelek-color {
    top: 0;
  }
}
.banner .banner-elements-wrapper .baner-elements .pantofelek-color img {
  width: 100%;
}
.banner .banner-elements-wrapper .baner-elements .pantofelek-bw {
  grid-area: pantofelek-bw;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;
  position: absolute;
  top: -183px;
  left: 0px;
  z-index: 0;
}
@media (max-width: 600px) {
  .banner .banner-elements-wrapper .baner-elements .pantofelek-bw {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }
}
@media (max-width: 375px) {
  .banner .banner-elements-wrapper .baner-elements .pantofelek-bw {
    top: -105px;
    left: -150px;
  }
}
.banner .banner-elements-wrapper .baner-elements .pantofelek-bw img {
  max-width: 933px;
  max-height: 676px;
}
@media (max-width: 375px) {
  .banner .banner-elements-wrapper .baner-elements .pantofelek-bw img {
    max-width: 604px;
    max-height: 437px;
  }
}
.banner .banner-elements-wrapper .baner-elements .slimak {
  grid-area: slimak;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 2;
  grid-row-end: 2;
  position: absolute;
  top: 0px;
  left: 106px;
  max-width: 231px;
  max-height: 105px;
}
@media (max-width: 1024px) {
  .banner .banner-elements-wrapper .baner-elements .slimak {
    left: 0px;
  }
}
@media (max-width: 600px) {
  .banner .banner-elements-wrapper .baner-elements .slimak {
    display: none;
  }
}
.banner .banner-elements-wrapper .baner-elements .slimak img {
  width: 100%;
}
.banner .banner-elements-wrapper .baner-elements .zaba {
  grid-area: zaba;
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 3;
  position: absolute;
  left: -34px;
  bottom: 62px;
  max-width: 164px;
  max-height: 124px;
}
@media (max-width: 600px) {
  .banner .banner-elements-wrapper .baner-elements .zaba {
    left: -34px;
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 4;
    grid-row-end: 4;
  }
}
@media (max-width: 375px) {
  .banner .banner-elements-wrapper .baner-elements .zaba {
    left: -44px;
  }
}
.banner .banner-elements-wrapper .baner-elements .zaba img {
  width: 100%;
}
.banner .banner-elements-wrapper .baner-elements .glupik {
  grid-area: glupik;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 3;
  grid-row-end: 3;
  position: absolute;
  top: -47px;
  left: -58px;
  max-width: 224px;
  max-height: 104px;
}
@media (max-width: 600px) {
  .banner .banner-elements-wrapper .baner-elements .glupik {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
    left: 50px;
    top: 0;
  }
}
@media (max-width: 375px) {
  .banner .banner-elements-wrapper .baner-elements .glupik {
    left: 12px;
    top: 48px;
  }
}
.banner .banner-elements-wrapper .baner-elements .glupik img {
  width: 100%;
}
@media (max-width: 375px) {
  .banner .banner-elements-wrapper .baner-elements .glupik img {
    width: 150%;
  }
}
.banner .banner-elements-wrapper .baner-elements .malpa {
  grid-area: malpa;
  grid-column-start: 3;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: 3;
  position: absolute;
  left: -75px;
  bottom: -7px;
  max-width: 235px;
  max-height: 267px;
}
@media (max-width: 768px) {
  .banner .banner-elements-wrapper .baner-elements .malpa {
    left: -75px;
  }
}
@media (max-width: 600px) {
  .banner .banner-elements-wrapper .baner-elements .malpa {
    left: -25px;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 4;
  }
}
@media (max-width: 375px) {
  .banner .banner-elements-wrapper .baner-elements .malpa {
    left: -15px;
  }
}
.banner .banner-elements-wrapper .baner-elements .malpa img {
  width: 100%;
}
@media (max-width: 375px) {
  .banner .banner-elements-wrapper .baner-elements .malpa img {
    width: 120%;
  }
}
.banner .banner-elements-wrapper .baner-elements .czlowiek {
  grid-area: czlowiek;
  grid-column-start: 4;
  grid-column-end: 4;
  grid-row-start: 2;
  position: absolute;
  left: -18px;
  bottom: -7px;
  max-width: 189px;
  max-height: 425px;
}
@media (max-width: 768px) {
  .banner .banner-elements-wrapper .baner-elements .czlowiek {
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 2;
  }
}
@media (max-width: 600px) {
  .banner .banner-elements-wrapper .baner-elements .czlowiek {
    grid-column-start: 3;
    grid-column-end: 3;
  }
}
@media (max-width: 375px) {
  .banner .banner-elements-wrapper .baner-elements .czlowiek {
    position: relative;
    left: 9px;
    bottom: -105px;
    max-height: 305px;
  }
}
@media (max-width: 360px) {
  .banner .banner-elements-wrapper .baner-elements .czlowiek {
    bottom: -120px;
  }
}
.banner .banner-elements-wrapper .baner-elements .czlowiek img {
  width: 100%;
}
@media (max-width: 375px) {
  .banner .banner-elements-wrapper .baner-elements .czlowiek img {
    width: 120%;
  }
}
.banner .banner-elements-wrapper .baner-elements .dinozaur {
  grid-area: dinozaur;
  grid-column-start: 5;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 3;
  position: absolute;
  left: -8px;
  bottom: -15px;
  max-width: 508px;
  max-height: 268px;
}
@media (max-width: 768px) {
  .banner .banner-elements-wrapper .baner-elements .dinozaur {
    display: none;
  }
}
.banner .banner-elements-wrapper .baner-elements .dinozaur img {
  width: 160%;
}
.banner .banner-elements-wrapper .baner-elements .kometa {
  grid-area: kometa;
  grid-column-start: 5;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 1;
  position: absolute;
  top: -45px;
  right: -22px;
  max-width: 209px;
  max-height: 263px;
}
@media (max-width: 1024px) {
  .banner .banner-elements-wrapper .baner-elements .kometa {
    top: -129px;
    right: -83px;
  }
}
@media (max-width: 1024px) {
  .banner .banner-elements-wrapper .baner-elements .kometa {
    top: -45px;
    right: -22px;
  }
}
@media (max-width: 600px) {
  .banner .banner-elements-wrapper .baner-elements .kometa {
    top: -87px;
    right: -32px;
  }
}
@media (max-width: 600px) {
  .banner .banner-elements-wrapper .baner-elements .kometa {
    top: -107px;
    right: -62px;
  }
}
.banner .banner-elements-wrapper .baner-elements .kometa img {
  width: 100%;
}
.banner .baner-elements {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 375px) {
  .banner .baner-elements {
    margin: 0 36px;
  }
}
.banner .baner-elements .banner-title {
  font-weight: 900;
  color: #3c3c3b;
  line-height: 1;
  padding: 0 0 24px 0;
  margin: 0;
  text-align: center;
  z-index: 1;
}
.banner .baner-elements .banner-title p {
  padding: 0;
  margin: 0;
}
.banner .baner-elements .banner-subtitle {
  font-weight: 400;
  text-align: center;
  line-height: 1;
  color: #3c3c3b;
  padding: 0 0 34px 0;
  z-index: 1;
}
.banner .baner-elements .banner-subtitle p {
  padding: 0;
  margin: 0;
  font-size: 22px;
}
.banner .baner-elements .button-width185 {
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.materials {
  width: 100%;
  min-height: 591px !important;
  overflow: hidden;
  background: #f57a5b;
  background: linear-gradient(270deg, #f57a5b 50%, #a2ccd8 50%);
  position: relative;
  border-bottom: 9px solid #fff;
}
@media (max-width: 768px) {
  .materials {
    padding: 0;
    background: transparent;
  }
}
.materials .materials-elements {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .materials .materials-elements {
    flex-flow: column;
  }
}
.materials .materials-elements .left-part {
  font-weight: 400;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 162px 0 182px 0;
  margin: 0;
  background-color: #a2ccd8;
  position: relative;
}
@media (max-width: 768px) {
  .materials .materials-elements .left-part {
    width: 100%;
    padding: 90px 3%;
  }
}
@media (max-width: 375px) {
  .materials .materials-elements .left-part {
    padding: 90px 36px;
  }
}
.materials .materials-elements .left-part .clouds {
  width: 263px;
  height: 145px;
  position: absolute;
  bottom: -8px;
  left: -86px;
  z-index: 10;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../dist/img/home/cloud.png");
}
@media (max-width: 768px) {
  .materials .materials-elements .left-part .clouds {
    bottom: -57px;
  }
}
@media (max-width: 768px) {
  .materials .materials-elements .left-part .clouds-display-mobile {
    display: none;
  }
}
.materials .materials-elements .left-part .materials-text {
  width: 54%;
}
@media (max-width: 768px) {
  .materials .materials-elements .left-part .materials-text {
    width: 100%;
    margin: 0 3%;
  }
}
@media (max-width: 550px) {
  .materials .materials-elements .left-part .materials-text {
    font-weight: 100;
  }
}
.materials .materials-elements .left-part .materials-text p {
  color: #fff;
}
@media (max-width: 768px) {
  .materials .materials-elements .left-part .materials-text p {
    width: 100%;
  }
}
.materials .materials-elements .left-part .materials-text p strong {
  color: #fff;
  font-weight: 700;
}
@media (max-width: 550px) {
  .materials .materials-elements .left-part .materials-text p strong {
    font-weight: 500;
  }
}
.materials .materials-elements .right-part {
  font-weight: 400;
  background-color: #f57a5b;
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 50%;
  padding: 0;
  position: relative;
}
@media (max-width: 768px) {
  .materials .materials-elements .right-part {
    width: 100%;
    padding: 50px 27px;
  }
}
@media (max-width: 375px) {
  .materials .materials-elements .right-part {
    padding: 100px 36px 100px 36px;
  }
}
.materials .materials-elements .right-part .flashcards {
  height: 100%;
  position: absolute;
  left: -60px;
  top: -10px;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
}
@media (max-width: 768px) {
  .materials .materials-elements .right-part .flashcards {
    justify-content: center;
    position: static;
    height: 100%;
    padding: 80px 0 100px 0;
  }
}
.materials .materials-elements .right-part .flashcards .flashcards-math {
  transform: translate(0, -50%);
  width: 296px;
  height: 300px;
  background-color: transparent;
}
.materials .materials-elements .right-part .flashcards .flashcards-math:hover {
  z-index: 10000 !important;
}
.materials .materials-elements .right-part .flashcards .flashcards-math:hover a {
  transform: scale(1.1) !important;
}
@media (max-width: 768px) {
  .materials .materials-elements .right-part .flashcards .flashcards-math {
    padding: 180px 0;
  }
}
@media (max-width: 665px) {
  .materials .materials-elements .right-part .flashcards .flashcards-math {
    padding: 80px 0 0 0;
  }
}
@media (max-width: 375px) {
  .materials .materials-elements .right-part .flashcards .flashcards-math {
    width: 200px;
    height: 209px;
    margin: 0 150px 0 0;
  }
}
.materials .materials-elements .right-part .flashcards .flashcards-math a {
  position: relative;
  display: block;
  cursor: pointer;
  z-index: 0;
  background-color: transparent;
}
.materials .materials-elements .right-part .flashcards .flashcards-math a .flashcards-image1 {
  width: 100%;
  transform: translateY(0px);
  animation: float 6s ease-in-out infinite;
  background-color: transparent;
}
@media (max-width: 450px) {
  .materials .materials-elements .right-part .flashcards .flashcards-math a .flashcards-image1 {
    position: absolute;
    left: 20px;
  }
}
.materials .materials-elements .right-part .flashcards .flashcards-math .description-with-arrow-math {
  font-weight: 400;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -149px;
  left: 146px;
  width: 170px;
}
@media (max-width: 768px) {
  .materials .materials-elements .right-part .flashcards .flashcards-math .description-with-arrow-math {
    left: 86px;
    width: 50%;
    position: static;
    width: 100%;
    padding: 50px 0 0 0;
    background-color: #f57a5b;
  }
}
@media (max-width: 450px) and (min-width: 376px) {
  .materials .materials-elements .right-part .flashcards .flashcards-math .description-with-arrow-math {
    top: 300px;
    position: absolute;
    left: 1px;
  }
}
@media (max-width: 375px) {
  .materials .materials-elements .right-part .flashcards .flashcards-math .description-with-arrow-math {
    bottom: -176px;
    left: 49px;
    position: absolute;
    width: 125%;
    z-index: 0;
    padding: 0;
  }
}
.materials .materials-elements .right-part .flashcards .flashcards-math .description-with-arrow-math .text1 {
  width: 100%;
  text-align: center;
  background-color: #f57a5b;
}
.materials .materials-elements .right-part .flashcards .flashcards-math .description-with-arrow-math .text1 strong {
  font-weight: 700;
}
.materials .materials-elements .right-part .flashcards .flashcards-math .description-with-arrow-math .text1 .arrow-math {
  width: 48px;
  height: 33px;
  margin: 0 0 20px 0;
  background-color: #f57a5b;
}
.materials .materials-elements .right-part .flashcards .flashcards-math .description-with-arrow-math .text1 p {
  background-color: #f57a5b;
}
.materials .materials-elements .right-part .flashcards .flashcards-lang {
  position: absolute;
  top: -6px;
  left: 120px;
  transform: translate(0, -50%);
  width: 296px;
  height: 300px;
  z-index: 6;
  background-color: transparent;
}
.materials .materials-elements .right-part .flashcards .flashcards-lang:hover {
  z-index: 10000 !important;
}
.materials .materials-elements .right-part .flashcards .flashcards-lang:hover a {
  transform: scale(1.1) !important;
}
@media (max-width: 768px) {
  .materials .materials-elements .right-part .flashcards .flashcards-lang {
    position: static;
    padding: 180px 0;
  }
}
@media (max-width: 665px) {
  .materials .materials-elements .right-part .flashcards .flashcards-lang {
    padding: 80px 0 0 0;
  }
}
@media (max-width: 375px) {
  .materials .materials-elements .right-part .flashcards .flashcards-lang {
    position: absolute;
    width: 191px;
    height: 209px;
    top: 120px;
    left: 148px;
  }
}
.materials .materials-elements .right-part .flashcards .flashcards-lang a {
  position: relative;
  display: block;
  cursor: pointer;
  z-index: 0;
  background-color: transparent;
}
.materials .materials-elements .right-part .flashcards .flashcards-lang a .flashcards-image2 {
  width: 100%;
  animation: float2 6s ease-in-out infinite;
  background-color: transparent;
}
@media (max-width: 450px) {
  .materials .materials-elements .right-part .flashcards .flashcards-lang a .flashcards-image2 {
    position: absolute;
    left: -20px;
  }
}
.materials .materials-elements .right-part .flashcards .flashcards-lang .description-with-arrow-lang {
  font-weight: 400;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #f57a5b;
  position: absolute;
  bottom: -149px;
  left: 9px;
  width: 200px;
  z-index: -1;
}
@media (max-width: 768px) {
  .materials .materials-elements .right-part .flashcards .flashcards-lang .description-with-arrow-lang {
    position: static;
    padding: 50px 0 0 0;
    width: 100%;
  }
}
@media (max-width: 450px) and (min-width: 376px) {
  .materials .materials-elements .right-part .flashcards .flashcards-lang .description-with-arrow-lang {
    top: 300px;
    position: absolute;
  }
}
@media (max-width: 375px) {
  .materials .materials-elements .right-part .flashcards .flashcards-lang .description-with-arrow-lang {
    background-color: #f57a5b;
    position: absolute;
    padding: 25px 0 0 0;
    width: 100%;
    bottom: -237px;
    left: -62px;
  }
}
.materials .materials-elements .right-part .flashcards .flashcards-lang .description-with-arrow-lang .text2 {
  width: 100%;
  text-align: center;
  background-color: #f57a5b;
}
.materials .materials-elements .right-part .flashcards .flashcards-lang .description-with-arrow-lang .text2 strong {
  font-weight: 700;
}
.materials .materials-elements .right-part .flashcards .flashcards-lang .description-with-arrow-lang .text2 .arrow-lang {
  width: 48px;
  height: 33px;
  margin: 0 0 14px 0;
  background-color: #f57a5b;
}
.materials .materials-elements .right-part .flashcards .flashcards-lang .description-with-arrow-lang .text2 p {
  background-color: #f57a5b;
}
.ad {
  width: 100%;
  min-height: 590px;
  padding: 100px 0 134px 0;
  background-color: #f2f2f2;
  border-bottom: 8px solid #fff;
}
@media (max-width: 768px) {
  .ad {
    padding: 48px 0;
  }
}
.ad .ad-elements {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .ad .ad-elements {
    flex-flow: column;
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .ad .ad-elements {
    margin: 0 36px;
  }
}
.ad .ad-elements .left-part {
  font-weight: 700;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0;
}
@media (max-width: 768px) {
  .ad .ad-elements .left-part {
    width: 90%;
    margin: 0 0 65px 0;
  }
}
.ad .ad-elements .left-part img {
  width: 82%;
}
@media (max-width: 768px) {
  .ad .ad-elements .left-part img {
    width: 100%;
  }
}
@media (max-width: 375px) {
  .ad .ad-elements .left-part img {
    width: 120%;
  }
}
.ad .ad-elements .right-part {
  font-weight: 400;
  background-position: center;
  background-size: cover;
  width: 40%;
  margin: 0;
}
@media (max-width: 768px) {
  .ad .ad-elements .right-part {
    text-align: flex-start;
    width: 100%;
    padding: 0 3%;
  }
}
.ad .ad-elements .right-part .ad-text1 {
  font-weight: 700;
  width: 80%;
}
@media (max-width: 768px) {
  .ad .ad-elements .right-part .ad-text1 {
    text-align: flex-start;
    width: 100%;
  }
}
@media (max-width: 375px) {
  .ad .ad-elements .right-part .ad-text1 {
    font-size: 22px !important;
  }
}
.ad .ad-elements .right-part .ad-text2 {
  width: 80%;
  font-weight: 400;
  margin: 50px 0 70px 0;
}
@media (max-width: 768px) {
  .ad .ad-elements .right-part .ad-text2 {
    font-size: 20px;
    text-align: flex-start;
    width: 100%;
  }
}
@media (max-width: 550px) {
  .ad .ad-elements .right-part .ad-text2 {
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
@media (max-width: 375px) {
  .ad .ad-elements .right-part .ad-text2 {
    font-size: 20px !important;
    margin: 25px 0 40px 0;
  }
}
.singin-wrapper {
  min-height: 800px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #a3ced6;
  position: relative;
  border: none;
  outline: none;
  padding: 0 0 0 0;
  overflow: visible;
}
@media (max-width: 600px) {
  .singin-wrapper {
    height: auto;
    overflow: hidden;
  }
}
.singin-wrapper .login-cloud-blue2 {
  width: 567px;
  height: 312px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  right: 0px;
  bottom: -132px;
  z-index: 0;
  opacity: 0.1;
}
@media (max-width: 600px) {
  .singin-wrapper .login-cloud-blue2 {
    right: -263px;
    bottom: -157px;
  }
}
.singin-wrapper .signin__container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  width: 100%;
}
@media (max-width: 440px) {
  .singin-wrapper .signin__container {
    width: 100%;
  }
}
.singin-wrapper .signin__container .signin__text {
  font-weight: 900;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 40px 0;
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 0px 0 0 0;
}
@media (max-width: 812px) {
  .singin-wrapper .signin__container .signin__text {
    padding-top: 45px;
  }
}
@media (max-width: 440px) {
  .singin-wrapper .signin__container .signin__text {
    padding: 10px 0 30px 0;
  }
}
.singin-wrapper .signin__container .signin__text .h4-global {
  font-weight: 400;
}
.singin-wrapper .signin__container .signin__box {
  background-color: #f6f6f6;
  padding: 76px 70px 70px 70px;
  position: relative;
  margin: 0;
  min-width: 440px;
  max-width: 440px;
  height: 324;
}
@media (max-width: 440px) {
  .singin-wrapper .signin__container .signin__box {
    min-width: 340px;
    max-width: 340px !important;
    margin: 0 36px;
    padding: 36px 19px 56px 19px;
  }
}
.singin-wrapper .signin__container .signin__box .forgott-password-hader {
  text-align: center;
  margin: 0 0 37px 0;
}
.singin-wrapper .signin__container .signin__box .forgott-password-hader p {
  padding: 0;
  margin: 0;
}
.singin-wrapper .signin__container .signin__box .login-clouds {
  width: 317px;
  height: 175px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  left: -183px;
  bottom: -41px;
  z-index: 1;
}
@media (max-width: 440px) {
  .singin-wrapper .signin__container .signin__box .login-clouds {
    width: 253px;
    height: 139px;
    left: -79px;
    bottom: -90px;
    z-index: -1;
  }
}
.singin-wrapper .signin__container .signin__box .login-cloud-blue1 {
  width: 759px;
  height: 418px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  left: -373px;
  bottom: 179px;
  z-index: -1;
  opacity: 0.16;
}
@media (max-width: 440px) {
  .singin-wrapper .signin__container .signin__box .login-cloud-blue1 {
    left: -490px;
  }
}
.singin-wrapper .signin__container .signin__box .login-kiwi {
  width: 200px;
  height: 149px;
  background-image: url("../../dist/img/globalne/kiwi-right.png");
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
  left: -75px;
  bottom: -72px;
  z-index: 5;
}
@media (max-width: 375px) {
  .singin-wrapper .signin__container .signin__box .login-kiwi {
    width: 187px;
    height: 139px;
    left: 32px;
    bottom: -114px;
  }
}
.singin-wrapper .signin__container .signin__box form {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #f6f6f6;
  width: 300px;
  position: relative;
}
@media (max-width: 440px) {
  .singin-wrapper .signin__container .signin__box form {
    width: 100%;
  }
}
.singin-wrapper .signin__container .signin__box form label {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  margin: 0 0 15px 0;
  padding: 0;
  width: 100%;
}
.singin-wrapper .signin__container .signin__box form label .p6-global {
  color: #3c3c3b !important;
  padding: 0;
  margin: 0;
}
.singin-wrapper .signin__container .signin__box form label input[type='text'] {
  background-color: #808080;
  background-color: #fff;
  height: 41px;
  width: 100%;
  border-radius: 10px;
  margin: 7px 0 0 0;
  border: none;
  color: #3c3c3b;
  padding: 0 0 0 21px;
}
.singin-wrapper .signin__container .signin__box form label input[type='text']::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #c8c8c8;
  text-transform: lowercase;
}
@media (max-width: 440px) {
  .singin-wrapper .signin__container .signin__box form label input[type='text'] {
    width: 100%;
  }
}
.singin-wrapper .signin__container .signin__box form label input[type='password'] {
  border: 1px solid #f00;
  background-color: #808080;
  background-color: #fff;
  height: 41px;
  padding: 0 0 0 21px;
  width: 100%;
  border-radius: 10px;
  border: none;
  margin: 7px 0 37px 0;
  color: #3c3c3b;
}
.singin-wrapper .signin__container .signin__box form label input[type='password']::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #c8c8c8;
  text-transform: lowercase;
}
.singin-wrapper .signin__container .signin__box form .forgot-password {
  color: #f57a5b;
  margin: 0 0 16px 0;
}
.singin-wrapper .signin__container .signin__box form .button-width153 {
  border-radius: 50px;
  width: 153px;
  cursor: pointer;
  margin: 0;
}
.singin-wrapper .signin__container .signin__box form .additional-button {
  border-radius: 50px;
  width: 146px;
  cursor: pointer;
  margin: 0;
}
.singin-wrapper .signin__container .signin__box form .additional-button-new-password {
  border-radius: 50px;
  width: 225px;
  cursor: pointer;
  margin: 0;
}
.singin-wrapper .signin__container .signin__box form.enupal-stripe-form {
  background: none;
  position: absolute;
  top: 210px;
}
.singin-wrapper .signin__container .signin__box form.enupal-stripe-form .enupal-stripe-button {
  background: none;
  outline: none;
  box-shadow: none;
}
.singin-wrapper .signin__container .signin__box form.enupal-stripe-form .enupal-stripe-button span {
  font-size: 16px;
  color: #f57a5b;
  font-weight: 700;
  padding: 3px 35px 0px 35px;
  border-radius: 20px;
  background: #fff;
  border: 1px solid #f57a5b;
  outline: 0 !important;
  cursor: pointer;
  height: 37px;
  text-shadow: none;
}
.create-account-login {
  margin: 234px 0 166px 0;
  text-align: center;
  display: none;
}
@media (max-width: 600px) {
  .create-account-login {
    display: flex;
    margin: 234px 0 94px;
  }
}
.create-account-login a {
  font-size: 16px;
  width: 100%;
  font-weight: 700;
  color: #fff;
  text-decoration: underline;
}
.sign-box .errors {
  list-style-type: none;
  color: #f57a5b;
  margin: 15px 0 0 0;
}
.sign-box form {
  display: flex;
  flex-direction: column;
}
.sign-box form > h3 {
  margin-top: 1rem;
}
.sign-box form input[type=submit] {
  text-align: center;
}
.sign-box form > input {
  border: 1px solid rgba(128,128,128,0.3);
  padding: 0.5rem 2rem;
  margin: 0 1rem;
  border-radius: 3px;
}
.sign-box form > input:focus {
  border: 2px solid #afd2d6;
}
.sign-box form > input:not(:first-of-type) {
  margin-top: 1rem;
}
.sign-box form > input:last-of-type {
  cursor: pointer;
}
.profile-options {
  margin: 0 16px 113px 16px;
  position: relative;
  z-index: 50;
}
.profile-options .profile-wrapper {
  display: flex;
  flex-flow: column;
  max-width: 660px;
  margin: 0 auto;
}
.profile-options .profile-wrapper .changePasswordBtn {
  color: #f57a5b;
  display: inline-block;
  font-weight: bold;
  cursor: pointer;
}
.profile-options .profile-wrapper .avatar {
  margin: 0 0 36px 0;
}
.profile-options .profile-wrapper .avatar .photo {
  margin-left: 20px;
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  float: left;
}
.profile-options .profile-wrapper .avatar .photo input {
  position: absolute;
}
.profile-options .profile-wrapper .avatar .photo div {
  position: relative;
  font-weight: bold;
  color: #f57a5b;
  height: 100%;
  background-color: #fff;
  z-index: 100;
  cursor: pointer;
}
.profile-options .profile-wrapper .setting-header {
  font-size: 16px;
  font-weight: 900;
  margin: 75px 0 30px 0;
}
.profile-options .profile-wrapper .setting-avatar {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
  float: left;
  border: 1px solid #a2ccd8;
}
.profile-options .profile-wrapper .setting-avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.profile-options .profile-wrapper .setting-form {
  display: flex;
  flex-flow: column;
}
.profile-options .profile-wrapper .setting-form label {
  margin: 0 0 24px 0;
}
.profile-options .profile-wrapper .setting-form label p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: flience-grey;
}
.profile-options .profile-wrapper .setting-form label input[type=text],
.profile-options .profile-wrapper .setting-form label input[type=email] {
  width: 100%;
  height: 40px !important;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #f6f6f6;
  color: #c8c8c8;
  padding: 0 20px;
}
.profile-options .profile-wrapper .setting-form label input[type=text]:focus,
.profile-options .profile-wrapper .setting-form label input[type=email]:focus {
  border: 1px solid fleince-grey;
  color: #3c3c3b;
}
.profile-options .profile-wrapper .setting-form label input[type=password] {
  max-width: 524px;
  min-width: 524px;
  height: 40px !important;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #f6f6f6;
  color: #c8c8c8;
  padding: 0 20px;
}
@media (max-width: 558px) {
  .profile-options .profile-wrapper .setting-form label input[type=password] {
    max-width: 100%;
    min-width: 100%;
  }
}
.profile-options .profile-wrapper .setting-form label input[type=password]:focus {
  border: 1px solid fleince-grey;
  color: #3c3c3b;
}
.profile-options .profile-wrapper .setting-form label input::placeholder {
  font-size: 12px;
  text-align: center;
  font-family: 'GloberRegular';
}
.profile-options .profile-wrapper .setting-form input[type=submit] {
  margin: 24px 0 0 0;
}
.settings-box {
  background-color: rgba(0,0,0,0.2);
  position: fixed;
  z-index: 999999999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  display: none;
}
.settings-box button {
  cursor: pointer;
}
.settings-box .password {
  width: 300px;
  min-width: unset !important;
  background-color: #fff !important;
}
.settings-box .password-change-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
}
.settings-box .login-cloud-blue2 {
  width: 567px;
  height: 312px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  right: 0px;
  bottom: -132px;
  z-index: 0;
  opacity: 0.1;
}
@media (max-width: 600px) {
  .settings-box .login-cloud-blue2 {
    right: -263px;
    bottom: -157px;
  }
}
.settings-box .signin__container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 440px) {
  .settings-box .signin__container {
    width: 100%;
  }
}
.settings-box .signin__container .signin__text {
  font-weight: 900;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 40px 0;
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 60px 0 0 0;
}
@media (max-width: 440px) {
  .settings-box .signin__container .signin__text {
    padding: 0 0 30px 0;
  }
}
.settings-box .signin__container .signin__text .h4-global {
  font-weight: 400;
}
.settings-box .signin__container .signin__box {
  background-color: #f6f6f6;
  padding: 50px 70px 70px 70px;
  position: relative;
  margin: 0;
  min-width: 440px;
  max-width: 440px;
  height: 324;
  border-radius: 10px;
}
@media (max-width: 440px) {
  .settings-box .signin__container .signin__box {
    min-width: 340px;
    max-width: 340px !important;
    margin: 0 36px;
    padding: 36px 19px 56px 19px;
  }
}
.settings-box .signin__container .signin__box .forgott-password-hader {
  text-align: center;
  margin: 0 0 37px 0;
}
.settings-box .signin__container .signin__box .forgott-password-hader p {
  padding: 0;
  margin: 0;
}
.settings-box .signin__container .signin__box .login-clouds {
  width: 317px;
  height: 175px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  left: -183px;
  bottom: -41px;
  z-index: 1;
  bottom: -90px;
  z-index: -1;
}
@media (max-width: 440px) {
  .settings-box .signin__container .signin__box .login-clouds {
    width: 253px;
    height: 139px;
    left: -79px;
  }
}
.settings-box .signin__container .signin__box .login-cloud-blue1 {
  width: 759px;
  height: 418px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  left: -373px;
  bottom: 179px;
  z-index: -1;
  opacity: 0.16;
}
@media (max-width: 440px) {
  .settings-box .signin__container .signin__box .login-cloud-blue1 {
    left: -490px;
  }
}
.settings-box .signin__container .signin__box .login-kiwi {
  width: 200px;
  height: 149px;
  background-image: url("../../dist/img/globalne/kiwi-right.png");
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
  left: -75px;
  bottom: -86px;
  z-index: 5;
}
@media (max-width: 375px) {
  .settings-box .signin__container .signin__box .login-kiwi {
    width: 187px;
    height: 139px;
    left: 32px;
    bottom: -114px;
  }
}
.settings-box .signin__container .signin__box form {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: #f6f6f6;
  width: 300px;
  position: relative;
}
@media (max-width: 440px) {
  .settings-box .signin__container .signin__box form {
    width: 100%;
  }
}
.settings-box .signin__container .signin__box form label {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  margin: 0 0 15px 0;
  padding: 0;
  width: 100%;
}
.settings-box .signin__container .signin__box form label .p6-global {
  color: #3c3c3b !important;
  padding: 0;
  margin: 0;
}
.settings-box .signin__container .signin__box form label input[type='text'] {
  background-color: #808080;
  background-color: #fff;
  height: 41px;
  width: 100%;
  border-radius: 10px;
  margin: 7px 0 0 0;
  border: none;
  color: #3c3c3b;
  padding: 0 0 0 21px;
}
.settings-box .signin__container .signin__box form label input[type='text']::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #c8c8c8;
  text-transform: lowercase;
}
@media (max-width: 440px) {
  .settings-box .signin__container .signin__box form label input[type='text'] {
    width: 100%;
  }
}
.settings-box .signin__container .signin__box form label input[type='password'] {
  border: 1px solid #f00;
  background-color: #808080;
  background-color: #fff;
  height: 41px;
  padding: 0 0 0 21px;
  width: 100%;
  border-radius: 10px;
  border: none;
  margin: 7px 0 37px 0;
  color: #3c3c3b;
}
.settings-box .signin__container .signin__box form label input[type='password']::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #c8c8c8;
  text-transform: lowercase;
}
.settings-box .signin__container .signin__box form .forgot-password {
  color: #f57a5b;
  margin: 0 0 16px 0;
}
.settings-box .signin__container .signin__box form .button-width153 {
  border-radius: 50px;
  width: 116px;
  cursor: pointer;
  margin: 0;
}
.settings-box .signin__container .signin__box form .button-width170 {
  cursor: pointer;
}
.settings-box .signin__container .signin__box form .additional-button {
  border-radius: 50px;
  width: 146px;
  cursor: pointer;
  margin: 0;
}
.settings-box .signin__container .signin__box form .additional-button-new-password {
  border-radius: 50px;
  width: 225px;
  cursor: pointer;
  margin: 0;
}
.signup-wrapper {
  min-height: 900px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #a3ced6;
  position: relative;
}
@media (max-width: 768px) {
  .signup-wrapper {
    height: 100%;
    overflow-x: hidden;
  }
}
.signup-wrapper .signup__container {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  z-index: 3;
}
@media (max-width: 676px) {
  .signup-wrapper .signup__container {
    margin: 0 16px;
    width: 90%;
    max-width: 600px;
  }
}
.signup-wrapper .signup__container .sign-in-rwd {
  display: none;
  margin: 40px 0 25px 0;
  text-align: center;
  color: #fff;
  font-size: 16px;
  flex-flow: column;
  font-weight: 700;
}
.signup-wrapper .signup__container .sign-in-rwd p {
  padding: 0;
  margin: 0;
}
.signup-wrapper .signup__container .sign-in-rwd a {
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 600px) {
  .signup-wrapper .signup__container .sign-in-rwd {
    display: flex;
  }
}
.signup-wrapper .signup__container .signup__text {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 40px 0;
  height: 100%;
  font-weight: 900;
}
.signup-wrapper .signup__container .signup__text span {
  font-weight: 400;
}
.signup-wrapper .signup__container .wrapper {
  display: flex;
  flex-flow: row;
  justify-content: center;
  width: auto;
  position: relative;
}
@media (max-width: 1045px) {
  .signup-wrapper .signup__container .wrapper {
    flex-flow: column;
    flex-direction: column;
  }
}
@media (max-width: 676px) {
  .signup-wrapper .signup__container .wrapper {
    width: 100%;
  }
}
.signup-wrapper .signup__container .wrapper .login-clouds {
  width: 317px;
  height: 175px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  right: -90px;
  top: -120px;
  z-index: -1;
}
@media (max-width: 375px) {
  .signup-wrapper .signup__container .wrapper .login-clouds {
    right: -198px;
  }
}
@media (max-width: 600px) {
  .signup-wrapper .signup__container .wrapper .login-clouds {
    display: flex;
  }
}
.signup-wrapper .signup__container .wrapper .login-kiwi {
  width: 200px;
  height: 149px;
  background-image: url("../../dist/img/globalne/bird.svg");
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
  right: -86px;
  top: -86px;
}
@media (max-width: 600px) {
  .signup-wrapper .signup__container .wrapper .login-kiwi {
    display: flex;
  }
}
.signup-wrapper .signup__container .wrapper .signup__subscription {
  width: 100%;
  max-height: 791px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #f6f6f6;
  padding: 32px 60px 70px 60px;
  position: relative;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .step {
  display: none;
  cursor: pointer;
}
@media (max-width: 1045px) {
  .signup-wrapper .signup__container .wrapper .signup__subscription .step {
    margin-top: 50px;
    display: block;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 676px) {
  .signup-wrapper .signup__container .wrapper .signup__subscription .step {
    position: unset;
    transform: none;
  }
}
@media (max-width: 676px) {
  .signup-wrapper .signup__container .wrapper .signup__subscription {
    max-height: unset;
    padding: 32px 19px 50px 19px;
    align-items: center;
  }
}
.signup-wrapper .signup__container .wrapper .signup__subscription .button-wrapper {
  display: none;
  width: 100%;
  padding: 0;
  margin: 57px 0;
}
@media (max-width: 676px) {
  .signup-wrapper .signup__container .wrapper .signup__subscription .button-wrapper {
    display: flex;
  }
}
.signup-wrapper .signup__container .wrapper .signup__subscription .button-wrapper a {
  text-decoration: none;
  cursor: pointer;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .button-wrapper a .registery-mobile {
  border: 1px solid #f00;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .top-header {
  display: flex;
  flex-flow: row;
  margin: 0 0 38px 0;
}
@media (max-width: 676px) {
  .signup-wrapper .signup__container .wrapper .signup__subscription .top-header {
    margin: 0 0 38px 5%;
  }
}
.signup-wrapper .signup__container .wrapper .signup__subscription .top-header .circle-number {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #a3ced6;
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  color: #fff;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .top-header .title {
  margin: 0 0 0 13px;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .top-header .title strong {
  font-weight: 900;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media (max-width: 676px) {
  .signup-wrapper .signup__container .wrapper .signup__subscription .elements {
    flex-direction: column;
  }
}
@media (max-width: 676px) {
  .signup-wrapper .signup__container .wrapper .signup__subscription .elements {
    flex-flow: column;
  }
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month {
  height: 375px;
  width: 340px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 6px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 676px) {
  .signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month {
    width: 98%;
  }
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .header-1 {
  font-weight: 900;
  border-top: 0;
  line-height: 3;
  color: #9fd6c1;
  height: 70px;
  width: 100%;
  background-color: transparent;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 {
  background-color: #fff;
  width: 100%;
  margin: 0 0 5px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  justify-content: flex-start;
  height: 285px;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .options {
  border-radius: 10px;
  width: 100%;
  height: 278px;
  background-color: #fff;
  list-style-type: none;
  text-align: center;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .options p:nth-child(1) {
  width: 100%;
  padding: 26px 0 47px 0;
  margin: 0;
  position: relative;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .options p:nth-child(2) {
  padding: 26px 0;
  margin: 0;
  position: relative;
  border-bottom: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .options p:nth-child(3) {
  padding: 26px 0 0 0;
  margin: 0;
  height: 73px;
  position: relative;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .options p strong {
  font-weight: 900;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .free {
  width: 130px;
  height: 40px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 8px 0 0 0;
  bottom: -28px;
  color: #9fd6c1;
  position: absolute;
  background-color: #fff;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .free .svg-circle1 {
  width: 24px;
  height: 24px;
  border: 2px solid #9fd6c1;
  margin: 0 16px 0 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  color: #fff;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .free .svg-circle1 svg {
  opacity: 0;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .free .svg-circle1 img.svg {
  margin: 0 0 3px 0;
  width: 9px;
  height: 8px;
  fill: #fff !important;
  display: none;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .free .svg-circle1 img.svg,
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .first-month .box-1 .free .svg-circle1 img.svg * {
  fill: #fff !important;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .next-months {
  z-index: 2;
  border: 1px solid #f57a5b;
  background-color: #fff;
  height: 355px;
  width: 258px;
  border-radius: 10px;
  margin: 0 6px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  cursor: not-allowed;
  position: relative;
}
@media (max-width: 676px) {
  .signup-wrapper .signup__container .wrapper .signup__subscription .elements .next-months {
    margin: 50px 0 0 0;
    width: 98%;
  }
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .next-months .header-2 {
  border-top: 0;
  line-height: 3;
  font-size: 25px;
  font-weight: 900;
  color: #f57a5b;
  width: 80%;
  background-color: transparent;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .next-months .box-2 {
  background-color: rgba(255,255,255,0.95);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  z-index: 1000;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .next-months .box-2 ul {
  border-radius: 10px;
  width: 100%;
  background-color: rgba(255,255,255,0.95);
  list-style-type: none;
  text-align: center;
  padding: 90px 0 0 0;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .next-months .box-2 ul li {
  font-size: 25px;
  color: #f57a5b;
  padding: 27px 0 0 0;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .elements .next-months .box-2 ul li strong {
  font-weight: 900;
}
.signup-wrapper .signup__container .wrapper .signup__subscription .login-cloud-blue1 {
  width: 759px;
  height: 418px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  left: -85px;
  top: -136px;
  z-index: -1;
  opacity: 0.1;
}
.signup-wrapper .signup__container .wrapper .sign-box {
  background-color: #f6f6f6;
  padding: 32px 60px;
  position: relative;
  margin: 0 0 0 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1045px) {
  .signup-wrapper .signup__container .wrapper .sign-box {
    margin: 50px 0 0 0;
    display: none;
  }
}
@media (max-width: 600px) {
  .signup-wrapper .signup__container .wrapper .sign-box {
    padding: 32px 19px 70px 19px;
    display: none;
  }
}
.signup-wrapper .signup__container .wrapper .sign-box .top-header {
  display: flex;
  flex-flow: row;
  margin: 0 0 38px 0;
}
.signup-wrapper .signup__container .wrapper .sign-box .top-header .circle-number {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #a3ced6;
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  color: #fff;
}
.signup-wrapper .signup__container .wrapper .sign-box .top-header .title {
  margin: 0 0 0 13px;
}
.signup-wrapper .signup__container .wrapper .sign-box .top-header .title strong {
  font-weight: 900;
}
.signup-wrapper .signup__container .wrapper .sign-box .login-clouds {
  width: 317px;
  height: 175px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  right: -90px;
  top: -120px;
  z-index: -1;
}
@media (max-width: 375px) {
  .signup-wrapper .signup__container .wrapper .sign-box .login-clouds {
    right: -198px;
  }
}
.signup-wrapper .signup__container .wrapper .sign-box .login-kiwi {
  width: 200px;
  height: 149px;
  background-image: url("../../dist/img/globalne/bird.svg");
  background-size: contain;
  position: absolute;
  background-repeat: no-repeat;
  right: -86px;
  top: -86px;
}
.signup-wrapper .signup__container .wrapper .sign-box form {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  background-color: #f6f6f6;
  width: 340px;
}
@media (max-width: 1045px) {
  .signup-wrapper .signup__container .wrapper .sign-box form {
    width: 100%;
  }
}
.signup-wrapper .signup__container .wrapper .sign-box form .password-box {
  display: flex;
  flex-flow: row;
  height: auto;
}
.signup-wrapper .signup__container .wrapper .sign-box form .password-box :nth-child(1) {
  margin: 0 10px 0 0;
}
.signup-wrapper .signup__container .wrapper .sign-box form .password-box :nth-child(2) {
  margin: 3px 0 0 0;
}
.signup-wrapper .signup__container .wrapper .sign-box form .password-box input[type="password"] {
  background-color: #808080;
  background-color: #fff;
  height: 41px;
  padding: 0 0 0 21px;
  width: 100%;
  border-radius: 10px;
  border: none;
  color: #3c3c3b;
}
.signup-wrapper .signup__container .wrapper .sign-box form .password-box input[type="password"]::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #c8c8c8;
  text-transform: lowercase;
}
.signup-wrapper .signup__container .wrapper .sign-box form label {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: column;
  margin: 0 0 15px 0;
  padding: 0;
  width: 100%;
}
.signup-wrapper .signup__container .wrapper .sign-box form label input[type="text"],
.signup-wrapper .signup__container .wrapper .sign-box form label input[type="email"] {
  background-color: #808080;
  background-color: #fff;
  height: 41px;
  width: 100%;
  border-radius: 10px;
  margin: 7px 0 0 0;
  border: none;
  color: #3c3c3b;
  padding: 0 0 0 21px;
}
.signup-wrapper .signup__container .wrapper .sign-box form label input[type="text"]::placeholder,
.signup-wrapper .signup__container .wrapper .sign-box form label input[type="email"]::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #c8c8c8;
  text-transform: lowercase;
}
.signup-wrapper .signup__container .wrapper .sign-box form .button-width153 {
  border-radius: 50px;
  width: 204px;
  cursor: pointer;
}
.signup-wrapper .signup__container .wrapper .sign-box .policy {
  margin: 26px 0 0 0;
}
.signup-wrapper .signup__container .wrapper .sign-box .policy .option {
  display: flex;
  position: relative;
}
.signup-wrapper .signup__container .wrapper .sign-box .policy .option input[type=checkbox] {
  opacity: 1;
  cursor: pointer;
  position: absolute;
  top: 2.5px;
  left: 0px;
}
.signup-wrapper .signup__container .wrapper .sign-box .policy .option .checkbox-opton {
  margin: 0 0 0 35px;
}
.signup-wrapper .signup__container .wrapper .sign-box .policy .option .checkbox-opton a {
  color: #a2ccd8;
}
.option {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.option input:checked ~ .checkmark:after {
  display: block;
}
.option .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #a2ccd8;
}
.per-month {
  width: 168px;
  height: 40px;
  border-radius: 50px;
  font-size: 16px;
  position: relative;
  z-index: 10;
  border: 1px solid #f57a5b;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 8px 0 0 0;
  position: absolute;
  bottom: -28px;
  color: #f57a5b;
  background-color: #fff;
}
.per-month.soon {
  width: 168px;
  height: 40px;
  border-radius: 50px;
  font-size: 16px;
  z-index: 100;
  border: 1px solid #f57a5b;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  padding: 8px 0 0;
  position: absolute;
  bottom: -28px;
  color: #f57a5b;
  background-color: rgba(255,255,255,0.95);
}
.per-month .svg-circle {
  width: 24px;
  height: 24px;
  border: 2px solid #f57a5b;
  margin: 0 16px 0 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.per-month .svg-circle img.svg {
  margin: 0 0 3px 0;
  width: 9px;
  height: 8px;
  fill: #fff !important;
  display: none;
}
.per-month .svg-circle img.svg,
.per-month .svg-circle img.svg * {
  fill: #f57a5b !important;
}
.rejestration2 {
  background-color: #f6f6f6;
  padding: 32px 60px;
  position: relative;
  margin: 0 0 0 10px;
  display: flex !important;
  flex-flow: column;
}
@media (max-width: 1045px) {
  .rejestration2 {
    margin: 50px 0 0 0;
  }
}
@media (max-width: 1045px) {
  .img-form-rwd {
    display: none;
  }
}
.img-form-mb {
  display: none;
}
@media (max-width: 1045px) {
  .img-form-mb {
    display: block !important;
  }
}
.selected-first-month {
  background-color: #9fd6c1 !important;
  box-shadow: 0px 12px 0px 0px #cecece !important;
  border: 5px solid #9fd6c1 !important;
}
.selected-first-month .header-1 {
  color: #fff !important;
}
.selected-first-month .free {
  background-color: #9fd6c1 !important;
  color: #fff !important;
}
.selected-first-month .tick1 {
  display: flex !important;
}
.selected-first-month .svg-circle1 {
  background-color: #fff !important;
}
.selected-first-month .svg-circle1 svg {
  opacity: 1 !important;
}
.selected-next-months .header-2 {
  color: #fff !important;
}
.selected-next-months .per-month {
  background-color: #f57a5b !important;
  color: #fff;
}
.selected-next-months .svg-circle {
  background-color: #fff !important;
}
.selected-next-months .tick2 {
  display: flex !important;
}
.password-error {
  display: none;
  color: #f57a5b;
  margin-bottom: -20px;
  margin-top: 10px;
  width: 100%;
}
.signUp-complete {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #a3ced6;
  position: relative;
}
@media (max-width: 768px) {
  .signUp-complete {
    height: 100%;
    overflow-x: hidden;
  }
}
.signUp-complete .complete-cloud-blue1 {
  width: 759px;
  height: 418px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  opacity: 0.16;
}
@media (max-width: 440px) {
  .signUp-complete .complete-cloud-blue1 {
    left: -417px;
  }
}
.signUp-complete .complete-cloud-blue2 {
  width: 759px;
  height: 418px;
  background-image: url("../../dist/img/home/cloud.png");
  background-size: contain;
  position: absolute;
  right: -373px;
  bottom: -250px;
  z-index: 1;
  opacity: 0.16;
}
@media (max-width: 440px) {
  .signUp-complete .complete-cloud-blue2 {
    left: -28px;
  }
}
.signUp-complete .signUp-complete-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  z-index: 3;
  height: 100vh;
}
@media (max-width: 648px) {
  .signUp-complete .signUp-complete-wrapper {
    margin: 0 16px;
  }
}
.signUp-complete .signUp-complete-wrapper .wrapper-for-kiwi {
  width: 257px;
  height: 178px;
  margin: 37px 0 95px 0;
}
.signUp-complete .signUp-complete-wrapper .wrapper-for-kiwi img {
  width: 100%;
  height: 100%;
}
.signUp-complete .signUp-complete-wrapper .complete-header {
  font-weight: 900;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 40px 0;
  position: relative;
  z-index: 1;
  text-align: center;
}
.signUp-complete .signUp-complete-wrapper .h4-global {
  font-weight: 400;
}
.signUp-complete .signUp-complete-wrapper .button-width170 {
  width: 116px;
  margin: 33px 0 0 0;
}
.signup-wrapper .signup__container .wrapper .sign-box.signup__box {
  align-items: flex-start;
}
.polityka-prywatnosci {
  width: 100%;
}
@media (max-width: 768px) {
  .polityka-prywatnosci {
    padding: 0;
    background: transparent;
  }
}
.polityka-prywatnosci .policy-wrapper {
  max-width: 900px;
  margin: 0 auto;
  padding: 138px 0 200px 0;
  font-family: 'Lato';
  font-size: 18px;
  font-weight: normal;
}
@media (max-width: 768px) {
  .polityka-prywatnosci .policy-wrapper {
    flex-flow: column;
  }
}
.polityka-prywatnosci .policy-wrapper .naglowek {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: bold;
}
.polityka-prywatnosci .policy-wrapper .naglowek-2 {
  font-family: 'Lato';
  font-size: 20px;
  font-weight: normal;
}
.polityka-prywatnosci .policy-wrapper table {
  width: 100%;
}
.polityka-prywatnosci .policy-wrapper table th {
  padding: 15px;
}
.polityka-prywatnosci .policy-wrapper table th,
.polityka-prywatnosci .policy-wrapper table td {
  width: 33%;
}
.polityka-prywatnosci .policy-wrapper table thead {
  margin-bottom: 10px;
  background-color: #f6f6f6;
  text-align: center;
  height: 50px;
  font-size: 15px;
}
.polityka-prywatnosci .policy-wrapper table tbody td {
  border: 1px solid black;
  padding: 20px 10px;
}
.download {
  width: 0;
  height: 0;
  visibility: hidden;
  display: flex;
  position: absolute;
}
.popup {
  width: 100%;
  height: 100vh;
  background-color: rgba(60,60,59,0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
}
.popup .popup__wrapper {
  background-color: #f2f2f2;
  width: 551px;
  height: 781px;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  position: relative;
  border-radius: 20px;
  font-family: 'Lato', sans-serif !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.popup .popup__wrapper .popup__content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}
.popup .popup__wrapper .popup__content .popup__left {
  width: 100%;
  height: 57%;
  display: flex;
  flex-flow: column;
  margin: -10px 0 0 0;
}
.popup .popup__wrapper .popup__content .popup__left .popup__info {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin: -3px 0 60px 0;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
.popup .popup__wrapper .popup__content .popup__left .popup__info .popup__info-icon2 {
  margin-left: 10px;
}
.popup .popup__wrapper .popup__content .popup__left .popup__info img {
  height: 100%;
  margin-left: 6px;
}
.popup .popup__wrapper .popup__content .popup__left .popup__info .popup__info-level {
  width: auto;
  padding: 5px 10px;
  text-transform: uppercase;
  height: 24px;
  border-radius: 50px;
  background-color: #3c3c3b;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
}
.popup .popup__wrapper .popup__content .popup__left .popup__info .popup__info-category {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 114px;
  height: 24px;
  border-radius: 50px;
  background-color: #3c3c3b;
  border: 2px solid #fff;
  padding: 3px 20px 3px 24px;
  margin-right: 2%;
}
.popup .popup__wrapper .popup__content .popup__left .popup__info .popup__info-index {
  width: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 50px;
  background-color: #3c3c3b;
  border: 2px solid #fff;
}
.popup .popup__wrapper .popup__content .popup__left .popup__info .popup__info-number {
  width: 60px;
  text-transform: uppercase;
  height: 24px;
  border-radius: 50px;
  background-color: #3c3c3b;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection {
  padding: 0 60px;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection-dow--btn {
  cursor: pointer;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .language-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 16px;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .language-header strong {
  font-weight: 900;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .language-header .circle-number {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #a3ced6;
  font-size: 14px;
  margin: 0 9px 0 0;
  font-weight: 900;
  text-align: center;
  color: #fff;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .lang {
  list-style-type: none;
  display: flex;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .lang .lang__item {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: black;
  border-radius: 50%;
  cursor: pointer;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .lang .lang__item.lang__item--active {
  background-color: #f57a5b;
  color: #fff;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .lang .lang__item:not(:first-of-type) {
  margin-left: 15px;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-lang {
  text-align: center;
  color: #3c3c3b;
  font-size: 14px;
  font-weight: 900;
  margin: 17px 0 17px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-lang.activeMathCircle {
  background-color: unset;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-res {
  position: relative;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-res .screenflies-select {
  margin: 15px 0 36px 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 368px;
  height: 40px;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #3c3c3b;
  text-transform: lowercase;
  cursor: pointer;
  background-color: #fff;
  position: relative;
  z-index: 10 !important;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-res .screenflies-select .chosen-res {
  margin-bottom: 0;
  margin-left: 20px;
}
@media (max-width: 812px) {
  .popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-res .screenflies-select {
    display: none;
  }
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-res .screenflies-select::after {
  content: '';
  position: absolute;
  right: 30px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid #3c3c3b;
  color: #3c3c3b;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-res .screenflies-select .screenflies-select-list {
  overflow-y: auto;
  list-style-type: none;
  background-color: #fff;
  list-style-type: none;
  position: absolute;
  z-index: 100;
  top: 41px;
  width: 368px;
  padding: 10px 33px 0 33px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  opacity: 0;
  max-height: 0;
  box-shadow: 0px 12px 0px 0px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-res .screenflies-select .screenflies-select-list .header__option {
  padding: 5px 0;
  display: flex;
  align-items: center;
  min-height: 40px;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-res .screenflies-select .screenflies-select-list .header__option .select-item {
  font-size: 16px;
  text-decoration: none;
  color: #3c3c3b;
  padding: 5px 5px 5px 0;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-res .screenflies-select .screenflies-select-list .header__option .select-item:hover {
  font-weight: 700;
}
.popup .popup__wrapper .popup__content .popup__left .popup__selection .popup__selection-res .screenflies-select .screenflies-select-list.visibleMenu {
  opacity: 1;
  max-height: 200px;
}
.popup .popup__wrapper .popup__content .popup__right {
  width: 100%;
  height: 487px;
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}
header a {
  font-size: 1.5rem;
  text-decoration: none;
}
header a:hover {
  color: #808080;
}
.header {
  border-top: 0.5rem solid black;
  background-color: #f2f2f2;
  position: relative;
  z-index: 1;
}
.header__select {
  height: 2rem;
}
.header__view-item {
  text-decoration: none;
  font-size: 2rem;
}
.header__view-item:hover {
  color: #808080;
}
.header__download-box {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__download--count {
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  border-radius: 50%;
  background-color: #f00;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header__sign-out {
  display: flex;
  flex-direction: column;
  text-align: right;
}
@media screen and (max-width: 1000px) {
  .header__sign-out {
    margin-left: 5rem;
  }
}
.header__sign {
  display: flex;
  flex-direction: column;
}
.header__sign > p {
  text-decoration: none;
  color: rgba(128,128,128,0.3);
  font-size: 2rem;
  font-weight: bold;
  transition: 0.3s all;
  cursor: pointer;
}
.header__sign > p:hover {
  color: #808080;
}
.header__sign > p:first-of-type {
  margin-bottom: 2rem;
}
@media screen and (max-width: 700px) {
  .header__sign > p:first-of-type {
    margin-bottom: 0;
    margin-right: 2rem;
  }
}
.header__box {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  max-width: 150rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  justify-items: center;
}
.header__flex-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 4;
}
.header__rates-box {
  display: flex;
}
.header__logo {
  display: flex;
  align-self: center;
  align-items: center;
  font-size: 3rem;
  flex-grow: 2;
  justify-content: center;
}
@media screen and (max-width: 1000px) {
  .header__logo {
    align-self: flex-start;
  }
}
.header__logo img {
  width: 18rem;
}
.rate {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
}
.rate__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
  cursor: pointer;
  height: 5rem;
  transition: 0.5s all;
}
.rate__wrapper:hover {
  opacity: 1;
}
.rate-2 {
  border-left: 1px dashed rgba(128,128,128,0.3);
  border-right: 1px dashed rgba(128,128,128,0.3);
}
.icons {
  display: grid;
  max-width: 50%;
  max-height: 20rem;
  grid-template-rows: repeat(2, 5rem);
  grid-auto-flow: column;
}
@media screen and (max-width: 1330px) {
  .icons {
    grid-template-rows: repeat(3, 5rem);
  }
}
@media screen and (max-width: 1000px) {
  .icons {
    grid-template-rows: repeat(4, 5rem);
  }
}
@media screen and (max-width: 1000px) {
  .icons {
    max-width: none;
    width: auto;
    margin-left: 5rem;
    grid-template-rows: repeat(4, 5rem);
  }
}
@media screen and (max-width: 700px) {
  .icons {
    margin-left: 0;
    margin-top: 1rem;
  }
}
.icons__icon {
  height: 5rem;
  opacity: 0.3;
  cursor: pointer;
  transition: 0.2s all;
}
.icons__icon:hover {
  opacity: 1;
}
.icons__icon-box {
  height: 5rem;
  border: 1px dashed rgba(128,128,128,0.3);
  border-left-width: 0;
  border-top-width: 0;
  visibility: hidden;
}
.hello-user {
  font-size: 1.5rem;
  color: black;
}
@media screen and (max-width: 700px) {
  .hello-user {
    margin-right: 1rem;
  }
}
.main {
  margin: 100px 0 50px 0;
  width: 100%;
  min-height: 591px !important;
  z-index: 999999 !important;
  text-align: center;
}
@media (max-width: 1440px) {
  .main {
    padding: 0 27px;
  }
}
@media (max-width: 375px) {
  .main {
    padding: 0;
    margin: 30px 0;
  }
}
.main .main__img-box,
.main .main__img-box-math,
.main .main__img-box-screenflies {
  max-width: 1440px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(425px, 425px));
  column-gap: 23px;
  row-gap: 23px;
  margin: 0 auto;
}
.main .main__img-box .main__no-search-results,
.main .main__img-box-math .main__no-search-results,
.main .main__img-box-screenflies .main__no-search-results {
  grid-column: 2;
}
@media (max-width: 950px) {
  .main .main__img-box,
  .main .main__img-box-math,
  .main .main__img-box-screenflies {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
}
.main .main__img-box .main__img-wrapper,
.main .main__img-box-math .main__img-wrapper,
.main .main__img-box-screenflies .main__img-wrapper,
.main .main__img-box .main__img-wrapper-math,
.main .main__img-box-math .main__img-wrapper-math,
.main .main__img-box-screenflies .main__img-wrapper-math,
.main .main__img-box .main__img-wrapper-screenflies,
.main .main__img-box-math .main__img-wrapper-screenflies,
.main .main__img-box-screenflies .main__img-wrapper-screenflies {
  background-color: #f6f6f6;
  position: relative;
  width: 425px;
  height: 425px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
@media (max-width: 480px) {
  .main .main__img-box .main__img-wrapper,
  .main .main__img-box-math .main__img-wrapper,
  .main .main__img-box-screenflies .main__img-wrapper,
  .main .main__img-box .main__img-wrapper-math,
  .main .main__img-box-math .main__img-wrapper-math,
  .main .main__img-box-screenflies .main__img-wrapper-math,
  .main .main__img-box .main__img-wrapper-screenflies,
  .main .main__img-box-math .main__img-wrapper-screenflies,
  .main .main__img-box-screenflies .main__img-wrapper-screenflies {
    width: 100%;
  }
}
@media (max-width: 375px) {
  .main .main__img-box .main__img-wrapper,
  .main .main__img-box-math .main__img-wrapper,
  .main .main__img-box-screenflies .main__img-wrapper,
  .main .main__img-box .main__img-wrapper-math,
  .main .main__img-box-math .main__img-wrapper-math,
  .main .main__img-box-screenflies .main__img-wrapper-math,
  .main .main__img-box .main__img-wrapper-screenflies,
  .main .main__img-box-math .main__img-wrapper-screenflies,
  .main .main__img-box-screenflies .main__img-wrapper-screenflies {
    height: 375px;
  }
}
.main .main__img-box .main__img-wrapper img,
.main .main__img-box-math .main__img-wrapper img,
.main .main__img-box-screenflies .main__img-wrapper img,
.main .main__img-box .main__img-wrapper-math img,
.main .main__img-box-math .main__img-wrapper-math img,
.main .main__img-box-screenflies .main__img-wrapper-math img,
.main .main__img-box .main__img-wrapper-screenflies img,
.main .main__img-box-math .main__img-wrapper-screenflies img,
.main .main__img-box-screenflies .main__img-wrapper-screenflies img {
  width: 425px;
  height: 301px;
  transition: 0.2s all;
}
@media (max-width: 480px) {
  .main .main__img-box .main__img-wrapper img,
  .main .main__img-box-math .main__img-wrapper img,
  .main .main__img-box-screenflies .main__img-wrapper img,
  .main .main__img-box .main__img-wrapper-math img,
  .main .main__img-box-math .main__img-wrapper-math img,
  .main .main__img-box-screenflies .main__img-wrapper-math img,
  .main .main__img-box .main__img-wrapper-screenflies img,
  .main .main__img-box-math .main__img-wrapper-screenflies img,
  .main .main__img-box-screenflies .main__img-wrapper-screenflies img {
    width: 100%;
  }
}
.main .main__img-box .main__img-wrapper .main__img-langs,
.main .main__img-box-math .main__img-wrapper .main__img-langs,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs,
.main .main__img-box .main__img-wrapper-math .main__img-langs,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(60,60,59,0.7);
  padding: 71px 41px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  display: none;
}
.main .main__img-box .main__img-wrapper .main__img-langs img,
.main .main__img-box-math .main__img-wrapper .main__img-langs img,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs img,
.main .main__img-box .main__img-wrapper-math .main__img-langs img,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs img,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs img,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs img,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs img,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs img {
  display: none;
}
.main .main__img-box .main__img-wrapper .main__img-langs .langs__item,
.main .main__img-box-math .main__img-wrapper .main__img-langs .langs__item,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs .langs__item,
.main .main__img-box .main__img-wrapper-math .main__img-langs .langs__item,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs .langs__item,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs .langs__item,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs .langs__item,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs .langs__item,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs .langs__item {
  text-align: center;
  color: #3c3c3b;
  font-size: 14px;
  font-weight: 900;
  margin: 0 0 10px 0;
  cursor: pointer;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
}
.main .main__img-box .main__img-wrapper .main__img-langs .langs__item .translate-out,
.main .main__img-box-math .main__img-wrapper .main__img-langs .langs__item .translate-out,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs .langs__item .translate-out,
.main .main__img-box .main__img-wrapper-math .main__img-langs .langs__item .translate-out,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs .langs__item .translate-out,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs .langs__item .translate-out,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs .langs__item .translate-out,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs .langs__item .translate-out,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs .langs__item .translate-out {
  font-size: 14px;
  font-weight: 900;
  color: #fff;
  position: absolute;
  left: 50px;
  display: none;
  top: 50%;
  transform: translateY(-50%);
}
.main .main__img-box .main__img-wrapper .main__img-langs .langs__item:hover .translate-out,
.main .main__img-box-math .main__img-wrapper .main__img-langs .langs__item:hover .translate-out,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs .langs__item:hover .translate-out,
.main .main__img-box .main__img-wrapper-math .main__img-langs .langs__item:hover .translate-out,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs .langs__item:hover .translate-out,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs .langs__item:hover .translate-out,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs .langs__item:hover .translate-out,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs .langs__item:hover .translate-out,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs .langs__item:hover .translate-out {
  display: flex;
  white-space: nowrap;
}
.main .main__img-box .main__img-wrapper:hover .main__img-langs,
.main .main__img-box-math .main__img-wrapper:hover .main__img-langs,
.main .main__img-box-screenflies .main__img-wrapper:hover .main__img-langs,
.main .main__img-box .main__img-wrapper-math:hover .main__img-langs,
.main .main__img-box-math .main__img-wrapper-math:hover .main__img-langs,
.main .main__img-box-screenflies .main__img-wrapper-math:hover .main__img-langs,
.main .main__img-box .main__img-wrapper-screenflies:hover .main__img-langs,
.main .main__img-box-math .main__img-wrapper-screenflies:hover .main__img-langs,
.main .main__img-box-screenflies .main__img-wrapper-screenflies:hover .main__img-langs {
  display: flex;
}
@media (max-width: 768px) {
  .main .main__img-box .main__img-wrapper:hover .main__img-langs,
  .main .main__img-box-math .main__img-wrapper:hover .main__img-langs,
  .main .main__img-box-screenflies .main__img-wrapper:hover .main__img-langs,
  .main .main__img-box .main__img-wrapper-math:hover .main__img-langs,
  .main .main__img-box-math .main__img-wrapper-math:hover .main__img-langs,
  .main .main__img-box-screenflies .main__img-wrapper-math:hover .main__img-langs,
  .main .main__img-box .main__img-wrapper-screenflies:hover .main__img-langs,
  .main .main__img-box-math .main__img-wrapper-screenflies:hover .main__img-langs,
  .main .main__img-box-screenflies .main__img-wrapper-screenflies:hover .main__img-langs {
    display: none;
  }
}
.main .main__img-box .main__img-wrapper .main__img-langs,
.main .main__img-box-math .main__img-wrapper .main__img-langs,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs,
.main .main__img-box .main__img-wrapper-math .main__img-langs,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(60,60,59,0.7);
  padding: 71px 41px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  display: none;
}
.main .main__img-box .main__img-wrapper .main__img-langs .maths__item,
.main .main__img-box-math .main__img-wrapper .main__img-langs .maths__item,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs .maths__item,
.main .main__img-box .main__img-wrapper-math .main__img-langs .maths__item,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs .maths__item,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs .maths__item,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs .maths__item,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs .maths__item,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs .maths__item {
  text-align: center;
  color: #3c3c3b;
  font-size: 14px;
  font-weight: 900;
  margin: 0 0 10px 0;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
}
.main .main__img-box .main__img-wrapper .main__img-langs .maths__item:hover .translate-out,
.main .main__img-box-math .main__img-wrapper .main__img-langs .maths__item:hover .translate-out,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs .maths__item:hover .translate-out,
.main .main__img-box .main__img-wrapper-math .main__img-langs .maths__item:hover .translate-out,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs .maths__item:hover .translate-out,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs .maths__item:hover .translate-out,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs .maths__item:hover .translate-out,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs .maths__item:hover .translate-out,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs .maths__item:hover .translate-out {
  display: flex;
}
.main .main__img-box .main__img-wrapper .main__img-langs .maths__item .fa-check,
.main .main__img-box-math .main__img-wrapper .main__img-langs .maths__item .fa-check,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs .maths__item .fa-check,
.main .main__img-box .main__img-wrapper-math .main__img-langs .maths__item .fa-check,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs .maths__item .fa-check,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs .maths__item .fa-check,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs .maths__item .fa-check,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs .maths__item .fa-check,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs .maths__item .fa-check {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
.main .main__img-box .main__img-wrapper .main__img-langs .screenflies_download,
.main .main__img-box-math .main__img-wrapper .main__img-langs .screenflies_download,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs .screenflies_download,
.main .main__img-box .main__img-wrapper-math .main__img-langs .screenflies_download,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs .screenflies_download,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs .screenflies_download,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs .screenflies_download,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs .screenflies_download,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs .screenflies_download {
  display: flex;
  flex-flow: row;
  justify-content: center !important;
  align-items: center !important;
}
.main .main__img-box .main__img-wrapper .main__img-langs .screenflies_download button,
.main .main__img-box-math .main__img-wrapper .main__img-langs .screenflies_download button,
.main .main__img-box-screenflies .main__img-wrapper .main__img-langs .screenflies_download button,
.main .main__img-box .main__img-wrapper-math .main__img-langs .screenflies_download button,
.main .main__img-box-math .main__img-wrapper-math .main__img-langs .screenflies_download button,
.main .main__img-box-screenflies .main__img-wrapper-math .main__img-langs .screenflies_download button,
.main .main__img-box .main__img-wrapper-screenflies .main__img-langs .screenflies_download button,
.main .main__img-box-math .main__img-wrapper-screenflies .main__img-langs .screenflies_download button,
.main .main__img-box-screenflies .main__img-wrapper-screenflies .main__img-langs .screenflies_download button {
  cursor: pointer;
  width: 142px !important;
}
.button-wrapper-reload {
  display: flex;
  justify-content: center;
  grid-column: 2;
}
@media (max-width: 1374px) {
  .button-wrapper-reload {
    grid-column: 1/3;
  }
}
@media (max-width: 768px) {
  .button-wrapper-reload #more-button {
    right: 100px;
  }
}
.download-button {
  justify-content: center !important;
}
.math-show-fix {
  width: auto !important;
  height: 100% !important;
}
.social {
  position: absolute;
  z-index: 1;
  top: 21rem;
  left: 0;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1190px) {
  .social {
    top: 26rem;
  }
}
@media screen and (max-width: 650px) {
  .social {
    visibility: hidden;
  }
}
.social__icon {
  width: 2.8rem;
  transition: 0.2s opacity;
}
.social__icon:hover {
  opacity: 0.3;
}
.alert {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0.1rem 0.1rem 1rem black;
  padding: 3rem;
  z-index: 9999999 !important;
  border-radius: 3px;
  visibility: hidden;
  transition: 0.5s all;
}
.alert__text {
  text-align: center;
  font-size: 2rem;
}
.alert.popup-wrapper-limit .popup-cotent {
  padding: 40px;
}
.btn-orange {
  background-color: #f57a5b;
  color: #fff;
  font-weight: bold;
  border-radius: 50px;
  text-transform: uppercase;
  box-shadow: 0;
  padding: 1rem;
  border: none;
}
.pdf {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: row;
}
.settings-menu {
  margin: 0px auto;
  width: 660px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.settings-menu ul.settings-menu-list {
  display: flex;
  flex-direction: row;
  margin: 0px;
}
.settings-menu ul.settings-menu-list a {
  text-decoration: none;
}
.settings-menu ul.settings-menu-list li.item {
  padding: 5px 25px;
  list-style-type: none;
  color: #94afb4;
  position: relative;
  z-index: 0;
}
.settings-menu ul.settings-menu-list li.item.item-active {
  color: black;
}
.settings-menu ul.settings-menu-list li.item.item-active:before {
  content: '';
  position: absolute;
  z-index: -1;
  height: 47px;
  width: 85px;
  background-image: url("../../dist/img/cloud-menu.svg");
  top: -13px;
  left: 50%;
  transform: translateX(-50%);
}
section.billing-section {
  width: 100%;
  margin-bottom: 300px;
  margin-top: 70px;
}
section.billing-section .billing-wrapper {
  margin: 0px auto;
  width: 660px;
}
section.billing-section .billing-wrapper .title {
  font-size: 16px;
  color: #3c3c3b;
  font-weight: bold;
}
section.billing-section .billing-wrapper .billing-cards {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
section.billing-section .billing-wrapper .billing-cards .card {
  list-style-type: none;
  height: 355px;
  width: 321px;
  border-radius: 10px;
  border: 2px solid #f57a5b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  box-shadow: 0px 7px 0px 0px #d1cdcd;
}
section.billing-section .billing-wrapper .billing-cards .card.choosen {
  background: #9fd6c1;
  border-color: #9fd6c1;
}
section.billing-section .billing-wrapper .billing-cards .card.choosen .card-title {
  color: #fff;
}
section.billing-section .billing-wrapper .billing-cards .card.choosen button.btn-ok {
  background: #fff;
  color: #9fd6c1;
  border-color: #9fd6c1;
}
section.billing-section .billing-wrapper .billing-cards .card .card-title {
  font-size: 25px;
  color: #f57a5b;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 10px;
}
section.billing-section .billing-wrapper .billing-cards .card .card-inside {
  height: 277px;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section.billing-section .billing-wrapper .billing-cards .card .card-inside .field {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
section.billing-section .billing-wrapper .billing-cards .card .card-inside .field.field2 {
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  font-weight: bold;
}
section.billing-section .billing-wrapper .billing-cards .card .card-inside .field.field3 {
  font-weight: bold;
}
section.billing-section .billing-wrapper .billing-cards .card .btn-ok {
  position: absolute;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  padding: 7px 35px;
  border-radius: 20px;
  border: none;
  background: #f57a5b;
  border: 1px solid #f57a5b;
  bottom: -19px;
  outline: none !important;
  cursor: pointer;
}
@media (max-width: 680px) {
  section.billing-section .billing-wrapper {
    width: 100%;
  }
  section.billing-section .billing-wrapper .title {
    text-align: center;
  }
  section.billing-section .billing-wrapper .billing-cards {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  section.billing-section .billing-wrapper .billing-cards .card {
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
section.invoices-section {
  width: 100%;
  margin-bottom: 220px;
  margin-top: 70px;
  position: relative;
  z-index: 50;
}
section.invoices-section div.invoices-wrapper {
  margin: 0 auto;
  width: 660px;
}
@media (max-width: 800px) {
  section.invoices-section div.invoices-wrapper {
    width: 80%;
  }
}
section.invoices-section div.invoices-wrapper .title {
  font-size: 16px;
  color: #3c3c3b;
  font-weight: bold;
}
section.invoices-section div.invoices-wrapper .invoices-table {
  margin-top: 30px;
}
section.invoices-section div.invoices-wrapper .invoices-table ul {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
section.invoices-section div.invoices-wrapper .invoices-table ul .table-row {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}
section.invoices-section div.invoices-wrapper .invoices-table ul .table-row.table-headers {
  color: #f6f6f6;
  background-color: #3c3c3b;
}
section.invoices-section div.invoices-wrapper .invoices-table ul .table-row.table-row-content {
  border-bottom: 1px solid #f6f6f6;
}
section.invoices-section div.invoices-wrapper .invoices-table ul .table-row .field {
  padding: 15px 45px 13px 20px;
  width: 20%;
}
section.invoices-section div.invoices-wrapper .invoices-table ul .table-row .field.field2 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.notification-section {
  width: 100%;
  height: 100%;
  margin-bottom: 300px;
  margin-top: 70px;
  position: relative;
  z-index: 50;
}
.notification-section .notification-wrapper {
  margin: 0 auto;
  width: 660px;
}
@media (max-width: 950px) {
  .notification-section .notification-wrapper {
    width: 80%;
  }
}
.notification-section .notification-wrapper .title {
  font-size: 16px;
  color: #3c3c3b;
  font-weight: bold;
}
.notification-section .notification-wrapper .notification-checkbox {
  margin-top: 45px;
  margin-bottom: 45px;
}
.notification-section .notification-wrapper .notification-checkbox .container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 23px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.notification-section .notification-wrapper .notification-checkbox .container input {
  position: absolute;
  visibility: hidden;
  cursor: pointer;
  height: 0;
  width: 0;
}
.notification-section .notification-wrapper .notification-checkbox .container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 2px solid #f57a5b;
  background-color: #fff;
  border-radius: 50%;
}
.notification-section .notification-wrapper .notification-checkbox .container .checkmark:after {
  content: "\2714";
  position: absolute;
  display: none;
  left: 4px;
  color: #f57a5b;
}
.notification-section .notification-wrapper .notification-checkbox .container input[type="checkbox"]:checked + .checkmark:after {
  display: block;
}
.notification-section .notification-wrapper .newsletter-description {
  margin-top: 5px;
  margin-bottom: 28px;
  display: flex;
  flex: wrap;
}
.notification-section .notification-wrapper .newsletter-input input[type=text] {
  width: 490px;
  height: 55px !important;
  border-radius: 10px;
  border: 1px solid fleince-grey;
  color: #c8c8c8;
  margin-right: 12px;
  padding-left: 33px;
  margin-right: 5px;
}
@media (max-width: 800px) {
  .notification-section .notification-wrapper .newsletter-input input[type=text] {
    width: 100%;
  }
}
.notification-section .notification-wrapper .unsubscribe {
  display: none;
}
@media (max-width: 800px) {
  .button-width153 {
    margin-top: 10px;
  }
}
.premium-wrapper {
  min-height: 100%;
  padding-top: 40px;
}
.premium-wrapper .premium-container .header-logo {
  align-self: start;
  margin-left: 62px;
}
.premium-wrapper .premium-container .premium-content {
  margin-top: 45px;
  width: 100%;
}
.premium-wrapper .premium-container .premium-content .premium-text {
  color: #3c3c3b;
  margin-top: 0px;
}
.premium-wrapper .premium-container .premium-content .premium-box {
  min-width: 755px;
  background-color: #f6f6f6;
  padding: 76px 70px 70px;
  position: relative;
  margin: 0 auto;
  border-radius: 10px;
  padding: 32px 77px 140px;
}
@media (max-width: 800px) {
  .premium-wrapper .premium-container .premium-content .premium-box {
    min-width: 500px;
  }
}
@media (max-width: 600px) {
  .premium-wrapper .premium-container .premium-content .premium-box {
    min-width: 340px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.premium-wrapper .premium-container .premium-content .premium-box.premium2-box {
  min-width: 660px;
  padding: 32px 23px 140px 28px;
}
@media (max-width: 800px) {
  .premium-wrapper .premium-container .premium-content .premium-box.premium2-box {
    min-width: 500px;
  }
}
@media (max-width: 600px) {
  .premium-wrapper .premium-container .premium-content .premium-box.premium2-box {
    min-width: 340px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
.premium-wrapper .premium-container .premium-content .premium-box .top-header {
  margin-bottom: 21px;
  display: flex;
}
.premium-wrapper .premium-container .premium-content .premium-box .top-header .circle-number {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background-color: #a3ced6;
  font-size: 14px;
  font-weight: 900;
  text-align: center;
  color: #fff;
}
.premium-wrapper .premium-container .premium-content .premium-box .top-header .title {
  margin-left: 10px;
  font-weight: bold;
  color: #3c3c3b;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .premium-wrapper .premium-container .premium-content .premium-box .billing-cards {
    flex-direction: column;
    align-items: center;
    align-content: space-around;
  }
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card {
  list-style-type: none;
  width: 48%;
  border-radius: 10px;
  border: 2px solid #f57a5b;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 7px 0px 0px #d1cdcd;
}
@media (max-width: 800px) {
  .premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card {
    width: 70%;
  }
  .premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card:first-child {
    margin-bottom: 30px;
  }
}
@media (max-width: 600px) {
  .premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card {
    width: 100%;
  }
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card.choosen {
  background: #f57a5b;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card.choosen .card-title {
  color: #fff;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card.choosen button.btn-ok {
  background: #f57a5b;
  color: #f57a5b;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card.choosen button.btn-ok .checkbox {
  background-color: #fff;
  background-size: 70%;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card.choosen button.btn-ok .checkbox .svg {
  visibility: visible;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card .card-title {
  font-size: 25px;
  color: #f57a5b;
  font-weight: bold;
  margin-bottom: 0px;
  margin-top: 10px;
  padding: 10px 0;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card .card-inside {
  height: 160px;
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card .card-inside .field {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  font-weight: bold;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card .btn-ok {
  position: absolute;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  padding: 20px 30px;
  border-radius: 20px;
  border: none;
  background: #fff;
  border: none;
  bottom: -20px;
  outline: none !important;
  cursor: pointer;
  display: flex;
  left: 110px;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card .btn-ok .checkbox {
  background: #fff;
  position: absolute;
  z-index: 3;
  border: 1px solid #f57a5b;
  width: 24px;
  border-radius: 50%;
  top: 7px;
  left: 19px;
}
.premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card .btn-ok .checkbox .svg {
  visibility: hidden;
}
@media (max-width: 800px) {
  .premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card .btn-ok {
    left: 90px;
  }
}
@media (max-width: 600px) {
  .premium-wrapper .premium-container .premium-content .premium-box .billing-cards .card .btn-ok {
    left: 110px;
  }
}
.premium-wrapper .premium-container .premium-content .buttons-options {
  width: 300px;
  display: flex;
  align-content: center;
  flex-direction: column;
  margin: 50px auto 0 auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  bottom: 34px;
  transform: translateX(-50%);
  align-items: center;
}
@media (max-width: 480px) {
  .premium-wrapper .premium-container .premium-content .buttons-options {
    width: 100%;
  }
}
.premium-wrapper .premium-container .premium-content .buttons-options .buttons-group {
  display: flex;
  justify-content: center;
}
.premium-wrapper .premium-container .premium-content .buttons-options .buttons-group .button1 {
  width: 120px;
  color: #f57a5b;
  background: #f6f6f6;
  border: 1px solid #f57a5b;
  font-weight: bold;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.premium-wrapper .premium-container .premium-content .buttons-options .buttons-group .button2 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-weight: bold;
}
.premium-wrapper .premium-container .premium-content .buttons-options .button-width185 {
  font-weight: bold;
  cursor: pointer;
}
.premium-wrapper .premium-container .premium-content .buttons-options .close-modal {
  color: #f57a5b;
  margin-top: 14px;
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
}
.premium-wrapper .premium-container .premium-content .buttons-options .helper-container {
  margin: auto;
  margin-top: 10px;
}
.premium-wrapper .premium-container .premium-content .buttons-options .helper-container .svg {
  margin-right: 5px;
}
.premium-wrapper .premium-container .premium-content .buttons-option2 {
  bottom: 28px;
}
.premium-wrapper .premium-container .premium-content .premium2-form #billingAddressContainer-11707 {
  width: 100%;
}
.premium-wrapper .premium-container .premium-content .premium2-form #billingAddressContainer-11707 .form-group .heading input,
.premium-wrapper .premium-container .premium-content .premium2-form #billingAddressContainer-11707 .form-group .input input {
  width: 100%;
}
.premium-wrapper .premium-container .premium-content .premium2-form form {
  width: 100%;
  padding: 0px 30px;
}
.premium-wrapper .premium-container .premium-content .premium2-form form label {
  font-size: 14px;
}
.premium-wrapper .premium-container .premium-content .premium2-form form label ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.premium-wrapper .premium-container .premium-content .premium2-form form label ul li {
  display: flex;
  padding-right: 30px;
}
.premium-wrapper .premium-container .premium-content .premium2-form form label ul li .container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
}
.premium-wrapper .premium-container .premium-content .premium2-form form label ul li .container input {
  position: absolute;
  visibility: hidden;
  cursor: pointer;
  height: 0;
  width: 0;
}
.premium-wrapper .premium-container .premium-content .premium2-form form label ul li .container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border-radius: 50%;
}
.premium-wrapper .premium-container .premium-content .premium2-form form label ul li .container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #a2ccd8;
}
.premium-wrapper .premium-container .premium-content .premium2-form form label ul li .container input[type=radio]:checked + .checkmark:after {
  display: block;
}
.premium-wrapper .premium-container .premium-content .premium2-form form .person {
  width: 100%;
}
.premium-wrapper .premium-container .premium-content .premium2-form form .company {
  width: 100%;
  display: none;
}
.premium-wrapper .premium-container .premium-content .button-close {
  position: absolute;
  width: 32px;
  height: 32px;
  background: #fff;
  border-radius: 50%;
  top: -7px;
  right: -8px;
  border: none;
  box-shadow: 0px 3px 0px 0px #d1cdcd;
  cursor: pointer;
}
.premium-wrapper .premium-container .premium-content .option2 {
  width: 300px;
}
.premium-wrapper .premium-container .premium-content .premium3-container {
  width: 230px;
  margin: 110px auto;
}
.premium-wrapper .premium-container .premium-content .login-cloud-blue1 {
  left: -211px;
  bottom: 470px;
}
.premium-wrapper .premium-container .premium-content .cloud2 {
  bottom: 232px !important;
}
.premium-wrapper .premium-container .premium-content .cloud3 {
  left: -263px;
  top: -167px;
}
.premium-wrapper .premium-container .invoices-table {
  margin-top: 30px;
}
.premium-wrapper .premium-container .invoices-table ul {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.premium-wrapper .premium-container .invoices-table ul .table-row {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}
.premium-wrapper .premium-container .invoices-table ul .table-row.table-headers {
  color: #f6f6f6;
  background-color: #3c3c3b;
}
.premium-wrapper .premium-container .invoices-table ul .table-row.table-row-content {
  border-bottom: 1px solid #f6f6f6;
  background-color: #fff;
}
.premium-wrapper .premium-container .invoices-table ul .table-row .field {
  padding: 15px 45px 13px 20px;
  width: 33%;
}
.premium-wrapper .premium-container .invoices-table ul .table-row .field.field2 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.premium-wrapper .premium-container .invoices-table ul .table-row .field.field2.field3 {
  display: flex;
  justify-content: center;
}
.premium-wrapper .premium-container .invoices-table ul .table-row .field.field2.field3 .button-widthauto {
  width: 123px;
  height: 30px;
  padding: 0px;
}
.premium-wrapper .cloud-bottom {
  width: 886px;
  height: 365px;
  right: -375px;
  bottom: -114px;
}
.premium-wrapper-scroll {
  overflow-y: scroll;
  height: 100vh;
}
@media (max-width: 800px) {
  .premium-wrapper {
    overflow-y: scroll;
    height: 100vh;
  }
}
.billing-premium {
  margin-bottom: 300px;
}
.billing-premium .title {
  font-size: 16px;
  color: #3c3c3b;
  font-weight: 700;
}
.billing-premium .billing-section {
  margin-bottom: 0px;
}
.billing-premium .billing-section .billing-wrapper .billing-cards .card {
  border-color: #9fd6c1;
}
.billing-premium .billing-section .billing-wrapper .billing-cards .card.choosen {
  background: #f57a5b;
  border-color: #f57a5b;
}
.billing-premium .billing-section .billing-wrapper .billing-cards .card.choosen .btn-ok {
  color: #f57a5b;
  border-color: #f57a5b;
}
.billing-premium .billing-section .underline-section {
  border-bottom: 1px solid #969695;
  opacity: 0.5;
  width: 894px;
  margin: 60px auto 45px auto;
}
.billing-premium .payment-section .invoices-table {
  margin-top: 30px;
}
.billing-premium .payment-section .invoices-table ul {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.billing-premium .payment-section .invoices-table ul .table-row {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
}
.billing-premium .payment-section .invoices-table ul .table-row.table-headers {
  color: #f6f6f6;
  background-color: #3c3c3b;
}
.billing-premium .payment-section .invoices-table ul .table-row .field {
  padding: 15px 45px 13px 20px;
  width: 33%;
}
.billing-premium .payment-section .invoices-table ul .table-row .field.field2 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.billing-premium .payment-section .invoices-table ul .table-row .field.field2.field3 {
  display: flex;
  justify-content: center;
}
.billing-premium .payment-section .invoices-table ul .table-row .field.field2.field3 .button-widthauto {
  width: 123px;
  height: 30px;
  padding: 0px;
}
.billing-premium .payment-details .billing-wrapper form {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}
.billing-premium .payment-details .billing-wrapper form label {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
}
.billing-premium .payment-details .billing-wrapper form label span {
  padding-left: 10px;
}
.billing-premium .payment-details .billing-wrapper form label input {
  border-radius: 10px;
  padding: 10px 20px;
  background: #f6f6f6;
  border: none;
  color: #d8d8d8;
}
.billing-premium .payment-details .billing-wrapper form:last-child .last-input {
  display: flex;
  justify-content: space-between;
}
.billing-premium .payment-details .billing-wrapper form:last-child .last-input input {
  width: 80%;
}
.billing-premium .payment-details .billing-wrapper form:last-child a {
  cursor: pointer;
  width: 17%;
  color: #f57a5b;
  font-weight: bold;
  font-size: 14px;
  align-self: center;
}
.billing-premium .payment-details .billing-wrapper form button {
  background: #f57a5b;
  color: #fff;
  margin-top: 39px;
}
@media (max-width: 700px) {
  .billing-wrapper {
    width: 90% !important;
  }
}
