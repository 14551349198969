.limitAlert
  position fixed
  top 0
  left 0
  box-shadow 0.1rem 0.1rem 1rem black
  padding 3rem
  border-radius 3px
  visibility hidden
  opacity 0
  //visibility visible
  //opacity 1
  transition 0.5s all
  z-index 10000
  &-text
    text-align center
    font-size 2rem

  &.popup-wrapper-limit .popup-cotent
    padding 40px

.amountAlert
  position fixed
  top 50%
  left 50%
  transform translate(-50%, -50%)
  box-shadow 0.1rem 0.1rem 1rem black
  padding 3rem
  border-radius 3px
  visibility hidden
  opacity 0
  //visibility visible
  //opacity 1
  transition 0.5s all
  z-index 10000

  &.popup-wrapper-limit .popup-cotent
    padding 40px
  .amountAlert-counter
    font-size 42px
    line-height 42px
    color #a2ccd8
    font-weight bold

.popup-close-x
  position relative
  .button-close
    position: absolute
    width: 32px
    height: 32px
    background: white
    border-radius: 50%
    top: -7px
    right: -8px
    border: none
    box-shadow: 0px 3px 0px 0px rgba(209,205,205,1);
    cursor pointer
    z-index 100

