.banner
  min-height 695px
  width 100%
  padding 170px 0 0 0
  background-color flience-green
  background-position center
  background-size contain
  background-repeat no-repeat
  position relative
  overflow hidden
  border-bottom 6px solid white
  @media (max-width: 600px)
    min-height 733px
  .banner-elements-wrapper
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    .baner-elements
      max-width 1440px
      margin 0 auto
      height 100%
      display grid
      overflow 0
      position relative
      grid-template-columns 1fr 1fr 1fr 1fr 1fr
      grid-template-rows 1fr 1fr 1fr
      @media (max-width: 600px)
        overflow hidden
        grid-template-columns 1fr 1fr 1fr
        grid-template-rows 1fr 1fr 1fr 1fr
      .pantofelek-color
        grid-area pantofelek-color
        grid-column-start 1
        grid-column-end 1
        grid-row-start 1
        grid-row-end 1
        position absolute
        top 34px
        left -43px
        max-width 189px
        max-height 136px
        @media (max-width: 1024px)
          top 0
        img
          width 100%
      .pantofelek-bw
        grid-area pantofelek-bw
        grid-column-start 2
        grid-column-end 2
        grid-row-start 1
        grid-row-end 1
        position absolute
        top -183px
        left 0px
        z-index 0
        @media (max-width: 600px)
          grid-column-start 1
          grid-column-end 2
          grid-row-start 2
          grid-row-end 2
        @media (max-width: 375px)
          top -105px
          left -150px
        img
          max-width 933px
          max-height 676px
          @media (max-width: 375px)
            max-width 604px
            max-height 437px
      .slimak
        grid-area slimak
        grid-column-start 1
        grid-column-end 1
        grid-row-start 2
        grid-row-end 2
        position absolute
        top 0px
        left 106px
        max-width 231px
        max-height 105px
        @media (max-width: 1024px)
          left 0px
        @media (max-width: 600px)
          display none
        img
          width 100%
      .zaba
        grid-area zaba
        grid-column-start 1
        grid-column-end 1
        grid-row-start 3
        grid-row-end 3
        position absolute
        left -34px
        bottom 62px
        max-width 164px
        max-height 124px
        @media (max-width: 600px)
          left -34px
          grid-column-start 1
          grid-column-end 1
          grid-row-start 4
          grid-row-end 4
        @media (max-width: 375px)
          left -44px
        img
          width 100%
      .glupik
        grid-area glupik
        grid-column-start 2
        grid-column-end 2
        grid-row-start 3
        grid-row-end 3
        position absolute
        top -47px
        left -58px
        max-width 224px
        max-height 104px
        @media (max-width: 600px)
          grid-column-start 1
          grid-column-end 2
          grid-row-start 3
          grid-row-end 3
          left 50px
          top 0
        @media (max-width: 375px)
          left 12px
          top 48px
        img
          width 100%
          @media (max-width: 375px)
            width 150%
      .malpa
        grid-area malpa
        grid-column-start 3
        grid-column-end 3
        grid-row-start 3
        grid-row-end 3
        position absolute
        left -75px
        bottom -7px
        max-width 235px
        max-height 267px
        @media (max-width: 768px)
          left -75px
        @media (max-width: 600px)
          left -25px
          grid-column-start 2
          grid-column-end 2
          grid-row-start 4
          grid-row-end 4
        @media (max-width: 375px)
          left -15px
        img
          width 100%
          @media (max-width: 375px)
            width 120%
      .czlowiek
        grid-area czlowiek
        grid-column-start 4
        grid-column-end 4
        grid-row-start 2
        position absolute
        left -18px
        bottom -7px
        max-width 189px
        max-height 425px
        @media (max-width: 768px)
          grid-column-start 5
          grid-column-end 5
          grid-row-start 2
        @media (max-width: 600px)
          grid-column-start 3
          grid-column-end 3
        @media (max-width: 375px)
          position relative
          left 9px
          bottom -105px
          max-height 305px
        @media (max-width: 360px)
          bottom -120px
        img
          width 100%
          @media (max-width: 375px)
            width 120%
      .dinozaur
        grid-area dinozaur
        grid-column-start 5
        grid-column-end 5
        grid-row-start 3
        grid-row-end 3
        position absolute
        left -8px
        bottom -15px
        max-width 508px
        max-height 268px
        @media (max-width: 768px)
          display none
        img
          width 160%
      .kometa
        grid-area kometa
        grid-column-start 5
        grid-column-end 5
        grid-row-start 1
        grid-row-end 1
        position absolute
        top -45px
        right -22px
        max-width 209px
        max-height 263px
        @media (max-width: 1024px)
          top -129px
          right -83px
        @media (max-width: 1024px)
          top -45px
          right -22px
        @media (max-width: 600px)
          top -87px
          right -32px
        @media (max-width: 600px)
          top -107px
          right -62px
        img
          width 100%
  .baner-elements
    content-container()
    display flex
    flex-flow column
    justify-content center
    align-items center
    @media (max-width: 375px)
      margin 0 36px
    .banner-title
      black()
      color flience-dark-grey
      line-height 1
      padding 0 0 24px 0
      margin 0
      text-align center
      z-index 1
      p
        padding 0
        margin 0
    .banner-subtitle
      regular()
      text-align center
      line-height 1
      color flience-dark-grey
      padding 0 0 34px 0
      z-index 1
      p
        padding 0
        margin 0
        font-size 22px
    .button-width185
      cursor pointer
      position relative
      z-index 1
