.singin-wrapper
  min-height 800px
  width 100vw
  display flex
  justify-content center
  align-items flex-start
  background-color flience-login-blue
  position relative
  border none
  outline none
  padding 0 0 0 0
  overflow visible
  @media (max-width: 600px)
    height auto
    overflow hidden
  .login-cloud-blue2
    width 567px
    height 312px
    background-image url('../../dist/img/home/cloud.png')
    background-size contain
    position absolute
    right 0px
    bottom -132px
    z-index 0
    opacity 0.1
    @media (max-width: 600px)
      right -263px
      bottom -157px
  .signin__container
    display flex
    flex-flow column
    justify-content center
    align-items center
    z-index 3
    width 100%
    @media (max-width: 440px)
      width 100%
    .signin__text
      black()
      display flex
      flex-flow column
      justify-content center
      align-items center
      padding 0 0 40px 0
      position relative
      z-index 1
      text-align center
      margin 0px 0 0 0
      @media(max-width: 812px)
        padding-top: 45px
      @media (max-width: 440px)
        padding 10px 0 30px 0
      .h4-global
        regular()
    .signin__box
      background-color #F6F6F6
      padding 76px 70px 70px 70px
      position relative
      margin 0
      min-width 440px
      max-width 440px
      height 324
      @media (max-width: 440px)
        min-width 340px
        max-width 340px !important
        margin 0 36px
        padding 36px 19px 56px 19px
      .forgott-password-hader
        text-align center
        margin 0 0 37px 0
        p
          padding 0
          margin 0
      .login-clouds
        width 317px
        height 175px
        background-image url('../../dist/img/home/cloud.png')
        background-size contain
        position absolute
        left -183px
        bottom -41px
        z-index 1
        @media (max-width: 440px)
          width 253px
          height 139px
          left -79px
          bottom -90px
          z-index -1
      .login-cloud-blue1
        width 759px
        height 418px
        background-image url('../../dist/img/home/cloud.png')
        background-size contain
        position absolute
        left -373px
        bottom 179px
        z-index -1
        opacity 0.16
        @media (max-width: 440px)
          left -490px
      .login-kiwi
        width 200px
        height 149px
        background-image url('../../dist/img/globalne/kiwi-right.png')
        background-size contain
        position absolute
        background-repeat no-repeat
        left -75px
        bottom -72px
        z-index 5
        @media (max-width: 375px)
          width 187px
          height 139px
          left 32px
          bottom -114px
      form
        display flex
        flex-flow column
        justify-content center
        align-items center
        width 100%
        padding 0
        margin 0
        background-color flience-login-white
        width 300px
        position relative
        @media (max-width: 440px)
          width 100%
        label
          display flex
          justify-content center
          align-items flex-start
          flex-flow column
          margin 0 0 15px 0
          padding 0
          width 100%
          .p6-global
            color flience-dark-grey !important
            padding 0
            margin 0
          input[type='text']
            background-color grey
            background-color flience-white
            height 41px
            width 100%
            border-radius 10px
            margin 7px 0 0 0
            border none
            color flience-dark-grey
            padding 0 0 0 21px
            &::placeholder
              regular()
              font-size 14px
              color flience-placeholder-grey
              text-transform lowercase
            @media (max-width: 440px)
              width 100%
          input[type='password']
            border 1px solid red
            background-color grey
            background-color flience-white
            height 41px
            padding 0 0 0 21px
            width 100%
            border-radius 10px
            border none
            margin 7px 0 37px 0
            color flience-dark-grey
            &::placeholder
              regular()
              font-size 14px
              color flience-placeholder-grey
              text-transform lowercase
        .forgot-password
          color flience-orange
          margin 0 0 16px 0
        .button-width153
          border-radius 50px
          width 153px
          cursor pointer
          margin 0
        .additional-button
          border-radius 50px
          width 146px
          cursor pointer
          margin 0
        .additional-button-new-password
          border-radius 50px
          width 225px
          cursor pointer
          margin 0
        &.enupal-stripe-form
            background: none
            position: absolute
            top: 210px
            .enupal-stripe-button
              background: none
              outline: none
              box-shadow: none
              span
                font-size: 16px
                color: #f57a5b
                font-weight: 700
                padding: 3px 35px 0px 35px
                border-radius: 20px
                background: #fff
                border: 1px solid #f57a5b
                outline: 0!important
                cursor: pointer
                height: 37px
                text-shadow: none
	.create-account-login
      margin 234px 0 166px 0
      text-align center
      display none
      @media (max-width: 600px)
        display flex
        margin 234px 0 94px
      a
        font-size 16px
        width 100%
        bold()
        color white
        text-decoration underline
.sign-box
  .errors
    list-style-type none
    color: flience-orange
    margin: 15px 0 0 0
  & form
    display flex
    flex-direction column
    & > h3
      margin-top 1rem
    input[type=submit]
      text-align center
    & > input
      border 1px solid rgba(gray, 0.3)
      padding 0.5rem 2rem
      margin 0 1rem
      border-radius 3px
      &:focus
        border 2px solid #afd2d6
      &:not(:first-of-type)
        margin-top 1rem
      &:last-of-type
        cursor pointer
