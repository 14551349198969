.social
  position absolute
  z-index 1
  top 21rem
  left 0
  display flex
  flex-direction column
  @media screen and (max-width: 1190px)
    top 26rem
  @media screen and (max-width: 650px)
    visibility hidden
  &__icon
    width 2.8rem
    transition 0.2s opacity
    &:hover
      opacity 0.3