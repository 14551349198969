section.billing-section
    width: 100%
    margin-bottom: 300px
    margin-top: 70px
    .billing-wrapper
        margin: 0px auto
        width: 660px
        .title
            font-size: 16px
            color: #3C3C3B
            font-weight: bold
        .billing-cards
            margin-top: 30px
            display: flex
            flex-direction: row
            justify-content: space-between
            .card  
                // klasa choosen dla karty
                list-style-type: none
                height: 355px
                width: 321px
                border-radius: 10px
                border: 2px solid #F57A5B
                display: flex
                flex-direction: column
                align-items: center
                justify-content: space-between
                padding: 5px
                position: relative
                box-shadow: 0px 7px 0px 0px rgba(209,205,205,1);
                &.choosen
                    background: #9FD6C1
                    border-color: #9FD6C1
                    .card-title
                        color: white
                    button.btn-ok
                        background: white
                        color: #9FD6C1
                        border-color: #9FD6C1

                .card-title
                    font-size: 25px 
                    color: #F57A5B
                    font-weight: bold
                    margin-bottom: 0px
                    margin-top: 10px
                .card-inside
                    height: 277px
                    width: 100%
                    border-bottom-left-radius: 10px
                    border-bottom-right-radius: 10px
                    background: white
                    display: flex
                    flex-direction: column
                    align-items: center
                    justify-content: center
                    .field
                        height: 70px
                        display: flex
                        align-items: center
                        justify-content: center
                        width: 100%
                        &.field2
                            border-top: 1px solid #F6F6F6
                            border-bottom: 1px solid #F6F6F6
                            font-weight: bold
                        &.field3
                            font-weight: bold
                .btn-ok
                    position: absolute
                    font-size: 16px
                    color: white
                    font-weight: bold
                    padding: 7px 35px
                    border-radius: 20px
                    border: none
                    background: #F57A5B
                    border: 1px solid #F57A5B
                    bottom: -19px
                    outline: none !important
                    cursor: pointer

@media (max-width: 680px)
    section.billing-section
        .billing-wrapper
            width: 100%
            .title
                text-align: center
            .billing-cards
                width: 100%
                flex-direction: column
                align-items: center
                .card
                    margin-top: 10px
                    margin-bottom: 20px