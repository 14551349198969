.menu
  width 100%
  background-color flience-light-grey
  position relative
  z-index 10px !important
  .main-header
    max-width 1440px !important
    margin 0 !important
    position relative
    z-index -999999
    @media (max-width: 1440px)
      padding 0 60px 0 36px
    @media (max-width: 812px)
      min-height 80px
    @media (max-width: 375px)
      align-items center
  .header-container
    content-container()
    position: relative
    display flex
    min-height 117px
    flex-flow row wrap
    justify-content space-between
    align-items center
    z-index 100
    @media (max-width: 1570px)
      margin 0 60px
    @media (max-width: 812px)
      min-height 80px
    @media (max-width: 375px)
      align-items center
    .header-logo
      width 107px
      height 34px
      @media (max-width: 375px)
        width 83px
        height 26px
      a
        display block
        text-decoration none
        margin 0
        padding 0
        display block
        .header-logo-img
          margin 0
          padding 0
          width 100%
          height 100%
          fill #3C3C3B
          &, & *
            fill #3C3C3B
      .header-logo-img-white
        margin 0
        padding 0
        width 107px
        height 34px
        position absolute
        top 22px
        left 27px
        display none
        z-index 50
        @media (max-width: 375px)
          width 83px
          height 26px
        svg
          fill #ffffff
          @media (max-width: 375px)
            width 83px
            height 26px
          &, & *
            fill #ffffff !important
    .header__select
      position relative
      display flex
      flex-flow row
      align-items center
      justify-content space-between
      width 222px
      height 40px
      border-radius 10px
      border none
      outline none
      color flience-dark-grey
      text-transform lowercase
      cursor pointer
      background-color white
      position relative
      z-index 10 !important
      @media (max-width: 812px)
        display none
      &::after
        content ''
        position absolute
        right 30px
        width 0
        height 0
        border-left 5px solid transparent
        border-right 5px solid transparent
        border-top 8px solid flience-dark-grey
        color flience-dark-grey
      p
        bold()
        padding 17px 26px 0 30px
        width 100%
        font-size 16px
      .select-list
        background-color #f6f6f6
        list-style-type none
        position absolute
        z-index 100000
        top 47px
        width 222px
        opacity 0
        max-height 0
        transition 0.2s ease-in-out
        box-shadow 0px 12px 0px 0px rgba(0, 0, 0, 0.1)
        border-radius 10px
        li
          &:first-child
            border-top-right-radius 10px
            border-top-left-radius 10px
        li
          &:last-child
            border-bottom-right-radius 10px
            border-bottom-left-radius 10px
        .header__option
          padding 5px 0
          border-bottom-left-radius 10px
          border-bottom-right-radius 10px
          &:hover
            bold()
            background-color: white
          a
            font-size 16px
            text-decoration none
            color flience-dark-grey
            .select-item
              padding 10px 33px 0 33px
      .header__option
        cursor pointer
.header__view
  display flex
  flex-flow row
  @media (max-width: 1005px)
    display none
  a
    margin 0 43px 0 0
    display flex
    flex-flow row
    display block
    text-decoration none
    color flience-dark-grey
    text-transform lowercase
    margin 0 40px 0 0
  a:nth-child(2)
    margin: 0
  .option-maths1, .option-language1
    position relative
.logIn-select
  position relative
  display flex
  flex-flow row
  align-items center
  justify-content space-between
  width 222px
  height 40px
  border-radius 10px
  border none
  outline none
  color flience-dark-grey
  text-transform lowercase
  cursor pointer
  position relative
  border-radius 10px
  z-index 500000
  @media (max-width: 812px)
    display none
  li
    &:first-child
      border-top-right-radius 10px
      border-top-left-radius 10px
  li
    &:last-child
      border-bottom-right-radius 10px
      border-bottom-left-radius 10px
  .header__option
    padding 5px 0
    border-bottom-left-radius 10px
    border-bottom-right-radius 10px
  &::after
    content ''
    margin 0 37px 0 0
    width 0
    height 0
    border-left 5px solid transparent
    border-right 5px solid transparent
    border-top 8px solid flience-dark-grey
    color flience-dark-grey
  .sign-in-avatar
    content ''
    width 32px
    height 32px
    border-radius 100px
    //border 1px solid grey
    color flience-dark-grey
    position absolute
    right 62px
    top 5px
    & img
      width 100%
      height 100%
      border-radius 50%
  p
    black()
    padding 17px 20px 0 33px
    width 100%
    font-size 14px
  .logIn-list
    background-color white
    list-style-type none
    position absolute
    top 55px
    right 34px
    z-index 100000
    width 121px
    border-bottom-right-radius 10px
    border-bottom-left-radius 10px
    opacity 0
    max-height 0
    transition 0.2s ease-in-out
    list-style-type none
    box-shadow 0px 12px 0px 0px rgba(0, 0, 0, 0.1)
    min-height 105px
    background-color flience-login-white
    li
      a
        font-size 14px
        padding 10px 13px 10px 33px
        display block
        width 100%
        height 100%
        regular()
        color flience-dark-grey
        text-decoration none
        text-align right
        &:hover
          bold()
          background-color flience-white
    .header__option
      padding 5px 0
      a
        text-decoration none
        color flience-dark-grey

.header-sign-in
  display flex
  flex-flow row
  justify-content center
  align-items center
  @media (max-width: 812px)
    display none
button
  padding 0
  a
    bold()
    margin 0
    padding 0
.sign-in
  margin 0 13px 0 0
  cursor pointer

& + .language
  right -40px
.language 
  position absolute
  top: 11px
  right: -2px
  //transform: translateY(-50%)
  list-style-type none
  z-index: 9999999
  font-size: 14px
  display flex
  flex-direction column
  &:last-child 
      margin-top: 10px

  @media (max-width: 1441px)
    right 0px

  @media (max-width: 812px)
    top: 0px

  &:hover * 
    //display: block

  li
    display: none
    order: 2
    //visibility: hidden
    height: 20px
    align-items center
    justify-content: center

    &.active
      display: flex
      align-items center
      justify-content: center
      //visibility: visible
      color: #F57A5B !important
      transform: none
      border-left: 4px solid #b2d6dd
      //padding-left: 5px
      margin: 0
      order: 1



      & a
        color: #F57A5B
        padding-left: 3px
        margin: 0

  & a
    text-decoration: none
    font-weight: bold
    color: #b2d6dd
    line-height: 20px
    padding-left: 8px
    font-size: 14px
    margin-top 3px
  & * 
    background-color: transparent !important
  
.register-menu-box
  display flex
  position relative
  .language
    position absolute
    top 12px
    right -30px
    margin 0
    @media(max-width: 812px)
      position:fixed
      top:30px
      right: 20px

.position
   @media (max-width: 812px)
     left:40px
.space
  @media (max-width: 812px)
     padding-left: 20px !important
