.signup-wrapper
  min-height: 900px
  width: 100%
  display: flex
  justify-content: center
  align-items: flex-start
  background-color: flience-login-blue
  position: relative
  @media (max-width: 768px)
    height: 100%
    overflow-x: hidden
  .signup__container
    display: flex
    flex-flow: column
    justify-content: space-around
    align-items: center
    z-index: 3
    @media (max-width: 676px)
      margin: 0 16px
      width 90%
      max-width 600px
    .sign-in-rwd
      display: none
      margin: 40px 0 25px 0
      text-align: center
      color: white
      font-size: 16px
      flex-flow: column
      bold()
      p
        padding: 0
        margin: 0
      a
        color: white
        font-size: 16px
        bold()
        text-align: center
        text-decoration: underline
        cursor: pointer
      @media (max-width: 600px)
        display: flex

    .signup__text
      display: flex
      flex-flow: column
      justify-content: center
      align-items: center
      padding: 20px 0 40px 0
      height: 100%
      black()
      span
        regular()
    .wrapper
      display: flex
      flex-flow: row
      justify-content: center
      width: auto
      position relative
      @media (max-width: 1045px)
        flex-flow: column
        flex-direction column
      @media (max-width: 676px)
        width 100%
      .login-clouds
        width: 317px
        height: 175px
        background-image: url('../../dist/img/home/cloud.png')
        background-size: contain
        position: absolute
        right: -90px;
        top: -120px;
        z-index: -1
        @media (max-width: 375px)
          right: -198px;
        @media (max-width: 600px)
          display: flex
      .login-kiwi
        width: 200px
        height: 149px
        background-image: url('../../dist/img/globalne/bird.svg')
        background-size: contain
        position: absolute
        background-repeat: no-repeat
        right: -86px;
        top: -86px;
        @media (max-width: 600px)
          display: flex
      .signup__subscription
        width: 100%
        max-height: 791px
        display: flex
        flex-flow: column
        justify-content: flex-start
        align-items: flex-start
        background-color: flience-login-white
        padding: 32px 60px 70px 60px
        position: relative
        .step
          display none
          cursor pointer
          @media (max-width 1045px)
            margin-top 50px
            display block
            position relative
            left 50%
            transform translateX(-50%)
          @media (max-width: 676px)
            position unset
            transform none
        @media (max-width: 676px)
          max-height: unset
          padding: 32px 19px 50px 19px
          align-items center

        .button-wrapper
            display: none
            width: 100%
            padding: 0
            margin: 57px 0
            @media (max-width: 676px)
              display: flex
            a
              text-decoration: none
              cursor: pointer
              .registery-mobile
                border: 1px solid red
        .top-header
          display: flex
          flex-flow: row
          margin: 0 0 38px 0
          @media (max-width: 676px)
            margin: 0 0 38px 5%
          .circle-number
            border-radius: 50%
            width: 20px
            height: 20px
            background-color: flience-login-blue
            font-size: 14px
            black()
            text-align: center
            color: flience-white
          .title
            margin: 0 0 0 13px

            strong
              black()

        .elements
          display: flex
          flex-flow: row
          justify-content: center
          align-items: center
          width: 100%
          @media (max-width: 676px)
            flex-direction column

          @media (max-width: 676px)
            flex-flow: column
          .first-month
            height: 375px
            width: 340px
            background-color: flience-white
            border-radius: 10px
            margin: 0 6px
            display: flex
            flex-flow: column
            justify-content: space-between
            align-items: center
            text-align: center
            cursor: pointer
            @media (max-width: 676px)
              width: 98%
            .header-1
              black()
              border-top: 0
              line-height: 3
              color: #9fd6c1
              height: 70px
              width: 100%
              background-color: transparent
            .box-1
              background-color: flience-white
              width: 100%
              margin: 0 0 5px 0;
              border-bottom-left-radius: 10px
              border-bottom-right-radius: 10px
              display: flex
              flex-flow: column
              align-items: center
              position: relative
              justify-content: flex-start
              height: 285px
              .options
                border-radius: 10px
                width: 100%
                height: 278px
                background-color: flience-white
                list-style-type none
                text-align: center
                p
                  &:nth-child(1)
                    width: 100%
                    padding: 26px 0 47px 0
                    margin: 0
                    position: relative
                  &:nth-child(2)
                    padding: 26px 0
                    margin: 0
                    position: relative
                    border-bottom: 1px solid #F2F2F2
                    border-top: 1px solid #F2F2F2
                  &:nth-child(3)
                    padding: 26px 0 0 0
                    margin: 0
                    height: 73px
                    position: relative
                  strong
                    black()
              .free
                width: 130px
                height: 40px
                border-radius: 50px
                font-size: 16px
                bold()
                display: flex
                justify-content: center
                align-content: center
                padding: 8px 0 0 0
                bottom: -28px
                color: flience-green
                position: absolute
                background-color: flience-white
                .svg-circle1
                  width: 24px
                  height: 24px
                  border: 2px solid flience-green
                  margin: 0 16px 0 0
                  border-radius: 50%
                  justify-content: center;
                  align-items: center;
                  display: flex
                  color: white
                  svg
                    opacity 0
                  img.svg
                    margin: 0 0 3px 0
                    width: 9px
                    height: 8px
                    fill: white !important
                    display: none
                    &, & *
                      fill: white !important
          .next-months
            z-index: 2
            border: 1px solid flience-orange
            background-color: white
            height: 355px
            width: 258px
            border-radius: 10px
            margin: 0 6px
            display: flex
            flex-flow: column
            justify-content: space-between
            align-items: center
            text-align: center
            cursor not-allowed
            position relative
            @media (max-width: 676px)
              margin: 50px 0 0 0
              width: 98%
            .header-2
              border-top: 0
              line-height: 3
              font-size: 25px
              black()
              color: flience-orange
              width: 80%
              background-color: transparent
            .box-2
              background-color: rgba(255,255,255,.95)
              width: 100%
              height: 100%
              border-radius: 10px
              display: flex
              flex-flow: column
              justify-content: flex-start
              align-items: center
              position: absolute
              z-index 1000
              ul
                border-radius: 10px
                width: 100%
                background-color: rgba(255,255,255,.95)
                list-style-type none
                text-align: center
                padding: 90px 0 0 0
                li
                  font-size: 25px
                  color: flience-orange
                  padding: 27px 0 0 0
                  strong
                    black()


        .login-cloud-blue1
          width: 759px
          height: 418px
          background-image: url('../../dist/img/home/cloud.png')
          background-size: contain
          position: absolute
          left: -85px;
          top: -136px;
          z-index: -1
          opacity: .1
      .sign-box
        background-color: flience-login-white
        padding: 32px 60px
        position: relative
        margin: 0 0 0 10px
        display: flex
        flex-flow: column
        justify-content: center
        align-items: center
        @media (max-width: 1045px)
          margin: 50px 0 0 0
          display none
        @media (max-width: 600px)
          padding: 32px 19px 70px 19px
          display: none
        .top-header
          display: flex
          flex-flow: row
          margin: 0 0 38px 0
          .circle-number
            border-radius: 50%
            width: 20px
            height: 20px
            background-color: flience-login-blue
            font-size: 14px
            black()
            text-align: center
            color: flience-white
          .title
            margin: 0 0 0 13px
            strong
              black()
        .login-clouds
          width: 317px
          height: 175px
          background-image: url('../../dist/img/home/cloud.png')
          background-size: contain
          position: absolute
          right: -90px;
          top: -120px;
          z-index: -1
          @media (max-width: 375px)
            right: -198px;
        .login-kiwi
          width: 200px
          height: 149px
          background-image: url('../../dist/img/globalne/bird.svg')
          background-size: contain
          position: absolute
          background-repeat: no-repeat
          right: -86px;
          top: -86px;
        form
          display: flex
          flex-flow: column
          justify-content: center
          align-items: center
          padding :0
          margin: 0
          background-color: flience-login-white
          width: 340px
          @media (max-width: 1045px)
            width: 100%
          .password-box
            display: flex
            flex-flow: row
            height: auto
            :nth-child(1)
              margin: 0 10px 0 0
            :nth-child(2)
              margin: 3px 0 0 0
            input[type="password"]
              background-color: grey
              background-color: flience-white
              height: 41px
              padding: 0 0 0 21px
              width: 100%
              border-radius: 10px
              border: none
              color: flience-dark-grey
              &::placeholder
                regular()
                font-size: 14px;
                color: flience-placeholder-grey
                text-transform: lowercase
          label
            display: flex
            justify-content: center
            align-items: flex-start
            flex-flow: column
            margin: 0 0 15px 0
            padding: 0
            width: 100%
            input[type="text"], input[type="email"]
              background-color: grey
              background-color: flience-white
              height: 41px
              width: 100%
              border-radius: 10px
              margin: 7px 0 0 0
              border: none
              color: flience-dark-grey
              padding: 0 0 0 21px
              &::placeholder
                regular()
                font-size: 14px;
                color: flience-placeholder-grey
                text-transform: lowercase
          .button-width153
            border-radius: 50px
            width: 204px
            cursor: pointer
        .policy
          margin: 26px 0 0 0
          .option
            display: flex
            position: relative
            input[type=checkbox]
              opacity: 1
              cursor: pointer
              position: absolute
              top: 2.5px
              left: 0px
            .checkbox-opton
              margin: 0 0 0 35px
              a
                color: flience-blue


/* Customize the label (the container) */
.option {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: flience-white;
  border-radius: 50%;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.option input:checked ~ .checkmark:after {
  display: block;
}

.option .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: flience-blue;
}

.per-month
  width: 168px
  height: 40px
  border-radius: 50px
  font-size: 16px
  position: relative
  z-index: 10
  border: 1px solid flience-orange
  bold()
  display: flex
  justify-content: center
  align-content: center
  padding: 8px 0 0 0
  position: absolute
  bottom: -28px
  color: flience-orange
  background-color: white

  &.soon
    width: 168px;
    height: 40px;
    border-radius: 50px;
    font-size: 16px;
    z-index: 100;
    border: 1px solid #f57a5b;
    font-weight: 700;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    padding: 8px 0 0;
    position: absolute;
    bottom: -28px;
    color: #f57a5b;
    background-color: rgba(255,255,255,.95)

  .svg-circle
    width: 24px
    height: 24px
    border: 2px solid flience-orange
    margin: 0 16px 0 0
    border-radius: 50%
    justify-content: center;
    align-items: center;
    display: flex
    img.svg
      margin: 0 0 3px 0
      width: 9px
      height: 8px
      fill: white !important
      display: none
      &, & *
        fill: flience-orange !important

.rejestration2
    background-color: flience-login-white
    padding: 32px 60px
    position: relative
    margin: 0 0 0 10px
    display: flex !important
    flex-flow: column
    @media (max-width: 1045px)
      margin: 50px 0 0 0

.img-form
  &-rwd
    @media (max-width: 1045px)
      display none
  &-mb
    display none
    @media (max-width: 1045px)
      display block !important

.selected
  &-first-month
    background-color #9FD6C1 !important
    box-shadow 0px 12px 0px 0px rgba(206,206,206,1) !important
    border 5px solid #9FD6C1 !important

    .header-1
      color white !important
    .free
      background-color #9FD6C1 !important
      color white !important
    .tick1
      display flex !important
    .svg-circle1
      background-color white !important
      svg
        opacity 1 !important
  &-next-months
    .header-2
      color white !important
    .per-month
      background-color #f57a5b !important
      color white
    .svg-circle
      background-color white !important
    .tick2
      display flex !important

.password-error
  display none
  color flience-orange
  margin-bottom -20px
  margin-top 10px
  width 100%
