$res-hamburger = 1000px
header a
  font-size 1.5rem
  text-decoration none
  &:hover
    color gray
.header
  border-top 0.5rem solid black
  background-color #f2f2f2
  position relative
  z-index 1
  &__select
    height 2rem
  &__view
    &-item
      text-decoration none
      font-size 2rem
      &:hover
        color gray
  &__download
    &-box
      position relative
      cursor pointer
      display flex
      align-items center
      justify-content center
    &--count
      position absolute
      top -0.8rem
      right -0.8rem
      border-radius 50%
      background-color red
      width 2rem
      height 2rem
      display flex
      align-items center
      justify-content center
  &__sign-out
    display flex
    flex-direction column
    text-align right
    @media screen and (max-width: $res-hamburger)
      margin-left 5rem
  &__sign
    display flex
    flex-direction column
    & > p
      text-decoration none
      color rgba(gray, 0.3)
      font-size 2rem
      font-weight bold
      transition 0.3s all
      cursor pointer
      &:hover
        color gray
      &:first-of-type
        margin-bottom 2rem
        @media screen and (max-width: 700px)
          margin-bottom 0
          margin-right 2rem
  &__box
    position relative
    left 50%
    transform translateX(-50%)
    max-width 150rem
    display flex
    justify-content space-between
    align-items center
    padding 2rem 0
    justify-items center
  &__flex-box
    display flex
    justify-content space-around
    align-items center
    flex-grow 4
  &__rates-box
    display flex
  &__logo
    display flex
    align-self center
    align-items center
    font-size 3rem
    flex-grow 2
    justify-content center
    @media screen and (max-width: $res-hamburger)
      align-self flex-start
    & img
      width 18rem
.rate
  display flex
  flex-direction column
  align-items center
  padding 0 5px
  &__wrapper
    display flex
    flex-direction column
    justify-content center
    align-items center
    opacity 0.3
    cursor pointer
    height 5rem
    transition 0.5s all
    &:hover
      opacity 1
  &-2
    border-left 1px dashed rgba(gray, 0.3)
    border-right 1px dashed rgba(gray, 0.3)
.icons
  display grid
  max-width 50%
  max-height 20rem
  grid-template-rows repeat(2, 5rem)
  grid-auto-flow column
  @media screen and (max-width: 1330px)
    grid-template-rows repeat(3, 5rem)
  @media screen and (max-width: 1000px)
    grid-template-rows repeat(4, 5rem)
  @media screen and (max-width: $res-hamburger)
    max-width none
    width auto
    margin-left 5rem
    grid-template-rows repeat(4, 5rem)
  @media screen and (max-width: 700px)
    margin-left 0
    margin-top 1rem
  &__icon
    height 5rem
    opacity 0.3
    cursor pointer
    transition 0.2s all
    &:hover
      opacity 1
    &-box
      height 5rem
      border 1px dashed rgba(gray, 0.3)
      border-left-width 0
      border-top-width 0
      visibility hidden
.hello-user
  font-size 1.5rem
  color black
  @media screen and (max-width: 700px)
    margin-right 1rem