section.invoices-section
    width: 100%
    margin-bottom: 220px
    margin-top: 70px
    position: relative
    z-index: 50;
    div.invoices-wrapper
        margin: 0 auto
        width: 660px
        @media(max-width: 800px)
            width: 80%
        .title 
            font-size: 16px
            color: #3C3C3B
            font-weight: bold
        .invoices-table
            margin-top: 30px
            ul
                display: flex
                justify-content: space-between
                flex-direction: column
                .table-row
                    list-style-type: none
                    display: flex
                    justify-content: space-between
                    width: 100%
                    font-size: 14px
                    &.table-headers
                        color: #F6F6F6
                        background-color: #3C3C3B
                    &.table-row-content
                        border-bottom: 1px solid #F6F6F6
                     .field
                        padding: 15px 45px 13px 20px
                        width: 20%
                        &.field2
                            padding-top: 30px 
                            padding-bottom: 30px
                    
 

