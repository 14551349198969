.billing-premium
    margin-bottom: 300px
    .title
        font-size: 16px;
        color: #3c3c3b;
        font-weight: 700;
    .billing-section
        margin-bottom: 0px
        .billing-wrapper
            .billing-cards
                .card
                    border-color: flience-green
                    &.choosen
                        background: flience-orange
                        border-color: flience-orange
                        .btn-ok
                            color: flience-orange
                            border-color: flience-orange
        .underline-section
            border-bottom: 1px solid #969695
            opacity: 0.5
            width: 894px
            margin: 60px auto 45px auto
    .payment-section
        .invoices-table
            margin-top: 30px
            ul
                display: flex
                justify-content: space-between
                flex-direction: column
                .table-row
                    list-style-type: none
                    display: flex
                    justify-content: space-between
                    width: 100%
                    font-size: 14px
                    &.table-headers
                        color: #F6F6F6
                        background-color: #3C3C3B
                    &.table-row-content
                        //border-bottom: 1px solid #F6F6F6
                     .field
                        padding: 15px 45px 13px 20px
                        width: 33%
                        &.field2
                            padding-top: 30px 
                            padding-bottom: 30px
                            &.field3
                                display: flex
                                justify-content: center
                                .button-widthauto
                                    width: 123px
                                    height: 30px
                                    padding: 0px
    .payment-details
        .billing-wrapper
            form
                margin-top: 30px
                display: flex
                flex-wrap: wrap
                label 
                    width: 100%
                    display: flex
                    flex-direction: column
                    margin-bottom: 19px
                    span 
                        padding-left: 10px
                    input  
                        border-radius: 10px
                        padding: 10px 20px 
                        background: #F6F6F6
                        border: none
                        color: #D8D8D8
                &:last-child
                    .last-input
                         display: flex
                         justify-content: space-between
                         input 
                            width: 80%
                        a
                            cursor: pointer
                            width: 17%
                            color: flience-orange
                            font-weight: bold
                            font-size: 14px
                            align-self: center
                button
                    background: flience-orange
                    color: white
                    margin-top: 39px


@media(max-width: 700px)
    .billing-wrapper
        width: 90% !important
        
