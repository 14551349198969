.menu
  .hamburger-menu
    display none
    //display block
    position relative
    flex-flow column
    justify-content space-between
    align-items center
    width 22px
    margin-right 40px
    height 20px
    cursor pointer
    transition 0.5s
    .bar1
      width 100%
      height 2px
      background-color flience-dark-grey
      position absolute
      top 0
      left 0
      transition 0.5s
    .bar2
      width 100%
      height 2px
      background-color flience-dark-grey
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
    .bar3
      width 100%
      height 2px
      background-color flience-dark-grey
      position absolute
      bottom 0
      left 0
      transition 0.5s
    @media (max-width: 812px)
      display flex
      @media (max-width: 812px)
        display flex
  .menu-elements
    z-index -5
    width 100vw
    position absolute
    top 80px
    left 50%
    transform translateX(-50%)
    display none
    opacity 0
    transition 0.2s linear
    //height auto
    background-color rgba(60, 60, 59, 1) !important
    & > .menu-mb-list
      text-align center
      background-color rgba(60, 60, 59, 1) !important
      width 100vw
      .list-header
        font-size 16px
        bold()
        color flience-orange
        width 100%
        margin 50px 0 22px 0
      & li
        list-style-type none
        position relative
        cursor pointer
        padding 0 0 27px 0
        :hover
          black()
        & a
          padding 10px 0
          display block
          text-decoration none
          color flience-white
          cursor pointer
          font-size 20px
          display inline-block
          position relative
          regular()
          &::before
            content ''
            width 8px
            height 8px
            border-radius 100%
            background-color flience-orange
            color flience-blue
            font-weight bold
            display inline-block
            position absolute
            left -15px
            top 20px
.sub-dysciplin
  display flex
  flex-flow column
  :hover
    black()
  a
    text-decoration none
    color flience-white
    padding 0 0 27px 0
.button-line
  padding-left 50%
  width 300px
  align-items center
  justify-content center
  display flex
  flex-flow column
  margin 0px 0 0px 0
  .shop-url-ham
    margin-right 14px
    padding 9px
    font bold Lato sans-serif
    text-decoration none
    color flience-white
    font-size 20px
    box-sizing border-box
  a
    padding 10px 0
    display block
    text-decoration none
    color flience-white
    cursor pointer
    font-size 20px
    regular()
    button
      cursor pointer
      @media (max-width: 812px)
        width 226px

.social-line
  display flex
  flex-flow row
  justify-content center
  margin 0 0 50px 0
  .piterest
    margin 0 6px 0 0
.active
  transform rotate(90deg)
.hamburger-menu.active
  .bar1
    top calc(50% - 2px)
    transform rotate(50deg)
    background-color flience-orange
.hamburger-menu.active
  .bar3
    bottom calc(50%)
    transform rotate(-50deg)
    background-color flience-orange
.hamburger-menu.active
  .bar2
    display none
.language-mb
  list-style-type: none
  display flex
  justify-content center
  li
    height 20px
    margin 20px 0
    padding 0
    padding-left 20px !important
    padding-right 20px !important
    display flex

    &.active-mb a
      color #F57A5B !important

    a
      padding 0 !important
      color #a2ccd8 !important
      cursor pointer

    & *::before
      content none !important

    &:last-of-type
      padding 0
      padding-left 20px !important
      border-left 2px solid #a2ccd8
