    .settings-menu
      margin: 0px auto
      width: 660px
      display: flex
      align-items: flex-end
      justify-content: flex-start
      ul.settings-menu-list
        display: flex
        flex-direction: row
        margin: 0px
        a
          text-decoration: none
        li.item
          padding: 5px 25px
          list-style-type: none
          color: #94AFB4
          position: relative
          z-index: 0
          &.item-active
            color: black
            &:before
              content: ''
              position: absolute
              z-index: -1
              height: 47px
              width: 85px
              background-image: url('../../dist/img/cloud-menu.svg')
              top: -13px
              left: 50%
              transform: translateX(-50%)
