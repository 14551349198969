.signUp-complete
  width: 100%
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  background-color: flience-login-blue
  position: relative
  @media (max-width: 768px)
    height: 100%
    overflow-x: hidden
  .complete-cloud-blue1
    width: 759px
    height: 418px
    background-image: url('../../dist/img/home/cloud.png')
    background-size: contain
    position: absolute
    left: 0px;
    top: 0px;
    z-index: 1;
    opacity: 0.16;
    @media (max-width: 440px)
      left: -417px;
  .complete-cloud-blue2
    width: 759px
    height: 418px
    background-image: url('../../dist/img/home/cloud.png')
    background-size: contain
    position: absolute
    right: -373px;
    bottom: -250px;
    z-index: 1;
    opacity: 0.16;
    @media (max-width: 440px)
      left: -28px;
  .signUp-complete-wrapper
    display: flex
    flex-flow: column
    align-items: center
    z-index: 3
    height: 100vh
    @media (max-width: 648px)
      margin: 0 16px
    .wrapper-for-kiwi
      width: 257px
      height: 178px
      margin: 37px 0 95px 0
      img
        width: 100%
        height: 100%

    .complete-header
      black()
      display: flex
      flex-flow: column
      justify-content: center
      align-items: center
      padding: 0 0 40px 0
      position: relative
      z-index: 1
      text-align: center
    .h4-global
      regular()
    .button-width170
      width: 116px
      margin: 33px 0 0 0


.signup-wrapper .signup__container .wrapper .sign-box.signup__box
  align-items flex-start

