.notification-section
    width: 100%
    height: 100%
    margin-bottom: 300px
    margin-top: 70px
    position: relative
    z-index: 50;
    .notification-wrapper
        margin: 0 auto
        width: 660px
        @media(max-width: 950px)
            width: 80%
        .title 
            font-size: 16px
            color: #3C3C3B
            font-weight: bold
        .notification-checkbox
            margin-top: 45px
            margin-bottom: 45px
            .container
                display: block
                position: relative
                padding-left: 30px
                margin-bottom: 23px
                cursor: pointer
                font-size: 14px
                -webkit-user-select: none
                -moz-user-select: none
                -ms-user-select: none
                user-select: none
                cursor: pointer 
                input 
                    position: absolute
                    visibility: hidden
                    cursor: pointer
                    height: 0
                    width: 0
                .checkmark 
                    position: absolute
                    top: 0
                    left: 0
                    height: 24px
                    width: 24px
                    border: 2px solid flience-orange
                    background-color: white
                    border-radius: 50%
                    &:after
                        content: "\2714";
                        position: absolute;
                        display: none;
                        left: 4px;
                        color: flience-orange
                input[type="checkbox"]:checked 
                    & + .checkmark  
                        &:after 
                            display:block;


        .newsletter-description
            margin-top: 5px
            margin-bottom: 28px
            display: flex
            flex: wrap
        
        .newsletter-input
            input[type=text]
                width: 490px
                height: 55px !important
                border-radius: 10px
                border: 1px solid fleince-grey
                color: #C8C8C8
                margin-right: 12px
                padding-left: 33px
                margin-right: 5px
                @media(max-width: 800px)
                    width: 100%
        .unsubscribe
            display:none

@media(max-width: 800px) 
    .button-width153 
        margin-top:10px
                


