.premium-wrapper
    min-height: 100%
    padding-top: 40px
    .premium-container
        .header-logo
            align-self: start 
            margin-left: 62px
        .premium-content
            margin-top: 45px
            width: 100%
            .premium-text
                color: flience-dark-grey
                margin-top: 0px
            .premium-box
                min-width: 755px
                //height: 483px
                background-color: #f6f6f6
                padding: 76px 70px 70px
                position: relative
                margin: 0 auto
                border-radius: 10px
                padding: 32px 77px 140px
                @media(max-width: 800px)
                    min-width: 500px
                @media(max-width: 600px)
                    min-width: 340px
                    padding-left: 20px
                    padding-right: 20px
                &.premium2-box
                    min-width: 660px
                    padding: 32px 23px 140px 28px
                    @media(max-width: 800px)
                        min-width: 500px
                    @media(max-width: 600px)
                        min-width: 340px
                        padding-left: 20px
                        padding-right: 20px
                .top-header
                    margin-bottom: 21px
                    display: flex
                    .circle-number
                        border-radius: 50%;
                        width: 20px;
                        height: 20px;
                        background-color: #a3ced6;
                        font-size: 14px;
                        font-weight: 900;
                        text-align: center;
                        color: #fff;
                    .title
                        margin-left: 10px
                        font-weight: bold;
                        color: flience-dark-grey
                .billing-cards
                    margin-top: 30px
                    display: flex
                    flex-direction: row
                    justify-content: space-between
                    @media(max-width: 800px)
                        flex-direction: column
                        align-items: center
                        align-content: space-around
                    .card
                        list-style-type: none
                        //height: 240px
                        width: 48%
                        border-radius: 10px
                        border: 2px solid #F57A5B
                        display: flex
                        flex-direction: column
                        align-items: center
                        justify-content: space-between
                        padding: 5px
                        background-color: white
                        position: relative
                        box-shadow: 0px 7px 0px 0px rgba(209,205,205,1);
                        @media(max-width: 800px)
                            width: 70%
                            &:first-child
                                margin-bottom: 30px
                        @media(max-width: 600px)
                            width: 100%
                        &.choosen
                            background:  #F57A5B
                            .card-title
                                color: white 
                            button.btn-ok
                                background: #F57A5B
                                color:#F57A5B
                                .checkbox
                                    background-color: white
                                    background-size: 70%
                                    .svg
                                        visibility: visible;  
                        .card-title
                            font-size: 25px 
                            color: #F57A5B
                            font-weight: bold
                            margin-bottom: 0px
                            margin-top: 10px
                            padding: 10px 0
                        .card-inside
                            height: 160px
                            width: 100%
                            border-bottom-left-radius: 10px
                            border-bottom-right-radius: 10px
                            background: white
                            display: flex
                            flex-direction: column
                            align-items: center
                            justify-content: center
                            .field
                                height: 100px
                                display: flex
                                align-items: center
                                justify-content: center
                                width: 100%
                                border-top: 1px solid #F6F6F6
                                border-bottom: 1px solid #F6F6F6
                                font-weight: bold
                        .btn-ok
                            position: absolute
                            font-size: 16px
                            color: white
                            font-weight: bold
                            padding: 20px 30px
                            border-radius: 20px
                            border: none
                            background: white
                            border: none
                            bottom: -20px
                            outline: none !important
                            cursor: pointer
                            display: flex
                            left: 110px
                            .checkbox
                                background: white
                                position: absolute
                                z-index: 3
                                border: 1px solid #F57A5B
                                width: 24px
                                border-radius: 50%
                                top: 7px
                                left: 19px
                                .svg 
                                    visibility: hidden
                            @media(max-width: 800px)
                                left:90px
                            @media(max-width: 600px)
                                left:110px
            .buttons-options
                width: 300px
                display: flex
                align-content: center
                flex-direction: column
                margin: 50px auto 0 auto
                position: absolute
                bottom: 0
                left: 50%
                bottom: 34px
                transform: translateX(-50%)
                align-items: center
                @media(max-width: 480px)
                    width: 100%
                .buttons-group
                    display: flex
                    justify-content: center
                    .button1
                        width: 120px
                        color: flience-orange
                        background: #F6F6F6
                        border: 1px solid flience-orange
                        font-weight: bold
                        border-top-left-radius: 20px
                        border-bottom-left-radius: 20px
                    .button2
                        border-top-left-radius: 0
                        border-bottom-left-radius: 0
                        font-weight: bold
                .button-width185
                    //margin: auto
                    font-weight: bold
                    cursor: pointer
                .close-modal
                        color: flience-orange
                        margin-top: 14px
                        font-size: 14px
                        font-weight: normal
                        text-align: center
                        cursor: pointer
                .helper-container
                    margin: auto
                    margin-top: 10px
                    .svg 
                        margin-right: 5px
            .buttons-option2
                bottom:28px
            .premium2-form
                #billingAddressContainer-11707
                    width: 100%
                    .form-group
                        .heading
                        .input
                            input 
                                width: 100%
                form
                    width: 100%
                    padding: 0px 30px
                    label
                        font-size: 14px
                        ul
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            overflow: auto;
                            display: flex
                            justify-content: space-between
                            margin-top: 15px
                            li
                                display: flex
                                padding-right: 30px
                                .container
                                    display: block
                                    position: relative
                                    padding-left: 25px
                                    margin-bottom: 12px
                                    cursor: pointer
                                    font-size: 14px
                                    -webkit-user-select: none
                                    -moz-user-select: none
                                    -ms-user-select: none
                                    user-select: none
                                    cursor: pointer 
                                    font-weight: bold
                                    input 
                                        position: absolute
                                        visibility: hidden
                                        cursor: pointer
                                        height: 0
                                        width: 0
                                    .checkmark 
                                        position: absolute
                                        top: 0
                                        left: 0
                                        height: 16px
                                        width: 16px
                                        background-color: white
                                        border-radius: 50%
                                        &:after
                                            content: "";
                                            position: absolute;
                                            display: none;
                                            top: 4px;
                                            left: 4px;
                                            width: 8px;
                                            height: 8px;
                                            border-radius: 50%;
                                            background: flience-blue
                                    input[type=radio]:checked 
                                        & + .checkmark  
                                            &:after 
                                                display:block;
                    .person
                        width: 100%
                    .company
                        width: 100%
                        display: none;


            .button-close
                position: absolute
                width: 32px
                height: 32px
                background: white
                border-radius: 50%
                top: -7px
                right: -8px
                border: none
                box-shadow: 0px 3px 0px 0px rgba(209,205,205,1);
                cursor pointer
            .option2
                width: 300px
            .premium3-container
                width: 230px
                margin: 110px auto 
            .login-cloud-blue1
                left -211px
                bottom: 470px
            .cloud2
                bottom: 232px !important
            .cloud3
                left: -263px
                top: -167px
        .invoices-table
            margin-top: 30px
            ul
                display: flex
                justify-content: space-between
                flex-direction: column
                .table-row
                    list-style-type: none
                    display: flex
                    justify-content: space-between
                    width: 100%
                    font-size: 14px
                    &.table-headers
                        color: #F6F6F6
                        background-color: #3C3C3B
                    &.table-row-content
                        border-bottom: 1px solid #F6F6F6
                        background-color: flience-white
                     .field
                        padding: 15px 45px 13px 20px
                        width: 33%
                        &.field2
                            padding-top: 30px 
                            padding-bottom: 30px
                            &.field3
                                display: flex
                                justify-content: center
                                .button-widthauto
                                    width: 123px
                                    height: 30px
                                    padding: 0px
    .cloud-bottom
        width: 886px
        height: 365px
        right: -375px
        bottom: -114px
.premium-wrapper-scroll
    overflow-y: scroll
    height: 100vh

@media(max-width: 800px)
    .premium-wrapper
        overflow-y: scroll
        height: 100vh


