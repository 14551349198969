.newsletter
  max-height 591px
  background-color flience-light-grey
  padding 136px 0 275px 0
  position relative
  overflow hidden
  border-bottom 12px solid white
  @media (max-width: 768px)
    max-height 800px
  @media (max-width: 375px)
    padding 136px 0 150px 0
  .newsletter-claud
    align-items center
    width 353px
    height 195px
    background-image url('../../dist/img/home/cloud.png')
    background-size contain
    position absolute
    top 31px
    right -95px
    z-index 0
    @media (max-width: 375px)
      right 195px
  .newsletter-claud2
    align-items center
    width 353px
    height 195px
    background-image url('../../dist/img/home/cloud.png')
    background-size contain
    position absolute
    bottom -59px
    right -130px
    z-index 0
    @media (max-width: 375px)
      display none
  .newsletter-elements
    content-container()
    display flex
    flex-flow column
    justify-content center
    align-items center
    @media (max-width: 1440px)
      margin 0 3%
    @media (max-width: 375px)
      margin 0 36px
    .newsletter-text
      z-index 1
      text-align center
      padding 0 0 75px 0
      p
        medium()
        strong
          black()
    .newsletter-box
      text-align center
      display flex
      flex-flow row
      justify-content center
      align-items center
      position relative
      z-index 5
      @media (max-width: 768px)
        flex-flow column
      @media (max-width: 375px)
        width 100%
      input[type=text]
        width 518px
        height 54px
        border-radius 10px
        background-color flience-white
        margin 0
        border 0
        outline 0
        padding 0 0 0 20px
        position relative
        z-index 1
        @media (max-width: 768px)
          margin 0 0 50px 0
        @media (max-width: 555px)
          width 100% !important
        &::placeholder
          color white
          font-size 16px
          regular()
          color flience-dark-grey
      .email-input
        padding 10px 15px
        background-color #FFFFFF
        outline none
        border none
        border-radius 10px
        width 500px
        @media(max-width: 768px)
          width:300px
        @media(max-width: 350px)
          width:100%
      .button-box
        display flex
        flex-flow row
        justify-content flex-start
        align-items center
        width 353px
        height 195px
        background-image url('../../dist/img/home/cloud.png')
        background-size contain
        position absolute
        right -118px
        bottom -42px
        @media(max-width: 768px)
          right: -25px
          bottom: -20px
          z-index -1;
        @media (max-width: 350px)
          width 315px
          height 174px
          bottom: -5px
      .button-width153
        margin 0 0 0 42px
        z-index 1
        position relative
        left -25px
        @media (max-width: 368px)
          margin 0
.newsletter-popup
  display none
  position fixed
  height 100vh
  width 100vw
  z-index 10000000
  top 0
  left 0
  background-color rgba(0,0,0,.6)
  .chmurka
    position: absolute
    top: calc(50% + 113px)
    left: calc(50% - 557px)
    height: 174px
    width: 317px
    background-image url('../../dist/img/globalne/chmukra.png')
    background-repeat: no-repeat
    background-size: contain
  .ptok
    position: absolute
    top: calc(50% + 164px);
    left: calc(50% - 467px);
    height: 174px
    width: 317px
    background-image url('../../dist/img/globalne/kiwi-right.png')
    background-repeat: no-repeat
    background-size: contain
  .newsletter-wrapper
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    background-color #f6f6f6
    padding 50px
    max-width 800px
    width 100%
    height: 500px
    border-radius 20px

    .newsletter-popup-text
      font-size 20px
      font-weight bold
      margin-bottom 30px

    .email-input
      padding 10px 15px
      background-color #FFFFFF
      outline none
      border none
      border-radius 10px
      margin 5px 0 20px 0
      width 100%

    .email-text
      font-size 15px
      margin-bottom 0

    input[type=submit]
      position relative
      left 50%
      transform translateX(-50%)

    .close-div
      margin-top: 20px
      display: flex
      align-items: center
      justify-content: center
      p.close-btn
        width: fit-content
        color: flience-orange
        cursor: pointer
