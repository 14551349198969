a
  color: flience-orange
  font-weight: bold
  text-decoration: underline
  &:hover
    color: flience-orange


.cookies
  position fixed
  left 50%
  transform: translateX(-50%)
  bottom 5px
  width calc(100vw - 25px);
  background-color rgba(0,0,0,.55)
  padding 20px 40px
  border-radius 20px
  z-index 999999
  display flex
  justify-content space-between
  align-items center
  flex-direction: row
  color white

  &-img
    height 100px
    margin-right 20px

  &-accept
    cursor pointer
  .cookies-text
    padding-right: 50px

  button
    padding: 0px 25px
    cursor: pointer

@media (max-width: 768px)
  .cookies
    .cookies-text
      padding-right: 0px

@media (max-width: 500px)
  .cookies
    width: 100%
    align-items flex-start
    flex-direction column
    .cookies-img
      display: none
    .cookies-text
      height: 175px
      overflow-y: scroll
      font-size: 13px
      margin-bottom: 15px
