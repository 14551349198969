.h1-global
  font-size: calc(46px + (68 - 46) * ((100vw - 360px) / (1440 - 360))); !important
  font-family: 'Lato', sans-serif
.h3-global
  font-size: 28px !important
  line-height: 41px
.h4-global
  font-size: 25px !important

.p1-global
  font-size: 28px !important
  line-height: 41px
  font-family: 'Lato', sans-serif !important
  @media (max-width: 375px)
    font-size: 22px !important
    line-height: 32px
.p2-global
  font-size: 25px !important
  line-height: 37px
  font-family: 'Lato', sans-serif !important
.p3-global
  font-size: 24px !important
  line-height: 38px
  font-family: 'Lato', sans-serif !important
  @media (max-width: 375px)
    font-size: 20px !important
    line-height: 29px
.p4-global
  font-size: 20px !important
  line-height: 26px
  font-family: 'Lato', sans-serif !important
  @media (max-width: 375px)
    font-size: 22px !important
    line-height: 26px
.p5-global
  font-size: 16px !important
  line-height: 24px
  font-family: 'Lato', sans-serif !important
.p6-global
  font-size: 14px !important
