.filter-header-select
  margin 0 0 105px 0
  display flex
  flex-flow row
  justify-content center
  align-items center
  ul li
    &:hover 
      background-color: white
  @media (max-width: 900px)
    flex-flow column
    padding 0 36px
  @media (max-width: 375px)
    flex-flow column
    margin 0 0 43px 0
  .filter1, .filter2
    display flex
    flex-flow row
    justify-content center
    align-items center
    margin 23px 0
    cursor pointer
    @media (max-width: 504px)
      flex-flow column
      width 100%
    p
      font-size 16px
      height 100%
      padding 0
      margin 0
      bold()
      margin 0 10px 0 0
      min-width 130px
      @media (max-width: 375px)
        width 100%
        margin 0 0 11px 0
    .filter-select-list-level, .filter-select-list-category
      width 158px
      background-color flience-login-white
      border-radius 10px
      transition 0.2s ease-in-out
      height 40px
      width 158px
      margin 0 37px 0 0
      position relative
      text-align left
      @media (max-width: 900px)
        width 261px
      @media (max-width: 504px)
        width 100%
        margin 0
      &::after
        content ''
        position absolute
        right 30px
        top 16px
        width 0
        height 0
        border-left 5px solid transparent
        border-right 5px solid transparent
        border-top 8px solid flience-dark-grey
        color flience-dark-grey
      .level-choose, .category-choose
        bold()
        padding 8px 26px 0 30px
        width 100%
        font-size 16px
      .filter-list-category, .filter-list-level
        list-style-type none
        background-color #f6f6f6
        position absolute
        top 47px
        width 100%
        border-bottom-right-radius 10px
        border-bottom-left-radius 10px
        z-index -1
        display none
        flex-flow column
        opacity 0
        height 0
        transition 0.2s ease-in-out
        box-shadow 0px 12px 0px 0px rgba(0, 0, 0, 0.1)
        :last-child
          border-bottom-right-radius 10px
          border-bottom-left-radius 10px
        :first-child
          border-top-right-radius 10px
          border-top-left-radius 10px
        .filter-header-option-level, .filter-header-option-category
          list-style-type none
          padding 10px 33px 10px 33px
          .filter-select-item-level, .filter-select-item-category
            color flience-black
            text-decoration none
            display block
            width 100%
            height 100%
            font-size 16px
            bold()
    .category
      width 261px
      @media (max-width: 504px)
        width 100%
  .reset-filter-button
    cursor pointer
.popup__info-level
  padding-bottom: 2px
  margin-left: 4px
.popup__info-icon-category
  margin-left: 5px
  padding: 1px 3px 3px 3px
  width: 32px
  height: 32px