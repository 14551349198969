.polityka-prywatnosci
  width: 100%;
  @media (max-width: 768px)
    padding: 0
    background: transparent
  .policy-wrapper
    max-width: 900px
    margin: 0 auto
    padding: 138px 0 200px 0
    font-family 'Lato'
    font-size 18px
    font-weight normal
    @media (max-width: 768px)
      flex-flow: column
    .naglowek
      font-family 'Lato'
      font-size 16px
      font-weight bold
    .naglowek-2
      font-family 'Lato'
      font-size 20px
      font-weight normal

    table
      width 100%

      th
        padding 15px

      th,td
        width 33%

      thead
        margin-bottom 10px
        background-color #F6F6F6
        text-align center
        height 50px
        font-size 15px

      tbody
        td
          border 1px solid black
          padding 20px 10px

