.lds-ripple
  display inline-block
  position relative
  width 64px
  height 64px
  margin 50px 0
  &.active
    div
      display block !important
.lds-ripple div
  height: 150px
  display none
  position absolute
  border 4px solid #F57A5B
  opacity 1
  border-radius 50%
  animation lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite
.lds-ripple div:nth-child(2)
  animation-delay -0.5s
.dow-loader-box
  display none
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  background-color rgba(255, 255, 255, .6)
  z-index 100000
  .lds-ripple
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)
    margin 0
