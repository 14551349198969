flience-light-grey = #F2F2F2
flience-placeholder-grey = #C8C8C8
flience-dark-grey = #3C3C3B
flience-green = #9FD6C1
flience-orange = #F57A5B
flience-blue = #A2CCD8
flience-login-blue = #a3ced6
flience-cloud-blue = #b2d6dd
flience-white = #ffffff
flience-login-white = #F6F6F6
flience-black = #000000
*
  padding 0
  margin 0
  box-sizing border-box
  font-family 'Lato', sans-serif
// klasy do stylowania menu
.visibleMenu
  display flex !important
  opacity 1 !important
  // height 100vh !important
  max-height: unset
  z-index 99999999 !important
.visibleFilterList
  display flex !important
  opacity 1 !important
  height auto !important
  z-index 99999999 !important
.visibleSelect
  opacity 1 !important
  max-height 100px !important
  z-index -99999999 !important
.menuOverflow
  overflow hidden !important
.visibleSelectBorderRadius
  border-bottom-right-radius 0px !important
  border-bottom-left-radius 0px !important
.checkElement
  z-index 10 !important
  transform scale(1.1, 1.1) !important
.dot
  height 26px
  width 26px
  background-color flience-blue
  border-radius 50%
  z-index -1
  display flex
  justify-content center
  align-content center
  font-weight 700
.rotate
  transform rotate(90deg) !important
.zIndex
  z-index 5 !important
.sticky
  position fixed
  left 0
  top 0
  width 100%
  z-index 1000 !important
  background-color flience-login-blue
  overflow hidden
  height auto
  @media (max-width: 960px)
    display none
.sticked
  max-height 134px !important
  min-height 134px !important
  @media (max-width: 387px)
    max-height auto !important
    min-height auto !important
.select-options
  padding 0 20px 0 33px
  font-weight 700
.disabled
  pointer-events none
  cursor default
  color red
.displayNone
  display none !important
.displayFlex
  display flex !important
.displayBlock
  display block !important
.menuBackgroundClor
  background-color flience-dark-grey !important
.cardsStyles
  padding 17px 20px 0 33px
  font-weight 700
.visAndOpTrue
  visibility visible !important
  opacity 1 !important
.visAndOpFalse
  visibility hidden
  opacity 0
.logochange
  display block !important
  z-index 60
  @media (max-width: 375px)
    width 83px
    height 26px
content-container()
  max-width 1440px
  margin 0 auto
html
  font-family 'Lato', sans-serif
  scroll-behavior smooth
  overflow-x hidden
  background-color flience-login-blue
.body
  background-color flience-login-blue
.error
  p
    color flience-orange
// fonts-weight
thin()
  font-weight 100
light()
  font-weight 300
regular()
  font-weight 400
medium()
  font-weight 500
semiBold()
  font-weight 600
bold()
  font-weight 700
extraBold()
  font-weight 800
black()
  font-weight 900
.off-opacity
  opacity 1
.on-opacity
  opacity 0.3
.more-button
  cursor pointer !important
.button-width185
  width 185px
  height 41px
  background-color flience-orange
  border-radius 20px
  color flience-white
  border none
  outline none
  font-size 16px
  text-align center
  &.smaller
	  width : 100px;
  a
    bold()
    display block
    text-decoration none
    color flience-white
  &:focus
    outline none
    cursor pointer
.button-width226
  width 226px
  height 41px
  margin-right 25px
  background-color flience-orange
  border-radius 20px
  color flience-white
  border none
  outline none
  font-size 16px
  text-align center
  cursor: pointer
  bold()
  a
    bold()
    display block
    text-decoration none
    color flience-white
  &:focus
    outline none
    cursor pointer
.button-width226ham
  width 226px
  height 41px
  margin-right 14px
  background-color flience-orange
  border-radius 20px
  color flience-white
  border none
  outline none
  font-size 16px
  text-align center
  cursor: pointer
  bold()
  a
    bold()
    display block
    text-decoration none
    color flience-white
  &:focus
    outline none
    cursor pointer
.button-width153
  width 153px
  height 41px
  background-color flience-orange
  border-radius 20px
  color flience-white
  border none
  outline none
  font-size 16px
  text-align center
  cursor: pointer
  bold()
  a
    bold()
    display block
    text-decoration none
    color flience-white
  &:focus
    outline none
    cursor pointer
.button-width170
  width 153px
  height 41px
  background-color flience-orange
  border-radius 20px
  color flience-white
  border none
  outline none
  font-size 16px
  text-align center
  cursor: pointer
  bold()
  a
    bold()
    display block
    text-decoration none
    color flience-white
  &:focus
    outline none
    cursor pointer
.button-width107
  width 107px
  height 41px
  background-color transparent
  border-radius 20px
  color flience-orange
  border 2px solid flience-orange
  outline none
  font-size 16px
  text-align center
  cursor: pointer
  bold()
  a
    bold()
    display block
    text-decoration none
    color flience-orange
  &:focus
    outline none
    cursor pointer
.button-width198
  width 198px
  height 41px
  background-color flience-white
  border-radius 20px
  color flience-orange
  border 2px solid flience-orange
  outline none
  font-size 16px
  text-align center
  cursor: pointer
  bold()
  a
    bold()
    display block
    text-decoration none
    color flience-orange
  &:focus
    outline none
    cursor pointer
.button-widthauto
  height 41px
  padding 10px
  background-color flience-orange
  border-radius 20px
  color flience-white
  border none
  outline none
  font-size 16px
  text-align center
  cursor: pointer
  bold()
  a
    bold()
    display block
    text-decoration none
    color flience-white
  &:focus
    outline none
    cursor pointer
button
  transition .5s transform
  & a
    transition .5s transform
  &:hover
    transform scale(1.05, 1.05)
    & a
      transform scale(.95, .95)
  a:hover
    color #fff
.active-flashcard
  background-color flience-orange !important
  color white !important
  .translate-out
    display flex !important
    color flience-orange !important
    width 100%
    white-space nowrap
.activeMathCircle
  background-color flience-orange 
  color white !important
.activeCirceblenda
  position absolute !important
  top 0 !important
  width 100% !important
  height 100% !important
  background-color rgba(60, 60, 59, 0.7) !important
  padding 71px 41px !important
  flex-flow column !important
  justify-content flex-start !important
  display flex !important
.global-wrapper
  overflow hidden
