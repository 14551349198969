.main
  margin 100px 0 50px 0
  width 100%
  min-height 591px !important
  z-index 999999 !important
  text-align center

  @media (max-width: 1440px)
    padding 0 27px
  @media (max-width: 375px)
    padding 0
    margin 30px 0
  .main__img-box, .main__img-box-math, .main__img-box-screenflies
    content-container()
    justify-content center
    align-items center
    display grid
    grid-template-columns repeat(auto-fill, minmax(425px, 425px))
    column-gap 23px
    row-gap 23px
    margin 0 auto

    .main__no-search-results
      grid-column 2

    @media (max-width: 950px)
      // grid-template-columns repeat(auto-fill, minmax(100%, 1fr))
      display: flex
      flex-direction: column
      row-gap 10px
    .main__img-wrapper, .main__img-wrapper-math, .main__img-wrapper-screenflies
      background-color flience-login-white
      position relative
      width 425px
      height 425px
      display flex
      justify-content center
      align-items center
      margin 0
      @media (max-width: 480px)
        width 100%
      @media (max-width: 375px)
        height 375px
      img
        width 425px
        height 301px
        transition 0.2s all
        @media (max-width: 480px)
          width 100%
      .main__img-langs
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        background-color rgba(60, 60, 59, 0.7)
        padding 71px 41px
        display flex
        flex-flow column
        justify-content flex-start
        display none
        img
          display none
        .langs__item
          text-align center
          color flience-dark-grey
          font-size 14px
          black()
          margin 0 0 10px 0
          cursor pointer
          display flex
          flex-flow row
          justify-content center
          align-items center
          position relative
          z-index 1
          width 36px
          height 36px
          border-radius 50%
          background-color flience-white 
          .translate-out
            font-size 14px
            black()
            color flience-white
            position absolute
            left 50px
            display none
            top: 50%
            transform: translateY(-50%)
          &:hover
            .translate-out
              display flex
              white-space nowrap
      &:hover
        .main__img-langs
          display flex
          @media (max-width: 768px)
            display none
      .main__img-langs
        position absolute
        top 0
        width 100%
        height 100%
        background-color rgba(60, 60, 59, 0.7)
        padding 71px 41px
        display flex
        flex-flow column
        justify-content flex-start
        display none
        .maths__item
          text-align center
          color flience-dark-grey
          font-size 14px
          black()
          margin 0 0 10px 0
          cursor pointer
          text-align center
          display flex
          flex-flow row
          justify-content flex-start
          align-items flex-start
          position relative
          z-index 1
          width 36px
          height 36px
          border-radius 50%
          background-color flience-white
          &:hover
            .translate-out
              display flex
          .fa-check
            position absolute
            top 50%
            right 50%
            transform translate(50%, -50%)
        .screenflies_download
          display flex
          flex-flow row
          justify-content center !important
          align-items center !important
          button
            cursor pointer
            width 142px !important
.button-wrapper-reload
  display flex
  justify-content center
  grid-column: 2
  @media (max-width: 1374px) 
    grid-column 1 / 3
  #more-button
    
    @media(max-width: 768px)
      right: 100px
.download-button
  justify-content center !important
.math-show-fix
  width auto !important
  height 100% !important
