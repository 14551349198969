.profile-options
  margin: 0 16px 113px 16px
  position: relative
  z-index: 50
  .profile-wrapper
    display: flex
    flex-flow: column
    max-width: 660px
    margin: 0 auto
    .changePasswordBtn 
      color #F57A5B
      display: inline-block
      font-weight: bold
      cursor pointer
    .avatar 
      // display: flex
      // align-items: center
      // flex-grow: 0
      margin: 0 0 36px 0
      .photo
        margin-left: 20px
        margin-top: 30px
        position relative
        display flex
        flex-direction: column
        overflow hidden
        float left
        input 
          position: absolute
        div 
          position relative
          font-weight: bold
          color: #F57A5B
          // width: 100%
          height 100%
          background-color: #FFFFFF
          z-index: 100
          cursor pointer
    .setting-header
      font-size: 16px
      black()
      margin: 75px 0 30px 0
    .setting-avatar
      width: 88px
      height: 88px
      border-radius: 50%
      overflow hidden
      display: inline-block
      float left
      border: 1px solid #A2CCD8;
      img
        width: 100%
        height: 100%
        border-radius 50%
    .setting-form
      display: flex
      flex-flow: column
      label
        margin: 0 0 24px 0
        p
          padding: 0
          margin: 0
          font-size: 14px
          regular()
          color: flience-grey
        input[type=text],
        input[type=email]
          width: 100%
          height: 40px !important
          border-radius: 10px
          outline: none
          border: none
          background-color: flience-login-white
          color: #C8C8C8
          padding: 0 20px
          &:focus
            border: 1px solid fleince-grey
            color: #3C3C3B
        input[type=password]
          max-width: 524px
          min-width: 524px
          height: 40px !important
          border-radius: 10px
          outline: none
          border: none
          background-color: flience-login-white
          color: #C8C8C8
          padding: 0 20px
          @media (max-width: 558px)
            max-width: 100%
            min-width: 100%
          &:focus
            border: 1px solid fleince-grey
            color: #3C3C3B
        input::placeholder
          font-size: 12px
          text-align: center
          font-family: 'GloberRegular'
      input[type=submit]
        margin: 24px 0 0 0
.settings-box
  background-color rgba(0,0,0, 0.2);
  position: fixed
  z-index: 999999999
  top: 50%
  left: 50%
  transform translate(-50%,-50%)
  width: 100vw
  height: 100vh
  display: none
  & button
    cursor: pointer
  & .password
    width: 300px
    min-width: unset !important
    background-color #ffffff !important
  .password-change-title
    font-size 20px
    bold()
    margin-bottom 30px
  .login-cloud-blue2
    width 567px
    height 312px
    background-image url('../../dist/img/home/cloud.png')
    background-size contain
    position absolute
    right 0px
    bottom -132px
    z-index 0
    opacity 0.1
    @media (max-width: 600px)
      right -263px
      bottom -157px
  .signin__container
    display flex
    flex-flow column
    justify-content center
    align-items center
    z-index 3
    position: absolute
    top: 50%
    left: 50%
    transform translate(-50%, -50%)

    @media (max-width: 440px)
      width 100%
    .signin__text
      black()
      display flex
      flex-flow column
      justify-content center
      align-items center
      padding 0 0 40px 0
      position relative
      z-index 1
      text-align center
      margin 60px 0 0 0
      @media (max-width: 440px)
        padding 0 0 30px 0
      .h4-global
        regular()
    .signin__box
      background-color #F6f6f6
      padding 50px 70px 70px 70px
      position relative
      margin 0
      min-width 440px
      max-width 440px
      height 324
      border-radius 10px
      @media (max-width: 440px)
        min-width 340px
        max-width 340px !important
        margin 0 36px
        padding 36px 19px 56px 19px
      .forgott-password-hader
        text-align center
        margin 0 0 37px 0
        p
          padding 0
          margin 0
      .login-clouds
        width 317px
        height 175px
        background-image url('../../dist/img/home/cloud.png')
        background-size contain
        position absolute
        left -183px
        bottom -41px
        z-index 1
        @media (max-width: 440px)
          width 253px
          height 139px
          left -79px
        bottom -90px
        z-index -1
      .login-cloud-blue1
        width 759px
        height 418px
        background-image url('../../dist/img/home/cloud.png')
        background-size contain
        position absolute
        left -373px
        bottom 179px
        z-index -1
        opacity 0.16
        @media (max-width: 440px)
          left -490px
      .login-kiwi
        width 200px
        height 149px
        background-image url('../../dist/img/globalne/kiwi-right.png')
        background-size contain
        position absolute
        background-repeat no-repeat
        left -75px
        bottom -86px
        z-index 5
        @media (max-width: 375px)
          width 187px
          height 139px
          left 32px
          bottom -114px
      form
        display flex
        flex-flow column
        justify-content center
        align-items center
        width 100%
        padding 0
        margin 0
        background-color flience-login-white
        width 300px
        position relative
        @media (max-width: 440px)
          width 100%
        label
          display flex
          justify-content center
          align-items flex-start
          flex-flow column
          margin 0 0 15px 0
          padding 0
          width 100%
          .p6-global
            color flience-dark-grey !important
            padding 0
            margin 0
          input[type='text']
            background-color grey
            background-color flience-white
            height 41px
            width 100%
            border-radius 10px
            margin 7px 0 0 0
            border none
            color flience-dark-grey
            padding 0 0 0 21px
            &::placeholder
              regular()
              font-size 14px
              color flience-placeholder-grey
              text-transform lowercase
            @media (max-width: 440px)
              width 100%
          input[type='password']
            border 1px solid red
            background-color grey
            background-color flience-white
            height 41px
            padding 0 0 0 21px
            width 100%
            border-radius 10px
            border none
            margin 7px 0 37px 0
            color flience-dark-grey
            &::placeholder
              regular()
              font-size 14px
              color flience-placeholder-grey
              text-transform lowercase
        .forgot-password
          color flience-orange
          margin 0 0 16px 0
        .button-width153
          border-radius 50px
          width 116px
          cursor pointer
          margin 0
        .button-width170
          cursor pointer
        .additional-button
          border-radius 50px
          width 146px
          cursor pointer
          margin 0
        .additional-button-new-password
          border-radius 50px
          width 225px
          cursor pointer
          margin 0
