.ad
  width: 100%;
  min-height: 590px
  padding: 100px 0 134px 0
  background-color: flience-light-grey
  border-bottom: 8px solid white
  @media (max-width: 768px)
    padding: 48px 0
  .ad-elements
    content-container()
    display: flex
    flex-flow: row
    justify-content: center
    align-items: center
    @media (max-width: 768px)
      flex-flow: column
      justify-content: flex-start
    @media (max-width: 768px)
      margin: 0 36px
    .left-part
      bold()
      width: 50%
      height: 100%
      display: flex
      align-items: flex-end
      justify-content: center
      padding: 0
      @media (max-width: 768px)
        width: 90%
        margin: 0 0 65px 0
      img
        width: 82%
        @media (max-width: 768px)
          width: 100%
        @media (max-width: 375px)
          width: 120%
    .right-part
      regular()
      background-position: center
      background-size: cover
      width: 40%
      margin: 0
      @media (max-width: 768px)
        text-align: flex-start
        width: 100%
        padding: 0 3%
      .ad-text1
        bold()
        width: 80%
        @media (max-width: 768px)
          text-align: flex-start
          width: 100%
        @media (max-width: 375px)
          font-size: 22px !important
      .ad-text2
        width: 80%
        regular()
        margin: 50px 0 70px 0
        @media (max-width: 768px)
          font-size: 20px
          text-align: flex-start
          width: 100%
        @media (max-width: 550px)
          margin-top 20px
          margin-bottom 30px
        @media (max-width: 375px)
          font-size: 20px !important
          margin: 25px 0 40px 0
