.banner-policy-wrapper
  position relative
  z-index 0
  .policy-claud2
    background-image url('../../dist/img/globalne/chmukra.png')
    background-position center
    background-size cover
    width 346px
    height 191px
    position absolute
    bottom -1px
    right 129px
    z-index 0
  .baner-content
    width 100%
    height 100vh
    .wrapper-for-pictograms
      display flex
      flex-flow row nowrap
      justify-content center
      align-items center
      width 100%
      position relative
      z-index 10px
      @media (max-width: 1200px)
        justify-content flex-start
        flex-flow row wrap



    


    // .wrapper-for-pictograms-settings
    //   display flex
    //   flex-flow row nowrap
    //   justify-content space-between
    //   align-items center
    //   width 660px
    //   position relative
    //   z-index 10px
    //   margin 0 auto
    //   @media (max-width: 697px)
    //     width auto
    //     margin 0 16px !important
    //     flex-flow row wrap
    //   @media (max-width: 387px)
    //     flex-flow row wrap
    //   .pictogram-description-setting
    //     margin 4px 0 0 0
    //   .setting1
    //     black()
    //     position relative
    //     top 8px
    //     width 85px
    //     height 47px
    //     font-size 14px
    //     background-image url('../../dist/img/globalne/chmukra.png')
    //     background-position center
    //     background-size cover
    //     text-align center
    //     padding 20px 0 12px 0
    //     a
    //       text-decoration none
    //       color black
    //       display block
    //     p
    //       padding 0
    //       strong
    //         black()
    //   .setting2, .setting3, .setting4
    //     regular()
    //     position relative
    //     top 8px
    //     font-size 14px
    //     padding 30px 0 12px 0
    //     a
    //       cursor default
    //       color #707070
    //       text-decoration none
    //     p
    //       padding 0
    //       margin 0
    //       color flience
    //       strong
    //         black()





.banner-download-wrapper, .banner-policy-wrapper
  background-color flience-login-blue
  position relative
  padding 90px 0 0 0
  min-height 375px
  max-height 375px
  overflow hidden
  display flex
  flex-flow column
  .clouds1
    background-image url('../../dist/img/globalne/chmukra.png')
    background-position center
    background-size cover
    width 310px
    height 170px
    position absolute
    bottom -1px
    right 60px
    z-index 5
    display none
    @media (max-width: 960px)
      display flex
    @media (max-width: 375px)
      right -90px
  .download-cloud-blue1
    width 759px
    height 418px
    background-image url('../../dist/img/home/cloud.png')
    background-size contain
    position absolute
    left 112px
    bottom 0px
    opacity 0.1
    z-index 0
    @media (max-width: 375px)
      left -485px
      bottom -30px
  .baner-content
    content-container()
    display flex
    flex-flow column
    justify-content space-between
    .baner-download-header
      bold()
      position relative
      z-index 15
      display flex
      flex-flow column
      justify-content center
      align-items center
      text-align center
      .baner-download-description
        thin()
        text-align center
        @media (max-width: 960px)
          display none
  .pictograms
    display flex
    flex-flow row
    align-items flex-end
    justify-content center
    z-index 5
    @media (max-width: 960px)
      display none
    .pictograms-wrapper
      max-width 1440px
      margin 0 auto
      display flex
      flex-flow row
      justify-content flex-end
      align-items center
      width 100%
      height 100px
      @media (max-width: 1440px)
        padding 0 27px
      .wrapper-for-pictograms
        display flex
        flex-flow row nowrap
        justify-content center
        align-items center
        width 100%
        @media (max-width: 1200px)
          justify-content flex-start
          flex-flow row wrap
        .pictogram-description
          margin 0 73px 0 0
          width 30%
          p
            padding 0
            margin 0
            strong
              black()
        .pictogram-description-screenflies
          display flex
          flex-flow column
          width 30%
          p
            padding 0
            margin 0
            strong
              black()
        .option2
          width 50%
        .pictogram
          background-position center
          background-size contain
          background-repeat no-repeat
          margin 0 20px 0px 0
          display flex
          justify-content flex-end
          img
            width auto
            height 35px
    .button-wrapper
      position relative
      width auto
      background-image url('../../dist/img/home/cloud.png')
      background-size contain
      background-repeat no-repeat
      height 200px
      display flex
      justify-content center
      align-items center
      padding 0 49px 0 39px
      position relative
      margin 0
      .header__download-box-elements
        display flex
        flex-flow row
        justify-content center
        padding 0 10px 0 0
        img
          widows 27px
          height 34px
        .header__download--count
          background-color transparent !important
          bold()
          font-size 14px
          position relative
          top 3px
          right 0px
        span
          position relative
          top 9px
          right 10px
          bold()
          font-size 14px
          margin 0 0 0 3px
    .button-width170
      margin 0 0 0 20px
.card-download
  position relative
  z-index 10
  cursor pointer