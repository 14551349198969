$res-popup = 800px
.download
  width 0
  height 0
  visibility hidden
  display flex
  position absolute
.popup
  width 100%
  height 100vh
  background-color rgba(60, 60, 59, 0.9)
  position absolute
  top 0
  left 0
  z-index 99999
  display none
  justify-content center
  align-items center
  position fixed
  .popup__wrapper
    background-color flience-light-grey
    width 551px
    height 781px
    display flex
    justify-content center
    flex-flow column
    align-items center
    position relative
    border-radius 20px
    font-family 'Lato', sans-serif !important
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    .popup__content
      display flex
      flex-flow column
      justify-content center
      align-items flex-start
      width 100%
      height 100%
      .popup__left
        width 100%
        height 57%
        display flex
        flex-flow column
        margin -10px 0 0 0
        .popup__info
          display flex
          flex-flow row
          justify-content center
          align-items center
          margin -3px 0 60px 0
          font-size 16px
          bold()
          color flience-white
          text-align center
          .popup__info-icon2
            //height: 20px
            margin-left: 10px
          & img
            height 100%
            margin-left 6px
            //padding-bottom: 1px
          .popup__info-level
            width auto
            padding 5px 10px
            text-transform uppercase
            height 24px
            border-radius 50px
            background-color flience-dark-grey
            border 2px solid flience-white
            display flex
            justify-content center
            align-items center
            margin-right: 2%
          .popup__info-category
            display flex
            justify-content center
            align-items center
            min-width 114px
            height 24px
            border-radius 50px 
            background-color flience-dark-grey
            border 2px solid flience-white
            padding: 3px 20px 3px 24px
            margin-right: 2%
          .popup__info-index
            width 83px
            display flex
            justify-content center
            align-items center
            height 24px
            border-radius 50px
            background-color flience-dark-grey
            border 2px solid flience-white
          .popup__info-number
            width 60px
            text-transform uppercase
            height 24px
            border-radius 50px
            background-color flience-dark-grey
            border 2px solid flience-white
            display flex
            justify-content center
            align-items center
        .popup__selection
          &-dow--btn
            cursor pointer
          padding 0 60px
          .language-header
            display flex
            flex-flow row
            align-items center
            font-size 16px
            strong
              black()
            .circle-number
              border-radius 50%
              width 20px
              height 20px
              background-color flience-login-blue
              font-size 14px
              margin 0 9px 0 0
              black()
              text-align center
              color flience-white
          .lang
            list-style-type none
            display flex
            .lang__item
              width 40px
              height 40px
              display flex
              align-items center
              justify-content center
              background-color white
              color black
              border-radius 50%
              cursor pointer
              &.lang__item--active
                background-color #F57A5B
                color white
              &:not(:first-of-type)
                margin-left 15px
          .popup__selection-lang
            text-align center
            color #3c3c3b
            font-size 14px
            font-weight 900
            margin 17px 0 17px 10px
            display -webkit-box
            display -ms-flexbox
            display flex
            -webkit-box-orient horizontal
            -webkit-box-direction normal
            -ms-flex-flow row
            flex-flow row
            -webkit-box-pack start
            -ms-flex-pack start
            justify-content flex-start
            -webkit-box-align start
            -ms-flex-align start
            align-items flex-start
            position relative
            z-index 1
            width 36px
            height 36px
            border-radius 50%

            &.activeMathCircle {
              background-color: unset;
            }
          .popup__selection-res
            position relative
            .screenflies-select
              margin 15px 0 36px 0
              display flex
              flex-flow row
              align-items center
              justify-content space-between
              width 368px
              height 40px
              border-radius 10px
              border none
              outline none
              color flience-dark-grey
              text-transform lowercase
              cursor pointer
              background-color white
              position relative
              z-index 10 !important
              .chosen-res
                margin-bottom 0
                margin-left 20px
              @media (max-width: 812px)
                display none
              &::after
                content ''
                position absolute
                right 30px
                width 0
                height 0
                border-left 5px solid transparent
                border-right 5px solid transparent
                border-top 8px solid flience-dark-grey
                color flience-dark-grey
              .screenflies-select-list
                overflow-y auto
                list-style-type none
                background-color white
                list-style-type none
                position absolute
                z-index 100
                top 41px
                width 368px
                padding 10px 33px 0 33px
                border-bottom-right-radius 10px
                border-bottom-left-radius 10px
                opacity 0
                max-height 0
                box-shadow 0px 12px 0px 0px rgba(0, 0, 0, 0.1)
                display: flex
                flex-direction: column
                .header__option
                  padding 5px 0
                  display: flex;
                  align-items: center;
                  min-height 40px
                  .select-item
                    font-size 16px
                    text-decoration none
                    color flience-dark-grey
                    padding 5px 5px 5px 0
                    &:hover
                      bold()
              .screenflies-select-list.visibleMenu 
                opacity 1
                max-height: 200px
      .popup__right
        width 100%
        height 487px
        background-position: right
        background-size cover
        background-repeat no-repeat
