// registery menu
.menu-registery-wrapper
  background-color flience-login-blue
  @media (max-width: 812px)
    background-color flience-light-grey

  .registery-menu-container
    .logo
      width 107px
      height 34px

    .register-button
      display block

      .button-width107
        margin 0 3% 0 0
        background-color flience-white
      @media (max-width: 812px)
        display none

// login menu
.menu-login-wrapper
  background-color flience-login-blue
  @media (max-width: 812px)
    background-color flience-light-grey

  .menu-container
    .logo
      width 107px
      height 34px

    .login-button
      display block
      cursor pointer !important

      .button-width198
        cursor pointer !important
        margin 0 3% 0 0
      @media (max-width: 812px)
        display none

.menu-actions
  position relative
  .shop-url
    margin-right 4px;
    padding 9px
    font bold Lato sans-serif
    text-decoration none
    color flience-orange
    font-size 16px
    box-sizing border-box
    &:hover
      color flience-dark-grey !important
.limits-show
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -50px
  width 60px
  height 30px
  background-color flience-orange
  display flex
  align-items center
  justify-content center
  border-radius 10px
  color white
  @media (max-width: 812px)
    left:-77px

.sign-in-text
  display flex
  flex-direction column
  justify-content center
  padding-left 30px

  .account-type
    font-size 12px
    color flience-orange
    text-align right
    margin-top -5px

  p
    padding 0
    margin 0
    line-height normal

.limits-link
  font-size 16px

.limits-show.free
  display none

.limits-show.premium
  display none
  span
    font-size 40px
    font-weight 100
    position absolute
    top 50%
    left 50%
    transform translate(-50%, -50%)

.sign-langs
  li.active
    border-left 2px solid white !important
    a
      color black !important
  li a
    color white !important
